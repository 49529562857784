import React from "react";
import { Document } from "@react-pdf/renderer";
import EstimatePage from "./EstimatePage";

export function ViviendaEstimatePDF(props) {
  const { values, user } = props;
  return (
    <Document>
      <EstimatePage values={values} user={user} />
    </Document>
  );
}

export default ViviendaEstimatePDF;
