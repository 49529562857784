import React from "react";
import { ErrorWrapper } from "./style";
import { Main } from "../styled";
import Heading from "../../components/heading/heading";
import { Button } from "../../components/buttons/buttons";
import { connect } from "react-redux";
import { setCurrentKeyAction } from "../../redux/actions/uiActions";

const NotFound = (props) => {
  const { setCurrentKey } = props;
  return (
    <Main>
      <ErrorWrapper>
        <Heading className="error-text" as="h3">
          404
        </Heading>
        <p>Sorry! the page you are looking for doesn't exist.</p>

        <Button
          size="default"
          type="primary"
          onClick={() => setCurrentKey("/app/quotations/new")}
        >
          Return Home
        </Button>
      </ErrorWrapper>
    </Main>
  );
};

let mapDispatchToProps = (dispatch) => ({
  setCurrentKey: (key) => dispatch(setCurrentKeyAction(key)),
});

export default connect(null, mapDispatchToProps)(NotFound);
