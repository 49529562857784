import React, { useState } from "react";
import { Row, Col } from "antd";
import Heading from "../../../heading/heading";
import { Cards } from "../../../cards/frame/cards-frame";
import { Button } from "../../../buttons/buttons";
import FeatherIcon from "feather-icons-react";
import { connect } from "react-redux";
import {
  generateViviendaEstimatePDF,
  generateSunnovaIncentivePDF,
} from "../../../helpers/helper";
import { setCurrentKeyAction } from "../../../../redux/actions/uiActions";

function SuccessStep(props) {
  const { option, setPdfOption, form, resetForm, products, currentUser } =
    props;

  const [loading, setLoading] = useState({ en: false, es: false });

  const handleGeneratePDF = async (lang) => {
    setLoading({ ...loading, [lang]: true });

    if (option === "Sunnova Lease") {
      await generateSunnovaIncentivePDF(
        form.getFieldsValue(true),
        products,
        currentUser
      );
    } else if (option === "Estimado/Loan/Cash") {
      await generateViviendaEstimatePDF(
        form.getFieldsValue(true),
        products,
        currentUser
      );
    }

    setLoading({ ...loading, [lang]: false });
  };

  const handleClick = (e, o) => {
    e.preventDefault();
    resetForm();
    setPdfOption(o);
  };

  return (
    <Row justify="center" style={{ width: "100%" }}>
      <Col xl={22} xs={24}>
        <div className="checkout-successful">
          <Cards
            headless
            bodyStyle={{
              backgroundColor: "#F8F9FB",
              borderRadius: "20px",
            }}
          >
            <div style={{ padding: "52px 0px" }}>
              <FeatherIcon icon="check" size={48} className="text-success" />
              <Heading as="h3">¡El Estimado Fue Creado Exitosamente!</Heading>

              <p style={{ marginBottom: "8px" }}>Haz clic para descargar:</p>
              <div>
                <Button
                  type="info"
                  size="default"
                  onClick={() => handleGeneratePDF("es")}
                  style={{ width: "220px" }}
                >
                  <FeatherIcon
                    icon={loading.es ? "loader" : "download"}
                    size={18}
                    className={`pdf-loader-icon ${loading.es ? "loading" : ""}`}
                  />
                  {loading.es ? "Generando PDF..." : "Descargar PDF (Español)"}
                </Button>
              </div>
            </div>

            <Cards headless>
              <p>¿Qué te gustaría hacer próximo?</p>
              <br />
              <Row gutter={16} justify="center">
                <Col md={6} xs={24}>
                  <Button
                    type="primary"
                    size="default"
                    style={{ width: "100%" }}
                    onClick={(e) => handleClick(e, "Estimado/Loan/Cash")}
                  >
                    <FeatherIcon icon="plus" size={18} /> Crear Estimado
                    Vivienda
                  </Button>
                </Col>
                <Col md={6} xs={24}>
                  <Button
                    type="primary"
                    size="default"
                    style={{ width: "100%" }}
                    onClick={(e) => handleClick(e, "Sunnova Lease")}
                  >
                    <FeatherIcon icon="plus" size={18} /> Crear Incentivo
                    Sunnova
                  </Button>
                </Col>
              </Row>
            </Cards>
          </Cards>
        </div>
      </Col>
    </Row>
  );
}

let mapStateToProps = (state) => ({
  products: state.products.products,
  currentUser: state.auth.currentUser,
});

const mapDispatchToProps = (dispatch) => ({
  setCurrentKey: (key) => dispatch(setCurrentKeyAction(key)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SuccessStep);
