import React, { useState } from "react";
import QuoteCard from "../../../components/QuoteCard/QuoteCard";
import CommercialQuoteCard from "../../../components/CommercialQuoteCard.js/CommercialQuoteCard";
import { Row, Col, Pagination } from "antd";
import QuoteFormModal from "../../../components/modals/QuoteFormModal";

function QuotationsList(props) {
  const { quotes, pageSize, showCount, showPagination, gutter } = props;

  const [page, setPage] = useState(1);
  const [openModal, setOpenModal] = useState(false);
  const [currentQuote, setCurrentQuote] = useState(null);

  const handleOpenModal = (quote) => {
    setCurrentQuote({ ...quote });
    setOpenModal(true);
  };

  const handleChange = (pageNumber) => {
    setPage(pageNumber);
  };

  const generateQuotes = () => {
    return quotes
      .sort(
        (a, b) =>
          new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
      )
      .slice((page - 1) * pageSize, page * pageSize)
      .map((q, index) => {
        if (q.client_name) {
          return (
            <Col
              key={index + ""}
              xl={6}
              lg={8}
              sm={12}
              xs={24}
            >
              <CommercialQuoteCard
                {...q}
                setOpenModal={() => handleOpenModal(q)}
              />
            </Col>
          );
        }
        return (
          <Col
            key={index + ""}
            xl={6}
            lg={8}
            sm={12}
            xs={24}
          >
            <QuoteCard {...q} setOpenModal={() => handleOpenModal(q)} />
          </Col>
        );
      });
  };

  return (
    <div style={{ marginTop: "15px" }}>
      <Row gutter={gutter} justify="center">
        {showCount && (
          <Col xs={24}>
            <p className="text-center">
              Showing {page * pageSize - 47} -{" "}
              {quotes.length > page * pageSize
                ? page * pageSize
                : quotes.length}{" "}
              of {quotes.length}
            </p>
          </Col>
        )}
        {generateQuotes()}
        {showPagination && (
          <Col xs={24}>
            <div className="text-center">
              <Pagination
                onChange={handleChange}
                total={quotes.length}
                pageSize={pageSize}
                showSizeChanger={false}
              />
            </div>
          </Col>
        )}
      </Row>
      <QuoteFormModal
        quote={currentQuote}
        open={openModal}
        setOpen={setOpenModal}
      />
    </div>
  );
}

QuotationsList.defaultProps = {
  pageSize: 48,
  showCount: true,
  showPagination: true,
  gutter: 25,
};

export default QuotationsList;
