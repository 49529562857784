import React, { useState, useLayoutEffect } from "react";
import { Form } from "antd";
import { WizardWrapper } from "../../../containers/ViviendaEstimates/Create/Style";
import { Steps } from "../../steps/steps";
import CustomerForm from "./steps/CustomerForm";
import ViviendaInformationForm from "./steps/ViviendaInformationForm";
import SelectProductForm from "./steps/SelectProductForm";
import ViviendaEstimateReview from "./steps/ViviendaEstimateReview";
import SuccessStep from "./steps/SuccessStep";
import { connect } from "react-redux";
import { createNewEnergy2QuoteAction } from "../../../redux/actions/newEnergy2QuoteActions";

const ViviendaEstimateForm = (props) => {
  const { createQuote } = props;
  const [form] = Form.useForm();

  const [state, setState] = useState({
    status: "process",
    validation: false,
    isFinished: false,
    current: 0,
    quote: {},
  });

  const { status, isFinished, current, validation } = state;

  useLayoutEffect(() => {
    const activeElement = document.querySelectorAll(".ant-steps-item-active");
    const successElement = document.querySelectorAll(".ant-steps-item-finish");

    activeElement.forEach((element) => {
      if (element.previousSibling) {
        const bgImage = element.previousSibling;
        if (bgImage.classList.contains("success-step-item")) {
          bgImage.classList.remove("success-step-item");
        } else {
          bgImage.classList.remove("wizard-step-item");
        }
        bgImage.classList.add("wizard-steps-item-active");
      }
    });

    successElement.forEach((element) => {
      if (element.previousSibling) {
        const bgImage = element.previousSibling;
        bgImage.classList.remove("wizard-steps-item-active");
        bgImage.classList.add("success-step-item");
      }
    });
  });

  const next = () => {
    form
      .validateFields()
      .then(() => {
        setState({
          ...state,
          status: "process",
          validation: true,
          current: current + 1,
        });
      })
      .catch(() => {
        setState({
          ...state,
          validation: false,
        });
      });
  };
  const validationStatus = () => {
    setState({
      ...state,
      validation: false,
    });
  };
  const prev = () => {
    setState({
      ...state,
      status: "process",
      current: current - 1,
    });
  };

  const done = () => {
    createQuote({
      ...form.getFieldsValue(true),
    }).then((result) => {
      if (result && result.quote && result.quote.id) {
        // Completed Form
        setState({
          ...state,
          status: "finish",
          isFinished: true,
          current: 3,
          quote: result.quote,
        });
      }
    });
  };

  const handleResetForm = () => {
    setState({ ...state, current: 0, status: "process", isFinished: false });
    form.resetFields();
  };

  return (
    <WizardWrapper>
      <Steps
        isswitch
        language={"es"}
        current={state.current}
        status={status}
        validation={validation}
        validationStatus={validationStatus}
        steps={[
          {
            title: "Información Cliente ",
            content: <CustomerForm form={form} />,
          },
          {
            title: "Nueva Energía 2",
            content: <ViviendaInformationForm form={form} />,
          },
          {
            title: "Añadir Productos",
            content: <SelectProductForm form={form} />,
          },
          {
            title: "Revisar Cotización",
            content:
              status !== "finish" ? (
                <ViviendaEstimateReview
                  form={form}
                  setStep={(step) =>
                    setState({
                      ...state,
                      current: step,
                      status: "process",
                      isFinished: false,
                    })
                  }
                />
              ) : (
                <SuccessStep
                  form={form}
                  quote={state.quote}
                  resetForm={handleResetForm}
                />
              ),
          },
        ]}
        onNext={next}
        onPrev={prev}
        onDone={done}
        isfinished={isFinished}
      />
    </WizardWrapper>
  );
};

let mapDispatchToProps = (dispatch) => ({
  createQuote: (formValues) =>
    dispatch(createNewEnergy2QuoteAction(formValues)),
});

export default connect(null, mapDispatchToProps)(ViviendaEstimateForm);
