import React, { useEffect, useState } from "react";
import { BasicFormWrapper } from "../../../../containers/styled";
import Heading from "../../../heading/heading";
import { Row, Col, Form, Input, Radio, Select } from "antd";

const { Option } = Select;
function CustomerForm(props) {
  const { form } = props;

  const [formValues, setFormValues] = useState({});
  // const [payPercentage, setPayPercentage] = useState("");

  const [showUnitedPercentage, setShowUnitedPercentage] = useState(false);

  const handleSetValues = () => {
    let formValues = form.getFieldsValue();

    if (formValues["finance_method"] === "United") {
      setShowUnitedPercentage(true);
    } else {
      setShowUnitedPercentage(false);
    }

    // if (showUnitedPercentage && formValues["united_approved_percentage"]) {
    //   setPayPercentage(100 - formValues["united_approved_percentage"]);
    // } else {
    //   setPayPercentage("");
    // }
  };

  const handleChange = (changed, allFields) => {
    handleSetValues();
  };

  useEffect(() => {
    let fieldValues = { ...form.getFieldsValue(true) };

    setFormValues({ ...formValues, ...fieldValues });

    // Set Initial values
    handleSetValues();
    // eslint-disable-next-line
  }, [form]);

  const generatePercentageOptions = () => {
    let arr = Array(100)
      .fill(1)
      .map((n, i) => n + i);

    return arr.map((n) => (
      <Option key={n} value={n}>
        {n}%
      </Option>
    ));
  };
  
  return (
    <BasicFormWrapper className="basic-form-inner">
      <div className="atbd-form-checkout">
        <Row justify="center">
          <Col sm={22} xs={24}>
            <div className="create-account-form">
              <Heading as="h4">1. Please Add Customer Information</Heading>
              <Form form={form} name="account" onFieldsChange={handleChange}>
                <Form.Item
                  name="customer_name"
                  label="Customer Name"
                  rules={[
                    { required: true, message: "Customer name is required!" },
                  ]}
                >
                  <Input placeholder="Justin Rivera" />
                </Form.Item>
                <Form.Item
                  name="finance_method"
                  initialValue=""
                  label="Method"
                  rules={[{ required: true, message: "Method is required" }]}
                >
                  <Radio.Group style={{ width: "100%" }}>
                    <Radio value="Cash" style={{ width: "100%" }}>
                      <p>Cash</p>
                    </Radio>
                    <Radio value="Cheque" style={{ width: "100%" }}>
                      <p>Cheque</p>
                    </Radio>
                    <Radio value="Tarjeta de Crédito" style={{ width: "100%" }}>
                      <p>Tarjeta de credito</p>
                    </Radio>
                    <Radio value="Synchrony" style={{ width: "100%" }}>
                      {/* <p>Synchrony (+6%)</p> */}
                      <p>Synchrony</p>
                    </Radio>
                    <Radio value="United" style={{ width: "100%" }}>
                      {/* <p>United (+{payPercentage}%)</p> */}
                      <p>United</p>
                    </Radio>
                  </Radio.Group>
                </Form.Item>
                {showUnitedPercentage && (
                  <Form.Item
                    name="united_approved_percentage"
                    label="Percent Approved:"
                    rules={[
                      {
                        required: true,
                        message: "Percent Approved is required",
                      },
                    ]}
                  >
                    <Select style={{ minWidth: "300px" }} value={""}>
                      <Option value="">0%</Option>
                      {generatePercentageOptions()}
                    </Select>
                  </Form.Item>
                )}
              </Form>
            </div>
          </Col>
        </Row>
      </div>
    </BasicFormWrapper>
  );
}

export default CustomerForm;
