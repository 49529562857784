import React from "react";
import { Page, Text, View, StyleSheet, Image } from "@react-pdf/renderer";
// import text from "./lang";

const styles = StyleSheet.create({
  page: {
    // flexDirection: "row",
    backgroundColor: "#FFF",
    boxSizing: "border-box",
    fontSize: 14,
    padding: 0,
  },
  // section: {
  //   margin: 10,
  //   padding: 10,
  //   flexGrow: 1,
  // },
  header: {
    width: "100%",
    display: "flex",
    alignSelf: "center",
    backgroundColor: "#1D4091",
    color: "#fff",
    padding: "16 16 8 16",
    marginBottom: 16,
    borderBottom: "solid 3px #0E9CD5",
  },
  headerText: {
    fontSize: 14,
    color: "#1f497d",
    margin: "0 10",
  },
  bodyText: {
    fontSize: "12px",
    color: "#343a40",
    margin: "0",
    lineHeight: "2px",
  },
  secondaryHeader: {
    fontSize: 16,
    margin: "8 0",
  },
  flex: {
    display: "flex",
  },
  bold: {
    fontFamily: "Helvetica-Bold",
  },
  justifiedRight: {
    textAlign: "right",
  },
  orangeText: {
    color: "#E9682A",
  },
  grayText: {
    color: "#343a40",
  },

  blueText: {
    color: "#2C4596",
  },

  table: {
    width: "100%",
  },
  tableRow: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-around",
  },
  cell: {
    paddingTop: "5",
    paddingBottom: "3",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    border: "1 solid #ccc",
    width: "33.333333%",
    height: "100%",
    fontSize: "9",
  },
  headerCell: {
    backgroundColor: "#E9682A",
    color: "#FFFFFF",
  },
  totalPrice: {
    marginTop: "8px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    width: "100%",
  },
  upperCase: {
    textTransform: "uppercase",
  },
  lineText: {
    display: "flex",
    flexDirection: "row",
  },
});

function TimelinePage() {
  return (
    <Page size="A4" style={styles.page}>
      <View
        style={{
          width: "100%",
          display: "flex",
          boxSizing: "border-box",
          justifyContent: "space-between",
          height: "100%",
        }}
      >
        {/* Header */}
        <View
          style={[
            {
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
              flexDirection: 'row',
              alignItems: "center",
              backgroundColor: "#1D4091",
              color: "#fff",
              padding: "20 16 20 16",
              borderBottom: "4px solid #0E9CD5",
              marginBottom: 0,
              fontSize: 14,
              height: '72px'
            },
            styles.upperCase,
          ]}
        >
          {/* Page title */}
          {/* <Text>Cotización Comercial</Text> */}
          <Image
            style={{
              height: "28px",
            }}
            src={`/assets/commercial quotes images/powersolar-logo-white.png`}
          />
        </View>

        {/* Page Content */}
        <View
          style={{
            padding: 48,
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          {/* Page Title */}
          <View
            style={[
              {
                fontSize: "24px",
                marginBottom: 24,
                textAlign: "left",
              },
              styles.bold,
              styles.upperCase,
              styles.grayText,
            ]}
          >
            <Text>Itinerario De Trabajo</Text>
          </View>
          {/* Item 1 */}
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginBottom: 24,
            }}
          >
            <Image
              style={{
                height: "48px",
                width:  "48px",
                marginRight: 24,
              }}
              src={`/assets/commercial quotes images/icon-cooperative-handshake.png`}
            />
            <View
              style={[
                {
                  width: "100%",
                },
              ]}
            >
              <Text
                style={[
                  {
                    fontSize: "16px",
                    paddingBottom: '8px'
                  },
                  styles.blueText,
                  styles.bold,
                ]}
              >
                Firma de Acuerdo
              </Text>
              <Text
                style={[
                  {
                    fontSize: "12px",
                  },
                ]}
              >
                Visita Técnica en los primeros 7 días al firmar acuerdo, si no
                hubo visita previa durante el proceso de la propuesta.
              </Text>
            </View>
          </View>

          {/* Item 2 */}
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginBottom: 24,
            }}
          >
            <Image
              style={{
                height: "48px",
                width:  "48px",
                marginRight: 24,
              }}
              src={`/assets/commercial quotes images/icon-edit-one.png`}
            />
            <View
              style={[
                {
                  width: "100%",
                },
              ]}
            >
              <Text
                style={[
                  {
                    fontSize: "16px",
                    paddingBottom: '8px'
                  },
                  styles.blueText,
                  styles.bold,
                ]}
              >
                Ingeniería
              </Text>
              <Text
                style={[
                  {
                    fontSize: "12px",
                  },
                ]}
              >
                Se confirma diseño, si hubo alguno durante el desarrollo de la
                propuesta
              </Text>
            </View>
          </View>

          {/* Item 3 */}
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginBottom: 24,
            }}
          >
            <Image
              style={{
                height: "48px",
                width:  "48px",
                marginRight: 24,
              }}
              src={`/assets/commercial quotes images/icon-tool.png`}
            />
            <View
              style={[
                {
                  width: "100%",
                },
              ]}
            >
              <Text
                style={[
                  {
                    fontSize: "16px",
                    paddingBottom: '8px'
                  },
                  styles.blueText,
                  styles.bold,
                ]}
              >
                Instalación
              </Text>
              <Text
                style={[
                  {
                    fontSize: "12px",
                  },
                ]}
              >
                De no mostrar ninguna complicacion y haber adquirido todos los
                materiales, estaremos realizando instalación durante los
                primeros 30 días de haber firmado acuerdo.
              </Text>
              <Text
                style={[
                  {
                    fontSize: "12px",
                  },
                ]}
              >
                Los equipos, materiales y todos los components se llevaran el
                mismo día de comienzo de la instalación
              </Text>
            </View>
          </View>

          {/* Item 4 */}
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginBottom: 24,
            }}
          >
            <Image
              style={{
                height: "48px",
                width:  "48px",
                marginRight: 24,
              }}
              src={`/assets/commercial quotes images/icon-lightning.png`}
            />
            <View
              style={[
                {
                  width: "100%",
                },
              ]}
            >
              <Text
                style={[
                  {
                    fontSize: "16px",
                    paddingBottom: '8px'
                  },
                  styles.blueText,
                  styles.bold,
                ]}
              >
                Permisología
              </Text>
              <Text
                style={[
                  {
                    fontSize: "12px",
                  },
                ]}
              >
                Si PREPA solicita un studio sumplementario Power Solar LLC, no
                tendrá control de tiempo en el que este sea aprobado.
              </Text>
              <Text
                style={[
                  {
                    fontSize: "12px",
                  },
                ]}
              >
                De no requerir eestudio suplementario el proceso Expedito puede
                tardar ente 1- 3 meses.
              </Text>
              <Text
                style={[
                  {
                    fontSize: "12px",
                  },
                ]}
              >
                Power Solar LLC, Realiza toda la gestión necesaria para que el
                Proyecto se pueda concluir, No se garantiza tiempo ya que la
                aprobación la prove PREPA y LUMA
              </Text>
            </View>
          </View>

          {/* Item 5 */}
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginBottom: 24,
            }}
          >
            <Image
              style={{
                height: "48px",
                width:  "48px",
                marginRight: 24,
              }}
              src={`/assets/commercial quotes images/icon-done-all.png`}
            />
            <View
              style={[
                {
                  width: "100%",
                },
              ]}
            >
              <Text
                style={[
                  {
                    fontSize: "16px",
                    paddingBottom: '8px'
                  },
                  styles.blueText,
                  styles.bold,
                ]}
              >
                Proyecto Completado
              </Text>
              <Text
                style={[
                  {
                    fontSize: "12px",
                  },
                ]}
              >
                Con excepcion a la seccionde permisos. Power Solar LLC, puede
                culminar cualquier instalación en los primeros 30 días, estará
                sujeto al tamaño del Proyecto.
              </Text>
            </View>
          </View>
        </View>
      </View>
    </Page>
  );
}

export default TimelinePage;
