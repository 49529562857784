import { API_URL } from "../constants/apiURLConstants";
import {
  LOGIN,
  REGISTER,
  LOADING_AUTH,
  CLEAR_LOADING_AUTH,
  LOGOUT,
} from "../constants/authConstants";
import axios from "axios";

export function loginAction(formData) {
  return (dispatch) => {
    dispatch({ type: LOADING_AUTH });
    return (
      axios
        .post(`${API_URL}login`, formData, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        })
        // eslint-disable-next-line consistent-return
        .then((responseJSON) => {
          if (responseJSON.data && responseJSON.data.accessToken) {
            if (responseJSON.data.user.is_enabled) {
              localStorage.setItem("token", responseJSON.data.accessToken);
              localStorage.setItem(
                "currentUser",
                JSON.stringify(responseJSON.data.user)
              );
              dispatch({ type: LOGIN, payload: responseJSON.data.user });
            }
            dispatch({ type: CLEAR_LOADING_AUTH });
            return responseJSON.data.user;
          } else {
            dispatch({ type: CLEAR_LOADING_AUTH });
            return responseJSON.data;
          }
        })
        .catch((error) => {
          dispatch({ type: CLEAR_LOADING_AUTH });
          if (
            error.response &&
            error.response.data &&
            error.response.data.errors &&
            Object.keys(error.response.data.errors).length > 0
          ) {
            return error.response.data.errors;
          }
        })
    );
  };
}

export function registerAction(formData) {
  return (dispatch) => {
    dispatch({ type: LOADING_AUTH });
    return (
      axios
        .post(`${API_URL}register`, formData, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        })

        // eslint-disable-next-line consistent-return
        .then((responseJSON) => {
          if (responseJSON.data && responseJSON.data.user) {
            if (responseJSON.data.user.is_enabled) {
              localStorage.setItem(
                "currentUser",
                JSON.stringify(responseJSON.data.user)
              );
              dispatch({ type: REGISTER, payload: responseJSON.data.user });
            }
            dispatch({ type: CLEAR_LOADING_AUTH });
            return responseJSON.data.user;
          } else if (responseJSON.data && responseJSON.data.errors) {
            dispatch({ type: CLEAR_LOADING_AUTH });
            return responseJSON.data.errors;
          }
          dispatch({ type: CLEAR_LOADING_AUTH });
          return {};
        })
        .catch((error) => {
          dispatch({ type: CLEAR_LOADING_AUTH });
          if (
            error.response &&
            error.response.data &&
            error.response.data.errors &&
            Object.keys(error.response.data.errors).length > 0
          ) {
            return error.response.data.errors;
          }
        })
    );
  };
}

export function logoutAction() {
  return (dispatch) => {
    let token = localStorage.getItem("token");

    localStorage.removeItem("token");
    localStorage.removeItem("currentUser");

    dispatch({ type: LOGOUT });

    return axios.post(`${API_URL}logout`, null, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    // .then((responseJSON) => {
    //     console.log(responseJSON)

    //     return {};
    // })
    // .catch((error) => {
    //     console.log(error, "Something went wrong")
    // });
  };
}

export function sendResetPasswordLinkAction(formData) {
  return (dispatch) => {
    dispatch({ type: LOADING_AUTH });
    return axios
      .post(`${API_URL}forgot-password`, formData, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }) // eslint-disable-next-line consistent-return
      .then((responseJSON) => {
        dispatch({ type: CLEAR_LOADING_AUTH });
        return responseJSON.data;
      })
      .catch((error) => {
        dispatch({ type: CLEAR_LOADING_AUTH });
        if (
          error.response &&
          error.response.data &&
          Object.keys(error.response.data).length > 0
        ) {
          return error.response.data;
        }
      });
  };
}

export function resetPasswordAction(formData) {
  return (dispatch) => {
    dispatch({ type: LOADING_AUTH });
    return axios
      .post(`${API_URL}reset-password`, formData, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }) // eslint-disable-next-line consistent-return
      .then((responseJSON) => {
        dispatch({ type: CLEAR_LOADING_AUTH });
        return responseJSON.data;
      })
      .catch((error) => {
        dispatch({ type: CLEAR_LOADING_AUTH });
        if (
          error.response &&
          error.response.data &&
          Object.keys(error.response.data).length > 0
        ) {
          return error.response.data;
        }
      });
  };
}
