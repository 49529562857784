import React, { useState, useCallback } from "react";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
// eslint-disable-next-line import/no-extraneous-dependencies
import { AuthWrapper } from "./style";
import { loginAction } from "../../redux/actions/authActions";
import AuthLayout from "../../components/layouts/AuthLayout";
import LoginForm from "../../components/forms/LoginForm/LoginForm";

const SignIn = (props) => {
  const { login, loading } = props;

  const [loginErrors, setLoginErrors] = useState({});
  const [showDisabledCard, setShowDisabledCard] = useState(false);

  const handleSubmit = useCallback(
    (values) => {
      setLoginErrors({});
      setShowDisabledCard(false);
      login(values).then((result) => {
        if (result && result.id) {
          if (!result.is_enabled) {
            setShowDisabledCard(true);
          }
        } else if (result) {
          setLoginErrors({ ...result });
        } else {
          setLoginErrors({ message: "Something went wrong" });
        }
      });
    },
    [login]
  );

  return (
    <AuthLayout>
      <AuthWrapper>
        <div className="auth-contents">
          <LoginForm
            handleSubmit={handleSubmit}
            loading={loading}
            loginErrors={loginErrors}
            showDisabledCard={showDisabledCard}
          />
          <p className="auth-notice">
            Don&rsquo;t have an account?{" "}
            <NavLink to="/register">Register</NavLink>
          </p>
        </div>
      </AuthWrapper>
    </AuthLayout>
  );
};

const mapStateToProps = (state) => ({
  loading: state.auth.loading,
});

const mapDispatchToProps = (dispatch) => ({
  login: (formValues) => dispatch(loginAction(formValues)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
