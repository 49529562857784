import React, {
  useState,
  useEffect,
  useCallback,
  useLayoutEffect,
} from "react";
import { Form } from "antd";
import { WizardWrapper } from "../../../containers/Quotations/Create/Style";
import { Steps } from "../../steps/steps";
import ClientForm from "./steps/ClientForm";
import ProjectDetailsForm from "./steps/ProjectDetailsForm";
import ObjectRemovalForm from "./steps/ObjectRemovalForm";
import QuoteReview from "./steps/QuoteReview";
import SuccessStep from "./steps/SuccessStep";
import { createRoofQuoteAction } from "../../../redux/actions/roofQuoteActions";
import { fetchRoofObjectsAction } from "../../../redux/actions/roofObjectActions";
import { connect } from "react-redux";

const RoofQuotationForm = (props) => {
  const { createQuote, fetchRoofObjects, roofObjects } = props;
  const [form] = Form.useForm();

  const [state, setState] = useState({
    status: "process",
    validation: false,
    isFinished: false,
    current: 0,
    quote: {},
  });

  const { status, isFinished, current, validation } = state;

  const fetchRoofObjectsCallback = useCallback(() => {
    if (roofObjects && roofObjects.length === 0) fetchRoofObjects();
  }, [roofObjects, fetchRoofObjects]);

  useEffect(() => {
    fetchRoofObjectsCallback();
  }, [fetchRoofObjectsCallback]);

  useLayoutEffect(() => {
    const activeElement = document.querySelectorAll(".ant-steps-item-active");
    const successElement = document.querySelectorAll(".ant-steps-item-finish");

    activeElement.forEach((element) => {
      if (element.previousSibling) {
        const bgImage = element.previousSibling;
        if (bgImage.classList.contains("success-step-item")) {
          bgImage.classList.remove("success-step-item");
        } else {
          bgImage.classList.remove("wizard-step-item");
        }
        bgImage.classList.add("wizard-steps-item-active");
      }
    });

    successElement.forEach((element) => {
      if (element.previousSibling) {
        const bgImage = element.previousSibling;
        bgImage.classList.remove("wizard-steps-item-active");
        bgImage.classList.add("success-step-item");
      }
    });
  });

  const next = () => {
    form
      .validateFields()
      .then(() => {
        setState({
          ...state,
          status: "process",
          validation: true,
          current: current + 1,
        });
      })
      .catch(() => {
        setState({
          ...state,
          validation: false,
        });
      });
  };
  const validationStatus = () => {
    setState({
      ...state,
      validation: false,
    });
  };
  const prev = () => {
    setState({
      ...state,
      status: "process",
      current: current - 1,
    });
  };

  const done = () => {
    let formProducts = [];
    if (form.getFieldValue("solarPanels")) {
      formProducts = [...formProducts, ...form.getFieldValue("solarPanels")];
    }
    if (form.getFieldValue("inverters")) {
      formProducts = [...formProducts, ...form.getFieldValue("inverters")];
    }
    if (form.getFieldValue("batteries")) {
      formProducts = [...formProducts, ...form.getFieldValue("batteries")];
    }

    let roofObjects = form.getFieldValue("roof_objects");

    createQuote({
      ...form.getFieldsValue(true),
      membrane: 1,
      products: formProducts,
      roof_objects:
        roofObjects && roofObjects.length > 0
          ? roofObjects.map((ro) => ({ ...ro }))
          : [],
      discount: parseFloat(form.getFieldValue("discount")) * 100,
    }).then((result) => {
      if (result && result.roofQuote && result.roofQuote.id) {
        setState({
          ...state,
          status: "finish",
          isFinished: true,
          current: 3,
          quote: result.roofQuote,
        });
      }
    });
  };

  const handleResetForm = () => {
    setState({ ...state, current: 0, status: "process", isFinished: false });
    form.resetFields();
  };

  return (
    <WizardWrapper>
      <Steps
        isswitch
        current={state.current}
        status={status}
        validation={validation}
        validationStatus={validationStatus}
        steps={[
          {
            title: "Información del Cliente",
            content: <ClientForm form={form} />,
          },
          {
            title: "Detalles de Proyecto",
            content: <ProjectDetailsForm form={form} />,
          },
          {
            title: "Remoción o Corrección de Objetos",
            content: <ObjectRemovalForm form={form} />,
          },
          {
            title: "Revisar Estimado",
            content:
              status !== "finish" ? (
                <QuoteReview
                  form={form}
                  setStep={(step) =>
                    setState({
                      ...state,
                      current: step,
                      status: "process",
                      isFinished: false,
                    })
                  }
                />
              ) : (
                <SuccessStep
                  form={form}
                  quote={state.quote}
                  resetForm={handleResetForm}
                />
              ),
          },
        ]}
        onNext={next}
        onPrev={prev}
        onDone={done}
        isfinished={isFinished}
      />
    </WizardWrapper>
  );
};

let mapStateToProps = (state) => ({
  roofObjects: state.roofObjects.roofObjects,
});

let mapDispatchToProps = (dispatch) => ({
  createQuote: (formValues) => dispatch(createRoofQuoteAction(formValues)),
  fetchRoofObjects: () => dispatch(fetchRoofObjectsAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(RoofQuotationForm);
