import React from "react";
import { Form, Input, Button } from "antd";
import Heading from "../heading/heading";
import { NavLink } from "react-router-dom";
import FeatherIcon from "feather-icons-react";

function ResetForm(props) {
  const { onSubmit, loading, submitResponse } = props;

  const [form] = Form.useForm();

  const generateSubmitResponse = () => {
    if (submitResponse.status) {
      return (
        <p
          className="text-success"
          style={{ display: "flex", alignItems: "center" }}
        >
          <FeatherIcon
            size={16}
            icon="check-circle"
            style={{ marginRight: "8px" }}
          />
          <strong> We have emailed your password reset link!</strong>
        </p>
      );
    }
    if (submitResponse.error) {
      const error = submitResponse.error.split(".")[1];
      let errorText = "";

      if (error === "user") {
        errorText = "No user was found with that email.";
      } else if (error === "throttled") {
        errorText = "Please wait before retrying.";
      } else {
        errorText = "Something went wrong.";
      }

      return (
        <div
          className="ant-form-item-explain-error"
          style={{
            marginBottom: "10px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <FeatherIcon
            size={16}
            icon="x-circle"
            style={{ marginRight: "8px" }}
          />
          {errorText}
        </div>
      );
    }

    return <></>;
  };

  return (
    <Form
      name="forgotPassword"
      form={form}
      onFinish={onSubmit}
      layout="vertical"
    >
      <p className="return-text" style={{ marginBottom: "48px" }}>
        <NavLink
          to="/sign-in"
          style={{ display: "flex", alignItems: "center" }}
        >
          <FeatherIcon
            size={16}
            icon="arrow-left"
            style={{ marginRight: "8px" }}
          />{" "}
          Return to Sign In
        </NavLink>
      </p>

      <h4>Power Solar Quote Manager</h4>
      <Heading as="h3">Forgot Password</Heading>
      <p className="forgot-text">
        Enter the email address you used when you joined and we’ll send you
        instructions to reset your password.
      </p>
      <Form.Item
        name="email"
        rules={[{ message: "Email is required", required: true }]}
        initialValue=""
        label="Email Address"
      >
        <Input />
      </Form.Item>

      <Form.Item>
        <Button
          className="btn-reset"
          htmlType="submit"
          type="primary"
          size="large"
        >
          {loading ? "Loading..." : "Send Reset Password Link"}
        </Button>
      </Form.Item>

      {generateSubmitResponse()}
    </Form>
  );
}

export default ResetForm;
