import React from "react";
import { Route } from "react-router-dom";
import Dashboard from "../Dashboard/Dashboard";
import CreateQuotationContainer from "../Quotations/Create/CreateQuotationContainer";
import CreateViviendaEstimateContainer from "../ViviendaEstimates/Create/CreateViviendaEstimateContainer";
import CreateViviendaEstimateNuevaEnergia2Container from "../ViviendaNuevaEnergía2/Create/CreateViviendaEstimateNuevaEnergia2Container";
import QuotationsContainer from "../Quotations/List/QuotationsContainer";
import CreateRoofQuotationContainer from "../RoofQuotations/Create/CreateRoofQuotationContainer";
import CreateEcoFlowQuotationContainer from "../EcoFlowQuotes/Create/CreateEcoFlowQuotationContainer";
import Error404 from "../Errors/Error404";
import CreateCommercialQuotationContainer from "../CommercialQuotations/Create/CreateCommercialQuotationContainer";

const Application = [
  <Route path="/app" element={<Dashboard />} />,
  <Route path="/app/quotations" element={<QuotationsContainer />} />,
  <Route path="/app/quotations/new" element={<CreateQuotationContainer />} />,
  <Route
    path="/app/commercial-quotations/new"
    element={<CreateCommercialQuotationContainer />}
  />,
  <Route
    path="/app/roof-quotations/new"
    element={<CreateRoofQuotationContainer />}
  />,
  <Route
    path="/app/eco-flow-quotations/new"
    element={<CreateEcoFlowQuotationContainer />}
  />,
  <Route
    path="/app/vivienda-estimates/new"
    element={<CreateViviendaEstimateContainer option={"Estimado/Loan/Cash"} />}
  />,
  <Route
    path="/app/sunnova-incentives/new"
    element={<CreateViviendaEstimateContainer option={"Sunnova Lease"} />}
  />,
  <Route
    path="/app/vivienda-estimates-energy-2/new"
    element={<CreateViviendaEstimateNuevaEnergia2Container />}
  />,
  <Route path="*" element={<Error404 />} />,
];

export default Application;
