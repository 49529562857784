import React, { Suspense, useState, useEffect } from "react";
import { Row, Col, Skeleton, Segmented } from "antd";
import { WizardBlock } from "./Style";
import { PageHeader } from "../../../components/page-headers/page-headers";
import { Main } from "../../styled";
import { Cards } from "../../../components/cards/frame/cards-frame";
import ViviendaEstimateForm from "../../../components/forms/ViviendaEstimateForm/ViviendaEstimateForm";

const CreateViviendaEstimateContainer = (props) => {
  const { option } = props;
  const [pdfOption, setPdfOption] = useState(option);

  useEffect(() => {
    setPdfOption(option);
  }, [option]);

  const generateTitle = (option) => {
    if (option === "Estimado/Loan/Cash") {
      return "Estimado Vivienda / Sunnova Loan / Cash";
    } else if (option === "Sunnova Lease") {
      return "Sunnova Lease / Incentivo";
    }

    return "";
  };
  return (
    <>
      <PageHeader title={`Crear ${generateTitle(pdfOption)}`} />

      <Main>
        <Row gutter={25}>
          <Col sm={24} xs={24}>
            <Suspense
              fallback={
                <Cards headless>
                  <Skeleton paragraph={{ rows: 20 }} active />
                </Cards>
              }
            >
              <Cards headless>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Segmented
                    options={["Estimado/Loan/Cash", "Sunnova Lease"]}
                    value={pdfOption}
                    onChange={setPdfOption}
                    style={{
                      width: "100%",
                      padding: "8px",
                      maxWidth: "1000px",
                    }}
                  />
                </div>
              </Cards>
              <WizardBlock>
                <Cards headless>
                  <Row justify="center">
                    <Col xxl={20} xs={24}>
                      <ViviendaEstimateForm
                        option={pdfOption}
                        setPdfOption={setPdfOption}
                      />
                    </Col>
                  </Row>
                </Cards>
              </WizardBlock>
            </Suspense>
          </Col>
        </Row>
      </Main>
    </>
  );
};

export default CreateViviendaEstimateContainer;
