import { API_URL } from "../constants/apiURLConstants";
import {
    LOADING_QUOTES,
    CLEAR_LOADING_QUOTES,
    CREATE_QUOTE,
    FETCH_QUOTES,
    UPDATE_QUOTE,
} from "../constants/quoteConstants";
import axios from "axios";
import { errorHandler } from "../helpers.js/helper";

export function fetchEcoFlowQuotesAction() {
  return (dispatch) => {
    dispatch({ type: LOADING_QUOTES });
    return (
      axios
        .get(`${API_URL}eco-flow-quotes`, {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        // eslint-disable-next-line consistent-return
        .then((responseJSON) => {
          if (responseJSON && responseJSON.data) {
            dispatch({ type: FETCH_QUOTES, payload: responseJSON.data });
            return responseJSON.data;
          }
          dispatch({ type: CLEAR_LOADING_QUOTES });
          return {};
        })
        .catch((error) => {
          errorHandler(dispatch, error, () => {
            dispatch({ type: CLEAR_LOADING_QUOTES });
          });
        })
    );
  };
}

export function createEcoFlowQuoteAction(formData) {
  return (dispatch) => {
    dispatch({ type: LOADING_QUOTES });
    return (
      axios
        .post(`${API_URL}eco-flow-quotes`, formData, {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        // eslint-disable-next-line consistent-return
        .then((responseJSON) => {
          if (responseJSON && responseJSON.data && responseJSON.data.ecoFlowQuote) {
            dispatch({ type: CREATE_QUOTE, payload: responseJSON.data.eco_flow_quote });
            return responseJSON.data;
          }
          dispatch({ type: CLEAR_LOADING_QUOTES });
          return responseJSON;
        })
        .catch((error) => {
          return errorHandler(dispatch, error, () => {
            dispatch({ type: CLEAR_LOADING_QUOTES });
            if (
              error.response &&
              error.response.data &&
              error.response.data.errors &&
              Object.keys(error.response.data.errors).length > 0
            ) {
              return error.response.data.errors;
            }
            return false;
          });
        })
    );
  };
}

export function updateEcoFlowQuoteAction(EcoFlowQuoteId, formData) {
  return (dispatch) => {
    dispatch({ type: LOADING_QUOTES });
    return (
      axios
        .patch(`${API_URL}eco-flow-quotes/${EcoFlowQuoteId}`, formData, {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        // eslint-disable-next-line consistent-return
        .then((responseJSON) => {
          if (responseJSON && responseJSON.data && responseJSON.data.ecoFlowQuote) {
            dispatch({ type: UPDATE_QUOTE, payload: responseJSON.data.ecoFlowQuote });
            return responseJSON.data.ecoFlowQuote;
          }
          dispatch({ type: CLEAR_LOADING_QUOTES });
          return responseJSON;
        })
        .catch((error) => {
          return errorHandler(dispatch, error, () => {
            dispatch({ type: CLEAR_LOADING_QUOTES });
            if (
              error.response &&
              error.response.data &&
              error.response.data.errors &&
              Object.keys(error.response.data.errors).length > 0
            ) {
              return error.response.data.errors;
            }
            return false;
          });
        })
    );
  };
}
