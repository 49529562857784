import React from "react";
import { Row, Col, Form, Input } from "antd";
import { Button } from "../../../components/buttons/buttons";
import FeatherIcon from "feather-icons-react";

const FilterRow = (props) => {
  const { setFilteredQuotes, quotes } = props;

  const [form] = Form.useForm();

  const handleFilterClick = () => {
    let filteredQuotes = [...quotes];

    if (form.getFieldValue("customer_name")) {
      filteredQuotes = filteredQuotes.filter((q) =>
        q.customer_name && q.customer_name.toLowerCase().includes(
          form.getFieldValue("customer_name").toLowerCase()
        )
      );
    }

    if (form.getFieldValue("agent_name")) {
      filteredQuotes = filteredQuotes.filter((q) =>
        q.user.name
          .toLowerCase()
          .includes(form.getFieldValue("agent_name").toLowerCase())
      );
    }

    setFilteredQuotes([...filteredQuotes]);
  };

  const handleClear = () => {
    setFilteredQuotes([...quotes]);
    form.resetFields();
  };

  return (
    <Form
      name="quotes-filter"
      form={form}
      style={{ paddingBottom: "15px", borderBottom: "1px solid #d6d6d6" }}
    >
      <Row gutter={30}>
        <Col sm={12} xs={24} className="mb-25">
          <Form.Item name="customer_name">
            <Input placeholder="Customer Name" />
          </Form.Item>
        </Col>
        <Col sm={12} xs={24} className="mb-25">
          <Form.Item name="agent_name">
            <Input placeholder="Agent Name" />
          </Form.Item>
        </Col>
        {/* <Col sm={12} xs={24} className="mb-25">
          <Form.Item name="phone">
            <Input placeholder="Customer Phone" />
          </Form.Item>
        </Col> */}
      </Row>
      <Row></Row>
      <Row>
        <Col sm={12} xs={24}>
          <div className="sDash_form-action">
            <Button
              type="primary"
              style={{ marginRight: "15px" }}
              onClick={handleFilterClick}
            >
              <FeatherIcon icon="search" size={14} />
              Apply
            </Button>
            <Button type="light" onClick={handleClear}>
              <FeatherIcon icon="x" size={14} />
              Clear
            </Button>
          </div>
        </Col>
      </Row>
    </Form>
  );
};

export default FilterRow;
