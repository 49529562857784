import React from "react";
import FeatherIcon from "feather-icons-react";
import { Row } from "antd";
import { UserCard as UserCardStyled } from "./style";
import Heading from "../heading/heading";
import { Cards } from "../cards/frame/cards-frame";
import { Button } from "../buttons/buttons";

const UserCard = (props) => {
  const {
    id,
    name,
    email,
    role,
    is_enabled,
    setOpenModal,
    setOpenEnableModal,
    authUser,
  } = props;

  return (
    <UserCardStyled>
      <div className="card user-card">
        <Cards headless>
          <div className="card__content">
            <Heading className="card__name, body-text-large" as="h3">
              {name}
            </Heading>
            <p style={{ fontWeight: "bold" }}> {role}</p>
          </div>

          {authUser && (authUser.role === "admin" || role !== "admin") && (
            <div className="card__actions">
              <Button
                size="default"
                type="white"
                onClick={() => setOpenModal({ ...props })}
              >
                <FeatherIcon icon="edit-3" size={14} />
                Edit
              </Button>
              <Button
                size="default"
                type="white"
                onClick={() => setOpenEnableModal({ ...props })}
              >
                <FeatherIcon icon={is_enabled ? "x" : "check"} size={14} />
                {is_enabled ? "Disable" : "Enable"}
              </Button>
            </div>
          )}

          <div className="card__info border-bottom">
            <Row gutter={16} style={{ margin: "0px" }}>
              <ul className="quote-info " style={{ padding: 0 }}>
                <li className="quote-info-item" style={{ width: "100%" }}>
                  <FeatherIcon icon="hash" size={16} />
                  {id}
                </li>
                <li className="quote-info-item">
                  <FeatherIcon icon="mail" size={16} />
                  {email}
                </li>
              </ul>
            </Row>
          </div>
          <div className="card-footer">
            <Row justify="center" style={{ flexDirection: "column" }}>
              {is_enabled && (
                <p className="text-success" style={{ fontWeight: "bold" }}>
                  Enabled
                </p>
              )}
              {!is_enabled && (
                <p className="text-danger" style={{ fontWeight: "bold" }}>
                  Disabled
                </p>
              )}
              <p>Status</p>
            </Row>
          </div>
        </Cards>
      </div>
    </UserCardStyled>
  );
};

export default UserCard;
