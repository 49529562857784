import React from "react";
import { Document } from "@react-pdf/renderer";
import EstimatePage from "./EstimatePage";
import CoverPage from "./CoverPage"

function EcoFlowPDF(props) {
  const { quote, products, user } = props;
  return (
    <Document>
      <CoverPage />
      <EstimatePage quote={quote} products={products} user={user} />
    </Document>
  );
}

export default EcoFlowPDF;
