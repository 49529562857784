import React from "react";
import { Page, Text, View, StyleSheet, Image } from "@react-pdf/renderer";
// import text from "./lang";

const styles = StyleSheet.create({
  page: {
    // flexDirection: "row",
    backgroundColor: "#FFF",
    boxSizing: "border-box",
    fontSize: 14,
    padding: 0,
    fontFamily: "Poppins",
  },
  // section: {
  //   margin: 10,
  //   padding: 10,
  //   flexGrow: 1,
  // },
  header: {
    width: "100%",
    display: "flex",
    alignSelf: "center",
    backgroundColor: "#1D4091",
    color: "#fff",
    padding: "16 16 8 16",
    marginBottom: 16,
    borderBottom: "solid 3px #0E9CD5",
  },
  headerText: {
    fontSize: 14,
    color: "#1f497d",
    margin: "0 10",
  },
  bodyText: {
    fontSize: "12px",
    color: "#343a40",
    margin: "0",
    lineHeight: "2px",
  },
  secondaryHeader: {
    fontSize: 16,
    margin: "8 0",
  },
  flex: {
    display: "flex",
  },
  bold: {
    fontFamily: "PoppinsBold",
  },
  justifiedRight: {
    textAlign: "right",
  },
  orangeText: {
    color: "#E9682A",
  },
  grayText: {
    color: "#343a40",
  },
  table: {
    width: "100%",
  },
  tableRow: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-around",
  },
  cell: {
    paddingTop: "5",
    paddingBottom: "3",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    border: "1 solid #ccc",
    width: "33.333333%",
    height: "100%",
    fontSize: "9",
  },
  headerCell: {
    backgroundColor: "#E9682A",
    color: "#FFFFFF",
  },
  totalPrice: {
    marginTop: "8px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    width: "100%",
  },
  upperCase: {
    textTransform: "uppercase",
  },
  lineText: {
    display: "flex",
    flexDirection: "row",
  },
});

function AgreementCover(props) {
  const { quote } = props;

  return (
    <Page size="A4" style={styles.page}>
      {/* Page Container */}
      <View
        style={{
          width: "100%",
          display: "flex",
          boxSizing: "border-box",
          justifyContent: "space-between",
          height: "100%",
        }}
      >
        {/* Page Content */}
        <View>
          {/* Header Image */}

          <Image
            style={{
              width: "100%",
              height: "700px",
              objectFit: "cover",
            }}
            src={`assets/commercial quotes images/danosa-and-power-solar.jpg`}
          />

          <View
            style={{
              padding: 48,
              display: "flex",
              flexDirection: "column",
              height: "100%",
            }}
          >
            {/* Page Content */}
            <View
              style={[
                {
                  textAlign: "center",
                },
                styles.bodyText,
              ]}
            >
              <View
                style={{
                  backgroundColor: "#f8f9fb",
                  padding: 16,
                  borderRadius: "10px",
                  fontSize: 18,
                }}
              >
                <Text style={[styles.bold]}>Acuerdo de instalación y</Text>
                <Text style={[styles.bold]}>
                  garantía de sellado de techo entre:
                </Text>
                <Text>{quote.client_name}</Text>
                <Text>&</Text>
                <Text>Power Solar, LLC</Text>
              </View>
            </View>
          </View>

          {/* Power Solar Contact Info */}
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%",
              backgroundColor: "#F2F2F2",
              padding: "16px",
              fontSize: 10,
              color: "#2C4596",
            }}
          >
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Image
                style={{
                  height: "14px",
                  display: "flex",
                  flexDirection: "row",
                  marginRight: 4,
                  padding: 2,
                }}
                src="/assets/icons8-phone-64-blue.png"
              />
              <Text style={[styles.whiteSmallText]}>787.331.100</Text>
            </View>

            <View
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Image
                style={{
                  height: "14px",
                  display: "flex",
                  flexDirection: "row",
                  marginRight: 4,
                  padding: 2,
                }}
                src="/assets/icons8-website-64-blue.png"
              />
              <Text style={[styles.whiteSmallText]}>powersolarpr.com</Text>
            </View>

            <View
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Image
                style={{
                  height: "14px",
                  width: "auto",
                  display: "flex",
                  flexDirection: "row",
                  marginRight: 4,
                  padding: 2,
                }}
                src="/assets/icons8-facebook-64-blue.png"
              />
              <Text style={[styles.whiteSmallText]}>PowerSolarPR</Text>
            </View>

            <View
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Image
                style={{
                  height: "14px",
                  display: "flex",
                  flexDirection: "row",
                  marginRight: 4,
                  padding: 2,
                }}
                src="/assets/icons8-location-64-blue.png"
              />
              <Text style={[styles.whiteSmallText]}>
                279 calle César L. Gónzalez • San Juan, P.R. 00918
              </Text>
            </View>
          </View>
        </View>
      </View>
    </Page>
  );
}

export default AgreementCover;
