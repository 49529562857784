import {
    LOADING_ROOF_OBJECTS,
    CLEAR_LOADING_ROOF_OBJECTS,
    FETCH_ROOF_OBJECTS,
} from '../constants/roofObjectConstants';

const initialState = {
    roofObjects: [],
    loading: false,
};

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case CLEAR_LOADING_ROOF_OBJECTS:
            return {
                ...state,
                loading: false,
            }
        case LOADING_ROOF_OBJECTS:
            return {
                ...state,
                loading: true,
            }
        case FETCH_ROOF_OBJECTS:
            return {
                ...state,
                loading: false,
                roofObjects: action.payload
            }
        default:
            return state;
    }
}
