import React, { useEffect } from "react";
import { Modal } from "./antd-modals";
import EditQuotationForm from "../forms/EditQuotationForm/EditQuotationForm";
import { connect } from "react-redux";
import { Form } from "antd";
import { updateQuoteAction } from '../../redux/actions/quoteActions'

function QuoteFormModal(props) {
  const { quote, open, setOpen, updateQuote } = props;

  const [form] = Form.useForm();

  const handleSubmit = () => {
    let data = form.getFieldsValue(true);
    updateQuote(quote.id, data).then((result) => {
      if (result && result.id) {
        setOpen(false);
      }
    });
  };
  
  useEffect(() => {
    form.resetFields();
  }, [quote, form])
  


  return (
    <Modal
      title={quote && quote.id ? "Edit Quote" : ""}
      visible={open}
      onOk={() => handleSubmit()}
      onCancel={() => setOpen(false)}
      type="primary"
      footer={false}
    >
      <EditQuotationForm {...quote} form={form} />
    </Modal>
  );
}

let mapDispatchToProps = (dispatch) => ({
  updateQuote: (id, formData) => dispatch(updateQuoteAction(id, formData)),
});

export default connect(null, mapDispatchToProps)(QuoteFormModal);
