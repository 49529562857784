import React, { useEffect } from "react";
import { BasicFormWrapper } from "../../../../containers/styled";
import Heading from "../../../heading/heading";
import { Row, Col, Form, Input } from "antd";

function ClientForm(props) {
  const { form } = props;

  useEffect(() => {
    form.setFieldsValue({
      ...form.getFieldsValue(true),
      state: "PR",
    });
  }, [form]);

  return (
    <BasicFormWrapper className="basic-form-inner">
      <div className="atbd-form-checkout">
        <Row justify="center">
          <Col sm={22} xs={24}>
            <div className="create-account-form">
              <Heading as="h4">1. Información del Cliente</Heading>
              <Form form={form} name="account">
                <Form.Item
                  name="client_name"
                  label="Nombre del Cliente"
                  rules={[
                    { required: true, message: "Nombre del cliente es requerido" },
                  ]}
                >
                  <Input placeholder="Justin Rivera" />
                </Form.Item>
                <Form.Item
                  name="address_line1"
                  label="Dirección"
                  // eslint-disable-next-line react/jsx-no-duplicate-props
                  rules={[
                    { required: true, message: "Dirección es requerida" },
                  ]}
                >
                  <Input placeholder="" />
                </Form.Item>
                {/* <Form.Item name="address_line2" label="Address Line 2">
                  <Input placeholder="" />
                </Form.Item> */}
                <Form.Item
                  name="municipality"
                  label="Municipio"
                  // eslint-disable-next-line react/jsx-no-duplicate-props
                  rules={[{ required: true, message: "Municipio es requerido" }]}
                >
                  <Input placeholder="San Juan" />
                </Form.Item>
                <Form.Item name="state" label="Estado">
                  <Input placeholder="PR" disabled />
                </Form.Item>
              </Form>
            </div>
          </Col>
        </Row>
      </div>
    </BasicFormWrapper>
  );
}
export default ClientForm;
