import React from "react";
import { Page, Text, View, StyleSheet, Image } from "@react-pdf/renderer";
// import text from "./lang";
import { getInverter, hasPanel455 } from "../helpers/helper";

const styles = StyleSheet.create({
  page: {
    // flexDirection: "row",
    backgroundColor: "#FFF",
    boxSizing: "border-box",
    fontSize: 14,
    padding: 0,
  },
  // section: {
  //   margin: 10,
  //   padding: 10,
  //   flexGrow: 1,
  // },
  header: {
    width: "100%",
    display: "flex",
    alignSelf: "center",
    backgroundColor: "#1D4091",
    color: "#fff",
    padding: "16 16 8 16",
    marginBottom: 16,
    borderBottom: "solid 3px #0E9CD5",
  },
  headerText: {
    fontSize: 14,
    color: "#1f497d",
    margin: "0 10",
  },
  bodyText: {
    fontSize: "12px",
    color: "#343a40",
    margin: "0",
    lineHeight: "2px",
  },
  secondaryHeader: {
    fontSize: 16,
    margin: "8 0",
  },
  flex: {
    display: "flex",
  },
  bold: {
    fontFamily: "Helvetica-Bold",
  },
  justifiedRight: {
    textAlign: "right",
  },
  orangeText: {
    color: "#E9682A",
  },
  grayText: {
    color: "#343a40",
  },

  blueText: {
    color: "#2C4596",
  },

  table: {
    width: "100%",
  },
  tableRow: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-around",
  },
  cell: {
    paddingTop: "5",
    paddingBottom: "3",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    border: "1 solid #ccc",
    width: "33.333333%",
    height: "100%",
    fontSize: "9",
  },
  headerCell: {
    backgroundColor: "#E9682A",
    color: "#FFFFFF",
  },
  totalPrice: {
    marginTop: "8px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    width: "100%",
  },
  upperCase: {
    textTransform: "uppercase",
  },
  lineText: {
    display: "flex",
    flexDirection: "row",
  },
});

function DetailsPage(props) {
  const {
    quote,
    //  lang
  } = props;

  const generateInverterWarrantyText = () => {
    let inverter = getInverter(quote);

    if (inverter && inverter.name && inverter.name.includes("SMA"))
      return "SMA Diez(10) años";
    if (inverter && inverter.name && inverter.name.includes("Enphase"))
      return "Enphase 25 años";
  };

  return (
    <Page size="A4" style={styles.page}>
      {/* Page Container */}
      <View
        style={{
          width: "100%",
          display: "flex",
          // border: "1px solid #ccc",
          boxSizing: "border-box",
          justifyContent: "space-between",
          height: "100%",
        }}
      >
        {/* Header */}
        <View
          style={[
            {
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
              flexDirection: "row",
              alignItems: "center",
              backgroundColor: "#1D4091",
              color: "#fff",
              padding: "20 16 20 16",
              borderBottom: "4px solid #0E9CD5",
              marginBottom: 0,
              fontSize: 14,
              height: "72px",
            },
            styles.upperCase,
          ]}
        >
          {/* Page title */}
          {/* <Text>Cotización Comercial</Text> */}
          <Image
            style={{
              height: "28px",
            }}
            src={`/assets/commercial quotes images/powersolar-logo-white.png`}
          />
        </View>

        {/* Page Content */}
        <View
          style={{
            padding: 48,
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          {/* Page Title */}
          <View
            style={[
              {
                fontSize: "24px",
                marginBottom: 24,
                textAlign: "left",
                textWrap: "nowrap",
              },
              styles.bold,
              styles.upperCase,
              styles.grayText,
            ]}
          >
            <Text>CERTIFICACIONES, GESTIONES Y</Text>
            <Text>GARANTíAS</Text>
          </View>
          <View
            style={{
              display: "flex",
              flexDirection: "column",
              height: "100%",
              justifyContent: "space-between",
            }}
          >
            {/* Certificaciones */}

            <View>
              <Text
                style={[
                  {
                    fontSize: "12px",
                    paddingBottom: "8px",
                  },
                  styles.orangeText,
                  styles.bold,
                  styles.upperCase,
                ]}
              >
                CERTIFICACIONES
              </Text>
              <View
                style={[
                  {
                    fontSize: "12px",
                    paddingLeft: "12px",
                  },
                ]}
              >
                <Text>Certificacion Electricistas</Text>
                <Text>
                  Certificacion para Instalacion de Sistemas Solares en Puerto
                  Rico
                </Text>
                <Text>Instalador certificado de Tesla</Text>
                <Text>Instalador certificado de Enphase</Text>
                <Text>Instalador Certificado de Blue Ion</Text>
              </View>
            </View>

            {/* GESTIONES */}
            <View>
              <Text
                style={[
                  {
                    fontSize: "12px",
                    paddingBottom: "8px",
                  },
                  styles.orangeText,
                  styles.bold,
                  styles.upperCase,
                ]}
              >
                GESTIONES
              </Text>
              <View
                style={[
                  {
                    fontSize: "12px",
                    paddingLeft: "12px",
                  },
                ]}
              >
                <Text>
                  Maquinaria para movilización y/o grúas para levantamiento de
                  Sistema solar estará incluido en propuesta.
                </Text>
                <Text
                  style={[
                    {
                      paddingTop: "12px",
                    },
                  ]}
                >
                  Cualquier costo Adicional que requiera PREPA para la
                  interconexión estará considerado Sellados de techos son
                  realizados en conjunto con los proveedores de sellado si
                  alguno aplica, se coordina inspección para no violentar
                  garantías de cualquier sellado en la localidad. Todas las
                  perforaciones seran selladas con SYKAPLEX y/o botas DANOSA o
                  similares para cumplimiento de garantías. Power Solar LLC
                  Roofing Services se encarga de sellarlos techos con product
                  DANOSA, de estar interesado en este Servicio debe comunicarse
                  para incluirlo en la cotización.
                </Text>
              </View>
            </View>

            {/* GARANTIAS */}

            <View>
              <Text
                style={[
                  {
                    fontSize: "12px",
                    paddingBottom: "8px",
                  },
                  styles.orangeText,
                  styles.bold,
                  styles.upperCase,
                ]}
              >
                GARANTíAS
              </Text>
              <View
                style={[
                  {
                    fontSize: "12px",
                    paddingLeft: "12px",
                    marginBottom: 64,
                  },
                ]}
              >
                <Text
                  style={[
                    {
                      paddingTop: "12px",
                    },
                    styles.blueText,
                    styles.bold,
                  ]}
                >
                  Placas Solares
                </Text>
                <Text>
                  Veinticinco años ({hasPanel455(quote) ? "30" : "25"}) en
                  potencia linear.
                </Text>

                <Text
                  style={[
                    {
                      paddingTop: "12px",
                    },
                    styles.blueText,
                    styles.bold,
                  ]}
                >
                  Inversor
                </Text>
                <Text>{generateInverterWarrantyText()}</Text>

                <Text
                  style={[
                    {
                      paddingTop: "12px",
                    },
                    styles.blueText,
                    styles.bold,
                  ]}
                >
                  Instalación Eléctrica
                </Text>
                <Text>
                  Diez (10) años en cableado, conductos o tuberías, con
                  defectos. Incluye siSistema no esta funcionando
                  correctamentedado a una deficienciade la Instalación. Diez
                  (10) años en Instalación, esto incluye anclajes, torques,
                  filtraciones, Instalacion de Sistema solar y/o baterías,
                  paneles electricos, breakers y todo relacionado a la
                  Instalación.
                </Text>
                <Text
                  style={[
                    {
                      paddingTop: "12px",
                    },
                    styles.blueText,
                    styles.bold,
                  ]}
                >
                  Filtraciones y Anclaje
                </Text>
                <Text>Diez (10) años.</Text>
              </View>
            </View>
          </View>
        </View>
      </View>
    </Page>
  );
}

export default DetailsPage;
