import { API_URL } from "../constants/apiURLConstants";
import {
  LOADING_PRODUCTS,
  CLEAR_LOADING_PRODUCTS,
  FETCH_PRODUCTS,
  CREATE_PRODUCT,
  UPDATE_PRODUCT,
} from "../constants/productConstants";
import axios from "axios";
import { errorHandler } from "../helpers.js/helper";

export function fetchProductsAction() {
  return (dispatch) => {
    dispatch({ type: LOADING_PRODUCTS });
    return (
      axios
        .get(`${API_URL}products`, {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        // eslint-disable-next-line consistent-return
        .then((responseJSON) => {
          if (responseJSON && responseJSON.data) {
            dispatch({ type: FETCH_PRODUCTS, payload: responseJSON.data });
            return responseJSON.data;
          }
          dispatch({ type: CLEAR_LOADING_PRODUCTS });
          return {};
        })
        .catch((error) => {
          errorHandler(dispatch, error, () => {
            dispatch({ type: CLEAR_LOADING_PRODUCTS });
          });
        })
    );
  };
}

export function createProductAction(formData) {
  return (dispatch) => {
    dispatch({ type: LOADING_PRODUCTS });
    return (
      axios
        .post(`${API_URL}products`, formData, {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        // eslint-disable-next-line consistent-return
        .then((responseJSON) => {
          if (responseJSON && responseJSON.data && responseJSON.data.product) {
            dispatch({
              type: CREATE_PRODUCT,
              payload: responseJSON.data.product,
            });
            return responseJSON.data.product;
          }
          dispatch({ type: CLEAR_LOADING_PRODUCTS });
          return responseJSON;
        })
        .catch((error) => {
          return errorHandler(dispatch, error, () => {
            dispatch({ type: CLEAR_LOADING_PRODUCTS });
            if (
              error.response &&
              error.response.data &&
              error.response.data.errors &&
              Object.keys(error.response.data.errors).length > 0
            ) {
              return error.response.data.errors;
            }
            return false;
          });
        })
    );
  };
}

export function updateProductAction(productId, formData) {
  return (dispatch) => {
    dispatch({ type: LOADING_PRODUCTS });
    return (
      axios
        .patch(`${API_URL}products/${productId}`, formData, {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        // eslint-disable-next-line consistent-return
        .then((responseJSON) => {
          if (responseJSON && responseJSON.data && responseJSON.data.product) {
            dispatch({
              type: UPDATE_PRODUCT,
              payload: responseJSON.data.product,
            });
            return responseJSON.data.product;
          }
          dispatch({ type: CLEAR_LOADING_PRODUCTS });
          return responseJSON;
        })
        .catch((error) => {
          return errorHandler(dispatch, error, () => {
            dispatch({ type: CLEAR_LOADING_PRODUCTS });
            if (
              error.response &&
              error.response.data &&
              error.response.data.errors &&
              Object.keys(error.response.data.errors).length > 0
            ) {
              return error.response.data.errors;
            }
            return false;
          });
        })
    );
  };
}
