import React from "react";
import { Page, Text, View, StyleSheet, Font, Image } from "@react-pdf/renderer";
import { newLanguages as text } from "./lang";
import PoppinsRegular from "../../assets/fonts/Poppins-Regular.ttf";
import PoppinsBold from "../../assets/fonts/Poppins-Bold.ttf";
import PoppinsBoldItalic from "../../assets/fonts/Poppins-BoldItalic.ttf";

Font.register({
  family: "Poppins",
  format: "truetype",
  src: PoppinsRegular,
});

Font.register({
  family: "PoppinsBold",
  format: "truetype",
  src: PoppinsBold,
});

Font.register({
  family: "PoppinsBoldItalic",
  format: "truetype",
  src: PoppinsBoldItalic,
});

const styles = StyleSheet.create({
  page: {
    backgroundColor: "#FFF",
    boxSizing: "border-box",
    fontSize: 11,
    fontFamily: "Poppins",
  },
  header: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
  },
  headerText: {
    fontSize: 24,
    color: "#1f497d",
    margin: "0 10",
    fontFamily: "PoppinsBold",
  },
  secondaryHeader: {
    fontSize: 16,
    margin: "8 0",
    fontFamily: "PoppinsBold",
  },
  bodyText: {
    fontSize: 11,
    color: "#343a40",
    margin: "0 10",
  },
  flex: {
    display: "flex",
  },
  bold: {
    fontFamily: "PoppinsBold",
  },
  boldItalic: {
    fontFamily: "PoppinsBoldItalic",
  },
  justifiedRight: {
    textAlign: "right",
  },
  upperCase: {
    textTransform: "uppercase",
  },
  orangeText: {
    color: "#E9682A",
  },
  grayText: {
    color: "#343a40",
  },
  blueText: {
    color: "#2C4596",
  },
  lightGrayBackground: {
    backgroundColor: "#F2F2F2",
  },
  table: {
    width: "100%",
    borderRight: "1px solid #FFF",
  },
  tableRow: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-around",
  },
  cell: {
    paddingTop: "12",
    paddingBottom: "10",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    borderLeft: "1px solid #E6E6E6",
    borderBottom: "1px solid #E6E6E6",
    width: "33.333333%",
    height: "100%",
    fontSize: "10",
    backgroundColor: "#E6E6E6",
  },
  cell100: {
    paddingTop: "12",
    paddingBottom: "10",
    paddingLeft: "8",
    paddingRight: "8",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    borderLeft: "1px solid #E6E6E6",
    borderBottom: "1px solid #E6E6E6",
    width: "100%",
    height: "100%",
    fontSize: "10",
    backgroundColor: "#E6E6E6",
  },
  cell15: {
    padding: "4px 0px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    width: "15%",
    fontSize: "10",
    lineHeight: 1.4,
  },
  headerCell: {
    backgroundColor: "#2C4596",
    color: "#FFFFFF",
    border: "1px solid #2C4596",
  },
});

function ProductionsPage(props) {
  const { quote, lang } = props;

  const generateProductions = () => {
    let productions = [];

    // Get the sum of all watts in the products in the quote
    let wattsTotal = quote.quote_products.reduce((sumkw, qp) => {
      return (sumkw += qp.product.kw
        ? parseInt(qp.product.kw, 10) * parseInt(qp.quantity, 10)
        : 0);
    }, 0);

    // Apply production generated formula
    wattsTotal = Math.round(wattsTotal * 1.6);

    // Loop over 25 years
    for (let i = 0; i < 25; i++) {
      productions.push({ year: i + 1, production: wattsTotal });

      // Apply production generated formula, it tears down year over year
      wattsTotal = Math.round(wattsTotal * 0.993);
    }

    return productions.map((s) => (
      <View
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          backgroundColor: s.year % 2 === 0 ? "#F2F2F2" : "#FFFFFF",
        }}
      >
        <Text style={[styles.cell15, { width: "50%", textAlign: "center" }]}>
          {s.year}
        </Text>
        <Text
          style={[
            styles.cell15,
            {
              width: "50%",
              textAlign: "center",
            },
          ]}
        >
          {s.production.toLocaleString()}kWh
        </Text>
      </View>
    ));
  };

  return (
    <Page size="A4" style={styles.page}>
      {/* Page Container */}
      <View
        style={{
          width: "100%",
          display: "flex",
          border: "1px solid #ccc",
          padding: "48 0 0 0",
          boxSizing: "border-box",
          justifyContent: "space-between",
          alignItems: "center",
          height: "100%",
        }}
      >
        {/* Page title  */}
        <View
          style={[
            styles.headerText,
            styles.upperCase,
            { width: "100%", textAlign: "center" },
          ]}
        >
          <Text
            style={[
              styles.blueText,
              styles.bold,
              { fontSize: 28, width: "100%", textAlign: "center" },
            ]}
          >
            {text[lang].productionTitleBlue}
          </Text>
          <Text
            style={[
              styles.orangeText,
              styles.bold,
              {
                fontSize: 28,
                width: "100%",
                textAlign: "center",
              },
            ]}
          >
            {text[lang].productionTitleOrange}
          </Text>
        </View>

        {/* Table */}
        <View>
          <View
            style={[
              {
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              },
            ]}
          >
            <Text
              style={[
                styles.cell15,
                styles.headerCell,
                {
                  width: "25%",
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  padding: "5px 20px 4px 20px",
                },
              ]}
            >
              <strong>{text[lang].productionTableColumnOne}</strong>
            </Text>
            <Text
              style={[
                styles.cell15,
                styles.headerCell,
                {
                  width: "25%",
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  padding: "5px 20px 4px 20px",
                },
              ]}
            >
              <strong>{text[lang].productionTableColumnTwo}</strong>
            </Text>
          </View>

          <View
            style={[
              {
                padding: "0px",
                backgroundColor: "#E6E6E6",
                width: "50%",
                display: "flex",
                justifyContent: "center",
                alignSelf: "center",
              },
            ]}
          >
            {generateProductions()}
          </View>
        </View>

        {/* Footer */}
        <Image
          style={{
            width: "100%",
            display: "flex",
            alignSelf: "center",
          }}
          src={`/assets/bottom-triangles-border.png`}
        />
      </View>
    </Page>
  );
}

export default ProductionsPage;
