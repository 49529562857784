import { SET_CURRENT_KEY } from "../constants/uiConstants";

const initialState = {
  currentKey: "",
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_CURRENT_KEY:
      return {
        currentKey: action.payload,
      };
    default:
      return state;
  }
}
