import React from "react";
import { Route } from "react-router-dom";
import UsersContainer from "../Users/UsersContainer";
// import PromoCodesContainer from "../Promo Codes/PromoCodesContainer";

const AdminRolesRoutes = [
  <Route path="/app/users" element={<UsersContainer />} />,
  // <Route path="/app/promo-codes" element={<PromoCodesContainer />} />,
  
];

export default AdminRolesRoutes;
