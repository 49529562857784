import React from "react";
import { Modal } from "./antd-modals";
import { connect } from "react-redux";
import { updateProductAction } from "../../redux/actions/productActions";

function EnableProductModal(props) {
  const { product, open, setOpen, updateProduct } = props;

  const handleSubmit = () => {
    if (product && product.id) {
      updateProduct(product.id, { is_enabled: !product.is_enabled }).then(
        (result) => {
          if (result && result.id) {
            setOpen(false);
          }
        }
      );
    }
  };

  return (
    <Modal
      title={
        product && product.is_enabled ? "Disable Product" : "Enable Product"
      }
      visible={open}
      onOk={() => handleSubmit()}
      onCancel={() => setOpen(false)}
      type="primary"
      footer={false}
    >
      <p>
        Are you sure you wish to {product && product.is_enabled ? "Disable" : "Enable"}{" "}
        this product?
      </p>
    </Modal>
  );
}

let mapDispatchToProps = (dispatch) => ({
  updateProduct: (id, formData) => dispatch(updateProductAction(id, formData)),
});

export default connect(null, mapDispatchToProps)(EnableProductModal);
