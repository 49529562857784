import { API_URL } from "../constants/apiURLConstants";
import {
  LOADING_USERS,
  CLEAR_LOADING_USERS,
  FETCH_USERS,
  UPDATE_USER,
} from "../constants/userConstants";
import axios from "axios";
import { errorHandler } from "../helpers.js/helper";

export function fetchUsersAction() {
  return (dispatch) => {
    dispatch({ type: LOADING_USERS });
    return (
      axios
        .get(`${API_URL}users`, {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        // eslint-disable-next-line consistent-return
        .then((responseJSON) => {
          if (responseJSON && responseJSON.data) {
            dispatch({ type: FETCH_USERS, payload: responseJSON.data });
            return responseJSON.data;
          }
          dispatch({ type: CLEAR_LOADING_USERS });
          return {};
        })
        .catch((error) => {
          errorHandler(dispatch, error, () => {
            dispatch({ type: CLEAR_LOADING_USERS });
          });
        })
    );
  };
}

export function updateUserAction(userId, formData) {
  return (dispatch) => {
    dispatch({ type: LOADING_USERS });
    return (
      axios
        .patch(`${API_URL}users/${userId}`, formData, {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        // eslint-disable-next-line consistent-return
        .then((responseJSON) => {
          if (responseJSON && responseJSON.data && responseJSON.data.user) {
            dispatch({ type: UPDATE_USER, payload: responseJSON.data.user });
            return responseJSON.data.user;
          }
          dispatch({ type: CLEAR_LOADING_USERS });
          return responseJSON;
        })
        .catch((error) => {
          return errorHandler(dispatch, error, () => {
            dispatch({ type: CLEAR_LOADING_USERS });
            if (
              error.response &&
              error.response.data &&
              error.response.data.errors &&
              Object.keys(error.response.data.errors).length > 0
            ) {
              return error.response.data.errors;
            }
            return false;
          });
        })
    );
  };
}
