import React from "react";
import { Page, Text, View, StyleSheet, Image, Font } from "@react-pdf/renderer";
import { newLanguages as text } from "./lang";
import PoppinsRegular from "../../assets/fonts/Poppins-Regular.ttf";
import PoppinsBold from "../../assets/fonts/Poppins-Bold.ttf";
import PoppinsBoldItalic from "../../assets/fonts/Poppins-BoldItalic.ttf";

Font.register({
  family: "Poppins",
  format: "truetype",
  src: PoppinsRegular,
});

Font.register({
  family: "PoppinsBold",
  format: "truetype",
  src: PoppinsBold,
});

Font.register({
  family: "PoppinsBoldItalic",
  format: "truetype",
  src: PoppinsBoldItalic,
});

const styles = StyleSheet.create({
  page: {
    backgroundColor: "#FFF",
    boxSizing: "border-box",
    fontSize: 11,
    fontFamily: "Poppins",
  },
  header: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
  },
  headerText: {
    fontSize: 24,
    color: "#1f497d",
    fontFamily: "PoppinsBold",
  },
  secondaryHeader: {
    fontSize: 16,
    margin: "8 0",
    fontFamily: "PoppinsBold",
  },
  bodyText: {
    fontSize: 11,
    color: "#343a40",
    margin: "0 10",
  },
  flex: {
    display: "flex",
  },
  bold: {
    fontFamily: "PoppinsBold",
  },
  boldItalic: {
    fontFamily: "PoppinsBoldItalic",
  },
  justifiedRight: {
    textAlign: "right",
  },
  upperCase: {
    textTransform: "uppercase",
  },
  orangeText: {
    color: "#E9682A",
  },
  grayText: {
    color: "#343a40",
  },
  whiteText: {
    color: "#FFFFFF",
  },
  blueText: {
    color: "#2C4596",
  },
});

export default function AboutPage(props) {
  const { lang } = props;
  return (
    <Page size="A4" style={styles.page}>
      {/* Page Container */}
      <View
        style={{
          width: "100%",
          display: "flex",
          border: "1px solid #ccc",
          padding: "48 0 0 0",
          boxSizing: "border-box",
          justifyContent: "space-between",
          height: "100%",
        }}
      >
        {/* PAGE Title */}
        <View
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <View
            style={[
              styles.headerText,
              styles.upperCase,
              {
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              },
            ]}
          >
            <Text
              style={[
                styles.blueText,
                styles.bold,
                { fontSize: 28, textAlign: "center" },
              ]}
            >
              {text[lang].aboutPageTitleBlue}{" "}
            </Text>
            <Text
              style={[
                styles.orangeText,
                styles.bold,
                {
                  fontSize: 28,
                  textAlign: "center",
                },
              ]}
            >
              {text[lang].aboutPageTitleOrange}
            </Text>
          </View>

          {/* Sobre nosotros */}
          <View
            style={[
              {
                display: "flex",
                flexDirection: "column",
                alignContent: "center",
                width: "100%",
                textAlign: "center",
                margin: "16 0",
                fontSize: 13,
              },
            ]}
          >
            {/* <Text>{text[lang].aboutUsFirstParagraph}</Text> */}
            <Text style={{ width: "100%" }}>
              {text[lang].aboutUsFirstParagraphLineOne}
            </Text>
            <Text style={{ width: "100%" }}>
              {text[lang].aboutUsFirstParagraphLineTwo}
            </Text>
          </View>
          {/* Image */}
          <Image
            style={{
              width: "100%",
              display: "flex",
              objectFit: "contain",
            }}
            src={`/assets/cover-collage-residential-quote.png`}
          />
          {/* bottom half */}
          <View
            style={[
              {
                display: "flex",
                flexDirection: "column",
                margin: "16 32",
              },
            ]}
          >
            {/* Mision y vision */}
            <View
              style={[
                {
                  display: "flex",
                  flexDirection: "row",
                  textAlign: "center",
                  margin: 16,
                },
              ]}
            >
              {/* Mision */}
              <View
                style={[
                  {
                    display: "flex",
                    flexDirection: "column",
                    margin: 16,
                    width: "50%",
                  },
                ]}
              >
                <Text
                  style={[
                    styles.secondaryHeader,
                    styles.upperCase,
                    { marginBottom: 8, color: "#43A7DF",  },
                  ]}
                >
                  {text[lang].aboutUsSecondParagraphTitle}
                </Text>
                <Text
                  style={[
                    {
                      // overflowWrap: "break-word",
                      // hyphens: "none",
                      // wordWrap: "break-word",
                    },
                  ]}
                >
                  {text[lang].aboutUsSecondParagraph}
                </Text>
              </View>
              {/* Vision */}
              <View
                style={[
                  {
                    display: "flex",
                    flexDirection: "column",
                    margin: "16 32 16 32",
                    width: "50%",
                  },
                ]}
              >
                <Text
                  style={[
                    styles.secondaryHeader,
                    styles.upperCase,
                    { marginBottom: 8, color: "#43A7DF" },
                  ]}
                >
                  {text[lang].aboutUsThirdParagraphTitle}
                </Text>
                <Text>{text[lang].aboutUsThirdParagraph}</Text>
              </View>
            </View>

            {/* Asociados */}
            <View
              style={[
                {
                  display: "flex",
                  flexDirection: "column",
                  alignContent: "center",
                  padding: 16,
                  width: "100%",
                },
              ]}
            >
              <Text
                style={[
                  styles.secondaryHeader,
                  styles.upperCase,
                  { marginBottom: 8, textAlign: "center", color: "#43A7DF" },
                ]}
              >
                {text[lang].aboutUsPartnersTitle}
              </Text>
              <Image
                style={{
                  width: "90%",
                  display: "flex",
                  alignSelf: "center",
                  objectFit: "contain",
                }}
                src={`/assets/nuestros-asociados-logos.png`}
              />
            </View>
          </View>
        </View>

        {/* Footer */}
        <Image
          style={{
            width: "100%",
            display: "flex",
            alignSelf: "center",
          }}
          src={`/assets/bottom-triangles-border.png`}
        />
      </View>
    </Page>
  );
}
