import React from "react";
import { Document } from "@react-pdf/renderer";
import CoverPage from "./CoverPage";
import BackCoverPage from "./BackCoverPage";
import AboutPage from "./AboutPage";
import PaymentPlanAndWarrantiesPage from "./PaymentPlanAndWarrantiesPage";
import AgreementsPage from "./AgreementsPage";
import ProductionsPage from "./ProductionsPage";
import CertificationsAndProceduresPage from "./CertificationsAndProceduresPage";
import ItinenaryPage from "./ItineraryPage";
import PromoOnePage from "./PromoOnePage";
import PromoTwoPage from "./PromoTwoPage";
import PromoThreePage from "./PromoThreePage";
import PromoFourPage from "./PromoFourPage";
import PromoFivePage from "./PromoFivePage";
import QuotePage from "./QuotePage";

function QuotationPDF(props) {
  const { quote, lang } = props;
  return (
    <Document>
      <CoverPage quote={quote} lang={lang} />
      <AboutPage lang={lang} />
      <QuotePage quote={quote} lang={lang} />
      <PaymentPlanAndWarrantiesPage quote={quote} lang={lang} />
      <CertificationsAndProceduresPage lang={lang} />
      <ItinenaryPage lang={lang} />
      <ProductionsPage quote={quote} lang={lang} />
      <AgreementsPage quote={quote} lang={lang} />
      <PromoOnePage lang={lang} />
      <PromoTwoPage lang={lang} />
      <PromoThreePage lang={lang} />
      <PromoFourPage lang={lang} />
      <PromoFivePage lang={lang} />
      <BackCoverPage quote={quote} lang={lang} />
    </Document>
  );
}

QuotationPDF.defaultProps = {
  lang: "en",
};

export default QuotationPDF;
