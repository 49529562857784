import React from "react";
import { Form, Input, Button, Card } from "antd";
import Heading from "../../heading/heading";
import { NavLink } from "react-router-dom";
import FeatherIcon from "feather-icons-react";

function LoginForm(props) {
  const { handleSubmit, loading, loginErrors, showDisabledCard } = props;

  const [form] = Form.useForm();

  const generateErrors = () => {
    let showErrors = [];
    if (loginErrors.email) {
      showErrors = [...showErrors, ...loginErrors.email];
    }
    if (loginErrors.password) {
      showErrors = [...showErrors, ...loginErrors.password];
    }
    if (loginErrors.message) {
      showErrors = [...showErrors, loginErrors.message];
    }

    return showErrors.map((e) => (
      <div
        className="ant-form-item-explain-error"
        style={{ marginBottom: "10px" }}
      >
        * {e}
      </div>
    ));
  };

  return (
    <Form name="login" form={form} onFinish={handleSubmit} layout="vertical">
      <h4>Power Solar Quote Manager</h4>
      <Heading as="h3">Sign in</Heading>
      {showDisabledCard && (
        <Card>
          <FeatherIcon icon="info" className={"text-info"}></FeatherIcon>
          <p className="text-info">
            <strong>Your account is currently disabled.</strong> Please contact
            an admin to enable it.
          </p>
        </Card>
      )}
      <br />
      <Form.Item
        name="email"
        rules={[{ message: "Email is required", required: true }]}
        initialValue=""
        label="Email Address"
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="password"
        rules={[{ message: "Password is required", required: true }]}
        initialValue=""
        label="Password"
      >
        <Input.Password placeholder="Password" />
      </Form.Item>
      <div className="auth-form-action">
        <NavLink className="forgot-pass-link" to="/forgot-password">
          Forgot password?
        </NavLink>
      </div>
      {generateErrors()}
      <Form.Item>
        <Button
          className="btn-signin"
          htmlType="submit"
          type="primary"
          size="large"
        >
          {loading ? "Loading..." : "Sign In"}
        </Button>
      </Form.Item>
    </Form>
  );
}

export default LoginForm;
