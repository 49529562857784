import 'antd/dist/antd.min.css'
import React from 'react'
import { ThemeProvider } from 'styled-components'
import { theme } from './theme'

export default function Main(props) {
    return (
        <ThemeProvider theme={theme}>
            {props.children}
        </ThemeProvider>
    )
}
