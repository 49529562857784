import React, { useState } from "react";
import UserCard from "../../components/UserCard/UserCard";
import { Row, Col, Pagination } from "antd";

function UsersList(props) {
  const {
    users,
    pageSize,
    setOpen,
    setOpenEnableModal,
    setCurrentUser,
    authUser,
  } = props;

  const [page, setPage] = useState(1);

  const handleOpenModal = (user) => {
    setCurrentUser({ ...user });
    setOpen(true);
  };

  const handleOpenEnableModal = (user) => {
    setCurrentUser({ ...user });
    setOpenEnableModal(true);
  };

  const handleChange = (pageNumber) => {
    setPage(pageNumber);
  };

  const generateUsers = () => {
    return users
      .sort((a, b) => b.id - a.id)
      .slice((page - 1) * pageSize, page * pageSize)
      .map((u) => (
        <Col key={u.id} md={8} sm={12} xs={24}>
          <UserCard
            {...u}
            setOpenModal={() => handleOpenModal(u)}
            setOpenEnableModal={handleOpenEnableModal}
            authUser={authUser}
          />
        </Col>
      ));
  };

  return (
    <div style={{ marginTop: "15px" }}>
      <Row gutter={25} justify="center">
        <Col xs={24}>
          <p className="text-center">
            Showing {page * pageSize - 47} -{" "}
            {users.length > page * pageSize ? page * pageSize : users.length} of{" "}
            {users.length}
          </p>
        </Col>
        {generateUsers()}
        <Col xs={24}>
          <div className="text-center">
            <Pagination
              onChange={handleChange}
              total={users.length}
              pageSize={pageSize}
              showSizeChanger={false}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
}

UsersList.defaultProps = {
  pageSize: 48,
};

export default UsersList;
