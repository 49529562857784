import React, { useState, useEffect } from "react";
import { BasicFormWrapper } from "../../../../containers/styled";
import Heading from "../../../heading/heading";
import { Cards } from "../../../cards/frame/cards-frame";
import { Link } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import { connect } from "react-redux";
import { formatter } from "../../../../helpers/helper";
import { getProductPrice } from "../../../helpers/helper";

function ViviendaEstimateReview(props) {
  const { option, form, products, setStep } = props;

  const [formValues, setFormValues] = useState({});

  useEffect(() => {
    setFormValues({ ...formValues, ...form.getFieldsValue(true) });
    // eslint-disable-next-line
  }, [form]);

  const hasFormValues =
    formValues["solarPanels"] ||
    formValues["inverters"] ||
    formValues["batteries"];

  const generateProducts = () => {
    if (hasFormValues) {
      let formProducts = [];
      if (formValues["solarPanels"]) {
        formProducts = [...formProducts, ...formValues["solarPanels"]];
      }
      if (formValues["inverters"]) {
        formProducts = [...formProducts, ...formValues["inverters"]];
      }
      if (formValues["batteries"]) {
        formProducts = [...formProducts, ...formValues["batteries"]];
      }
      return formProducts.map((p) => {
        let data = products.find((product) => product.id === p.id);
        return (
          <div>
            <hr style={{ borderTop: "1px solid #eeeeee" }} />
            <h3 className="orange-text">{data.name}</h3>
            {/* <p>{data.description}</p> */}
            <p>
              {/* <strong>Price:</strong>{" "} */}
              {formatter.format(getProductPrice(data, p.quantity) / 100)}
            </p>
            <li>
              <strong>Cantidad: </strong>
              {p.quantity}
            </li>
            <li>
              <strong>Precio Total:</strong>{" "}
              {formatter.format(
                (getProductPrice(data, p.quantity) * parseInt(p.quantity, 10)) /
                  100
              )}
            </li>
          </div>
        );
      });
    }

    return [];
  };

  const generateFinalPrice = () => {
    if (hasFormValues) {
      let formProducts = [];
      if (formValues["solarPanels"]) {
        formProducts = [...formProducts, ...formValues["solarPanels"]];
      }
      if (formValues["inverters"]) {
        formProducts = [...formProducts, ...formValues["inverters"]];
      }
      if (formValues["batteries"]) {
        formProducts = [...formProducts, ...formValues["batteries"]];
      }
      let result = formProducts.reduce((sumPrice, p) => {
        let data = products.find((product) => product.id === p.id);
        return (sumPrice +=
          getProductPrice(data, p.quantity) * parseInt(p.quantity, 10));
      }, 0);

      return result / 100;
    }

    return 0;
  };

  return (
    <BasicFormWrapper style={{ width: "100%" }}>
      <div className="atbd-review-order" style={{ width: "100%" }}>
        <Heading as="h4">4. Revisa y Confirma el Estimado</Heading>
        <Cards
          bodyStyle={{ backgroundColor: "#F8F9FB", borderRadius: 10 }}
          headless
        >
          <div className="atbd-review-order__single">
            <Cards headless>
              <div className="atbd-review-order__shippingTitle">
                <Heading as="h5">
                  Información de Cliente
                  <Link to="#" onClick={() => setStep(0)}>
                    <FeatherIcon icon="edit" />
                    Editar
                  </Link>
                </Heading>
              </div>
              <article className="atbd-review-order__shippingInfo">
                <p>Nombre de Cliente: {formValues["customer_name"]}</p>
                <p>Dirección: {formValues["address_line"]}</p>
                <p>Municipio: {formValues["municipality"]}</p>
                <p>Latitud: {formValues["latitude"]}</p>
                <p>Longitud: -{formValues["longitude"]}</p>
              </article>
            </Cards>
          </div>
          <div className="atbd-review-order__single">
            <Cards headless>
              <div className="atbd-review-order__shippingTitle">
                <Heading as="h5">
                  Información {option}
                  <Link to="#" onClick={() => setStep(1)}>
                    <FeatherIcon icon="edit" />
                    Editar
                  </Link>
                </Heading>
              </div>
              {formValues["ticket_number"] && (
                <p>Número de Boleto: {formValues["ticket_number"]}</p>
              )}
              <p>
                Suma de Factura kWh (12 meses):{" "}
                {formValues["annual_client_consumption"]}
              </p>
              {formValues["annual_client_consumption_capacity"] && (
                <p>
                  Producción de PVWatts anual kW:{" "}
                  {formValues["annual_client_consumption_capacity"]}
                </p>
              )}
            </Cards>
          </div>
          <div className="atbd-review-order__single">
            <Cards headless>
              <div className="atbd-review-order__shippingTitle">
                <Heading as="h5">
                  Productos
                  <Link to="#" onClick={() => setStep(2)}>
                    <FeatherIcon icon="edit" />
                    Editar
                  </Link>
                </Heading>
              </div>
              {generateProducts()}
            </Cards>
          </div>

          <div className="atbd-review-order__single">
            <Cards headless>
              <div>
                <Heading as="h5">Resumen de Estimado para Vivienda </Heading>
              </div>
              <h2>
                Total: <strong>{formatter.format(generateFinalPrice())}</strong>
              </h2>
            </Cards>
          </div>
        </Cards>
      </div>
    </BasicFormWrapper>
  );
}

let mapStateToProps = (state) => ({
  products: state.products.products,
});

export default connect(mapStateToProps)(ViviendaEstimateReview);
