import {
    LOADING_PROMO_CODES,
    CLEAR_LOADING_PROMO_CODES,
    FETCH_PROMO_CODES,
    CREATE_PROMO_CODE,
    UPDATE_PROMO_CODE,
} from '../constants/promoCodeConstants';

const initialState = {
    promoCodes: [],
    loading: false,
};

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case CLEAR_LOADING_PROMO_CODES:
            return {
                ...state,
                loading: false,
            }
        case LOADING_PROMO_CODES:
            return {
                ...state,
                loading: true,
            }
        case FETCH_PROMO_CODES:
            return {
                ...state,
                loading: false,
                promoCodes: action.payload
            }
        case CREATE_PROMO_CODE:
            return {
                ...state,
                loading: false,
                promoCodes: [...state.promoCodes, action.payload]
            }
        case UPDATE_PROMO_CODE:
            return {
                ...state,
                loading: false,
                promoCodes: [
                    ...state.promoCodes.filter(p => p.id !== action.payload.id),
                    action.payload
                ]
            }
        default:
            return state;
    }
}
