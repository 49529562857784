import React from "react";
import { Page, Text, View, StyleSheet, Image, Font } from "@react-pdf/renderer";
import { newLanguages as text } from "./lang";
import PoppinsRegular from "../../assets/fonts/Poppins-Regular.ttf";
import PoppinsBold from "../../assets/fonts/Poppins-Bold.ttf";
import PoppinsBoldItalic from "../../assets/fonts/Poppins-BoldItalic.ttf";

Font.register({
  family: "Poppins",
  format: "truetype",
  src: PoppinsRegular,
});

Font.register({
  family: "PoppinsBold",
  format: "truetype",
  src: PoppinsBold,
});

Font.register({
  family: "PoppinsBoldItalic",
  format: "truetype",
  src: PoppinsBoldItalic,
});

const styles = StyleSheet.create({
  page: {
    backgroundColor: "#FFF",
    boxSizing: "border-box",
    fontSize: 11,
    fontFamily: "Poppins",
  },
  backgroundImage: {
    zIndex: -1,
    position: "absolute",
    minWidth: "100%",
    minHeight: "100%",
    display: "block",
    height: "100%",
    width: "100%",
  },
  header: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
  },
  headerText: {
    fontSize: 24,
    color: "#1f497d",
    margin: "0 10",
    fontFamily: "PoppinsBold",
  },
  secondaryHeader: {
    fontSize: 16,
    margin: "8 0",
    fontFamily: "PoppinsBold",
  },
  bodyText: {
    fontSize: 11,
    color: "#343a40",
    margin: "0 10",
  },
  flex: {
    display: "flex",
  },
  bold: {
    fontFamily: "PoppinsBold",
  },
  boldItalic: {
    fontFamily: "PoppinsBoldItalic",
  },
  justifiedRight: {
    textAlign: "right",
  },
  upperCase: {
    textTransform: "uppercase",
  },
  orangeText: {
    color: "#E9682A",
  },
  grayText: {
    color: "#343a40",
  },
  whiteText: {
    color: "#FFFFFF",
  },
  blueText: {
    color: "#2C4596",
  },
  smallText: {
    fontSize: 10,
  },
  whiteSmallText: {
    color: "#ffffff",
    fontSize: "9px",
  },
});

export default function CoverPage(props) {
  const { quote, lang } = props;
  return (
    <Page size="A4" style={[styles.page]}>
      <View
        style={[
          styles.header,
          { display: "flex", justifyContent: "space-between", height: "100%" },
        ]}
      >
        {/* Upper Half */}
        <View
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            padding: 48,
          }}
        >
          {/* Logo image */}
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <Image
              style={{
                marginBottom: 10,
                width: "85%",
                display: "flex",
              }}
              src="/assets/powersolar-logo-white-big.png"
            />
          </View>

          {/* Quote Title */}
          <View
            style={{
              padding: "0px 10px",
              backgroundColor: "#E9682A",
              width: lang === "en" ? "300px" : "396px",
            }}
          >
            <Text
              style={[
                styles.whiteText,
                styles.upperCase,
                styles.bold,
                {
                  fontSize: 28,
                  textAlign: "center",
                  lineHeight: 1.4,
                },
              ]}
            >
              {text[lang].coverPageTitle}
            </Text>
          </View>
        </View>

        {/* Bottom Half  */}

        <View
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
            width: "100%",
          }}
        >
          {/* Quote info */}
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              padding: "24px 32px",
              backgroundColor: "#fff",
              opacity: 0.9,
            }}
          >
            {/* Column 1 */}
            <View
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <Text style={[styles.bold]}>{text[lang].name}</Text>

              <Text style={[styles.bold, styles.blueText, { fontSize: 16 }]}>
                {quote.customer_name}
              </Text>
            </View>
            {/* Column 2 */}
            <View
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <Text style={[styles.bold, { marginBottom: 0 }]}>
                {text[lang].consultant}
              </Text>
              <Text style={[styles.bold, styles.blueText, { fontSize: 16 }]}>
                {quote.user.name}
              </Text>
            </View>
          </View>
          {/* PS contact info footer */}
          <View
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              backgroundColor: "#fff",
              opacity: 0.8,
              padding: "24px",
            }}
          >
            <View
              style={[
                {
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  width: "100%",
                },
                styles.blueText,
              ]}
            >
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <Image
                  style={{
                    height: "16px",
                    display: "flex",
                    flexDirection: "row",
                    paddingRight: "9px",
                  }}
                  src="/assets/icons8-phone-64-blue.png"
                />
                <Text style={[styles.smallText]}>787.331.100</Text>
              </View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <Image
                  style={{
                    height: "16px",
                    display: "flex",
                    flexDirection: "row",
                    paddingRight: "9px",
                  }}
                  src="/assets/icons8-website-64-blue.png"
                />
                <Text style={[styles.smallText]}>powersolarpr.com</Text>
              </View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <Image
                  style={{
                    height: "16px",
                    width: "auto",
                    display: "flex",
                    flexDirection: "row",
                    paddingRight: "9px",
                  }}
                  src="/assets/icons8-facebook-64-blue.png"
                />
                <Text style={[styles.smallText]}>PowerSolarPR</Text>
              </View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <Image
                  style={{
                    height: "16px",
                    display: "flex",
                    flexDirection: "row",
                    paddingRight: "9px",
                  }}
                  src="/assets/icons8-location-64-blue.png"
                />
                <View
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "flex-start",
                  }}
                >
                  <Text style={[styles.smallText]}>
                    279 calle César L. Gónzalez
                  </Text>
                  <Text style={[styles.smallText]}>San Juan, P.R. 00918</Text>
                </View>
              </View>
            </View>
          </View>
        </View>
      </View>

      <Image
        src="/assets/residential quotes images/residential-cover-image-2024.jpg"
        style={styles.backgroundImage}
      />
    </Page>
  );
}
