import React from "react";
import { Page, Text, View, StyleSheet, Font, Image } from "@react-pdf/renderer";
import { newLanguages as text } from "./lang";
import PoppinsRegular from "../../assets/fonts/Poppins-Regular.ttf";
import PoppinsBold from "../../assets/fonts/Poppins-Bold.ttf";
import PoppinsBoldItalic from "../../assets/fonts/Poppins-BoldItalic.ttf";

Font.register({
  family: "Poppins",
  format: "truetype",
  src: PoppinsRegular,
});

Font.register({
  family: "PoppinsBold",
  format: "truetype",
  src: PoppinsBold,
});

Font.register({
  family: "PoppinsBoldItalic",
  format: "truetype",
  src: PoppinsBoldItalic,
});

const styles = StyleSheet.create({
  page: {
    backgroundColor: "#FFF",
    boxSizing: "border-box",
    fontSize: 11,
    fontFamily: "Poppins",
  },
  header: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
  },
  headerText: {
    fontSize: 24,
    color: "#1f497d",
    margin: "0 10",
    fontFamily: "PoppinsBold",
  },
  secondaryHeader: {
    fontSize: 16,
    margin: "8 0",
    fontFamily: "PoppinsBold",
  },
  bodyText: {
    fontSize: 11,
    color: "#343a40",
    margin: "0 10",
  },
  flex: {
    display: "flex",
  },
  bold: {
    fontFamily: "PoppinsBold",
  },
  boldItalic: {
    fontFamily: "PoppinsBoldItalic",
  },
  justifiedRight: {
    textAlign: "right",
  },
  upperCase: {
    textTransform: "uppercase",
  },
  orangeText: {
    color: "#E9682A",
  },
  grayText: {
    color: "#343a40",
  },
  blueText: {
    color: "#2C4596",
  },
  table: {
    width: "100%",
    borderRight: "1px solid #FFF",
  },
  tableRow: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-around",
  },
  cell: {
    paddingTop: "12",
    paddingBottom: "10",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    borderLeft: "1px solid #E6E6E6",
    borderBottom: "1px solid #E6E6E6",
    width: "33.333333%",
    height: "100%",
    fontSize: "10",
    backgroundColor: "#E6E6E6",
  },
  cell100: {
    paddingTop: "12",
    paddingBottom: "10",
    paddingLeft: "8",
    paddingRight: "8",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    borderLeft: "1px solid #E6E6E6",
    borderBottom: "1px solid #E6E6E6",
    width: "100%",
    height: "100%",
    fontSize: "10",
    backgroundColor: "#E6E6E6",
  },
  cell15: {
    padding: "0px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    borderLeft: "1px solid #E6E6E6",
    borderBottom: "1px solid #E6E6E6",
    width: "15%",
    fontSize: "10",
    backgroundColor: "#E6E6E6",
  },
  headerCell: {
    backgroundColor: "#2C4596",
    color: "#FFFFFF",
    border: "1px solid #2C4596",
  },
});

function PromoTwoPage(props) {
  const { lang } = props;

  return (
    <Page size="A4" style={styles.page}>
      {/* Page Container */}
      <View
        style={{
          width: "100%",
          display: "flex",
          border: "1px solid #ccc",
          padding: "48 0 0 0",
          boxSizing: "border-box",
          justifyContent: "space-between",
          alignItems: "center",
          height: "100%",
        }}
      >
        {/* Page title  */}
        <View
          style={[
            styles.headerText,
            styles.upperCase,
            { width: "100%", textAlign: "center" },
          ]}
        >
          <Text
            style={[
              styles.blueText,
              styles.bold,
              { fontSize: 28, width: "100%", textAlign: "center" },
            ]}
          >
            {text[lang].promoPageTwoTitleBlue}
          </Text>
          <Text
            style={[
              styles.orangeText,
              styles.bold,
              {
                fontSize: 28,
                width: "100%",
                textAlign: "center",
              },
            ]}
          >
            {text[lang].promoPageTwoTitleOrange}
          </Text>
        </View>

        <Image
          style={{
            width: "100%",
          }}
          src={`/assets/residential quotes images/pdf-residencial_proyectos-residenciales-ejemplos.png`}
        />

        {/* Footer */}
        <Image
          style={{
            width: "100%",
            display: "flex",
            alignSelf: "center",
          }}
          src={`/assets/bottom-triangles-border.png`}
        />
      </View>
    </Page>
  );
}

export default PromoTwoPage;
