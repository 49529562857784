import React from "react";
import { Document } from "@react-pdf/renderer";
import EstimatePage from "./EstimatePage";

function ViviendaEstimatePDF(props) {
  const { values, products, user } = props;
  return (
    <Document>
      <EstimatePage values={values} products={products} user={user} />
    </Document>
  );
}

export default ViviendaEstimatePDF;
