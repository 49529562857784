import React from "react";
import FeatherIcon from "feather-icons-react";
import { Form, Input, Button } from "antd";
import Heading from "../../heading/heading";
import { NavLink } from "react-router-dom";

function NewPasswordForm(props) {
  const { onSubmit, submitResponse, loading } = props;

  const [form] = Form.useForm();

  const generateSubmitResponse = () => {
    if (submitResponse.status) {
      return (
        <p
          className="text-success"
          style={{ display: "flex", alignItems: "center" }}
        >
          <FeatherIcon
            size={16}
            icon="check-circle"
            style={{ marginRight: "8px" }}
          />
          <strong>Your password has been reset!</strong>
        </p>
      );
    }
    if (submitResponse.error) {
      const error = submitResponse.error.split(".")[1];
      let errorText = "";

      if (error === "user") {
        errorText = "No user was found with that email.";
      } else if (error === "throttled") {
        errorText = "Please wait before retrying.";
      } else if (error === "Incorrect token or email.") {
        errorText = "Incorrect token or email.";
      } else if (error === "token") {
        errorText = "Incorrect or expired token";
      } else {
        errorText = "Something went wrong.";
      }

      return (
        <div
          className="ant-form-item-explain-error"
          style={{
            marginBottom: "10px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <FeatherIcon
            size={16}
            icon="x-circle"
            style={{ marginRight: "8px" }}
          />
          {errorText}
        </div>
      );
    }
    if (submitResponse.errors) {
      let showErrors = [];
      if (submitResponse.errors.email) {
        showErrors = [...showErrors, ...submitResponse.errors.email];
      }
      if (submitResponse.errors.password) {
        showErrors = [...showErrors, ...submitResponse.errors.password];
      }
      if (submitResponse.errors.token) {
        showErrors = [...showErrors, ...submitResponse.errors.token];
      }

      return showErrors.map((e) => (
        <p className="invalid-feedback" style={{ display: "block" }}>
          * {e}
        </p>
      ));
    }

    return <></>;
  };

  return (
    <Form name="newPassword" form={form} onFinish={onSubmit} layout="vertical">
      <p className="return-text" style={{ marginBottom: "48px" }}>
        <NavLink
          to="/sign-in"
          style={{ display: "flex", alignItems: "center" }}
        >
          <FeatherIcon
            size={16}
            icon="arrow-left"
            style={{ marginRight: "8px" }}
          />{" "}
          Return to Sign In
        </NavLink>
      </p>

      <h4>Power Solar Quote Manager</h4>
      <Heading as="h3">Reset Password</Heading>
      <p className="forgot-text">Enter your email and new password</p>
      <Form.Item
        name="email"
        rules={[{ message: "Email is required", required: true }]}
        initialValue=""
        label="Email Address"
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="password"
        rules={[{ message: "Password is required", required: true }]}
        initialValue=""
        label="Password"
      >
        <Input.Password placeholder="Password" />
      </Form.Item>

      <Form.Item>
        <Button
          className="btn-reset"
          htmlType="submit"
          type="primary"
          size="large"
        >
          {loading ? "Loading..." : "Reset Password"}
        </Button>
      </Form.Item>

      {generateSubmitResponse()}
    </Form>
  );
}

export default NewPasswordForm;
