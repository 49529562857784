import React, { useState, useEffect } from "react";
import { BasicFormWrapper } from "../../../../containers/styled";
import Heading from "../../../heading/heading";
import { Row, Col, Form, /* Checkbox,*/ Input, Radio } from "antd";
//import { calculateSquareFootCost } from "../../../helpers/helper";

function AllowanceForm(props) {
  const { form } = props;

  // const [result, setResult] = useState("");
  // const [showResult, setShowResult] = useState(false);

  const [showParapetFootInput, setShowParapetFootInput] = useState(false);
  const [showPuddleFootInput, setShowPuddleFootInput] = useState(false);

  const [formValues, setFormValues] = useState({});

  const handleSetValues = () => {
    let formValues = form.getFieldsValue();

    if (formValues["has_parapet"]) {
      setShowParapetFootInput(true);
    } else {
      setShowParapetFootInput(false);
    }

    if (formValues["has_puddle"]) {
      setShowPuddleFootInput(true);
    } else {
      setShowPuddleFootInput(false);
    }
  };

  //const handleSetValues = () => {
  // let formValues = form.getFieldsValue();
  // if (formValues["square_foot"] && formValues["membrane"]) {
  //   setShowResult(true);
  // } else {
  //   setShowResult(false);
  // }
  // let result = "";
  // result += formValues["square_foot"];
  // result += " x $";
  // let cost = 0;

  // if (parseInt(formValues["membrane"], 10) === 1) {
  //   cost = 6.13;
  // } else if (parseInt(formValues["membrane"], 10) === 2) {
  //   cost = 7.13;
  // }

  // result += cost + " + IVU = $";
  // result += calculateSquareFootCost(
  //   formValues["square_foot"],
  //   formValues["membrane"]
  // );

  // setResult(result);
  //};

  const handleChange = (changed, allFields) => {
    handleSetValues();
  };

  useEffect(() => {
    // Set on initial load
    let fieldValues = { ...form.getFieldsValue(true) };

    setFormValues({ ...formValues, ...fieldValues });

    handleSetValues();
    // eslint-disable-next-line
  }, []);

  return (
    <BasicFormWrapper className="basic-form-inner">
      <div className="atbd-form-checkout">
        <Row justify="center">
          <Col sm={22} xs={24}>
            <div className="shipping-form">
              <Heading as="h4">2. Detalles del Proyecto</Heading>
              <Form
                form={form}
                initialValues={{}}
                onFieldsChange={handleChange}
              >
                <Form.Item
                  name="square_foot"
                  label="Medida del techo (pies cuadrados)"
                  rules={[
                    {
                      required: true,
                      message: "Pies cuadrados es requerido",
                    },
                    {
                      pattern: /^[0-9]+$/,
                      message:
                        "Ingrese solo caracteres numéricos para pies cuadrados",
                    },
                  ]}
                >
                  <Input placeholder="200" />
                </Form.Item>
                {/* <Form.Item
                  name="membrane"
                  initialValue=""
                  label="Selecciona cantidad de membranas"
                  rules={[
                    {
                      required: true,
                      message: "Cantidad de membranas es requeridas",
                    },
                  ]}
                >
                  <Radio.Group style={{ width: "100%" }}>
                    <Radio value="1" style={{ width: "100%" }}>
                      <p>1 Membrana</p>
                    </Radio>
                    <Radio value="2" style={{ width: "100%" }}>
                      <p>2 Membranas</p>
                    </Radio>
                  </Radio.Group>
                </Form.Item> */}
                {/* {showResult && (
                  <Form.Item label="Costo estimado:">{result}</Form.Item>
                )} */}
                <Form.Item
                  name="has_parapet"
                  initialValue=""
                  label="¿Tiene pretil afectado?"
                  rules={[
                    {
                      required: true,
                      message: "Es requerido",
                    },
                  ]}
                >
                  <Radio.Group style={{ width: "100%", display: "flex" }}>
                    <Radio value={true} style={{ width: "100%" }}>
                      <p>Si</p>
                    </Radio>
                    <Radio value={false} style={{ width: "100%" }}>
                      <p>No</p>
                    </Radio>
                  </Radio.Group>
                </Form.Item>
                {showParapetFootInput && (
                  <Form.Item
                    name="parapet_foot"
                    label="Medida del pretil a trabajar (pies)"
                    rules={[
                      {
                        required: true,
                        message: "Pies cuadrados es requerido",
                      },
                      {
                        pattern: /^[0-9]+$/,
                        message:
                          "Ingrese solo caracteres numéricos para pies cuadrados",
                      },
                    ]}
                  >
                    <Input placeholder="200" />
                  </Form.Item>
                )}
                <Form.Item
                  name="has_puddle"
                  initialValue=""
                  label="¿Tiene empozamiento?"
                  rules={[
                    {
                      required: true,
                      message: "Es requerido",
                    },
                  ]}
                >
                  <Radio.Group style={{ width: "100%", display: "flex" }}>
                    <Radio value={true} style={{ width: "100%" }}>
                      <p>Si</p>
                    </Radio>
                    <Radio value={false} style={{ width: "100%" }}>
                      <p>No</p>
                    </Radio>
                  </Radio.Group>
                </Form.Item>
                {showPuddleFootInput && (
                  <Form.Item
                    name="puddle_square_foot"
                    label="Medida del empozamiento (pies cuadrados)"
                    rules={[
                      {
                        required: true,
                        message: "Pies cuadrados es requerido",
                      },
                      {
                        pattern: /^[0-9]+$/,
                        message:
                          "Ingrese solo caracteres numéricos para pies cuadrados",
                      },
                    ]}
                  >
                    <Input placeholder="200" />
                  </Form.Item>
                )}
              </Form>
            </div>
          </Col>
        </Row>
      </div>
    </BasicFormWrapper>
  );
}

export default AllowanceForm;
