import React from "react";
import { Page, Text, View, StyleSheet, Image } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  page: {
    // flexDirection: "row",
    backgroundColor: "#FFF",
    boxSizing: "border-box",
    fontSize: 10,
    padding: 0,
    fontFamily: "Poppins",
  },
  header: {
    width: "100%",
    display: "flex",
    alignSelf: "center",
    backgroundColor: "#1D4091",
    color: "#fff",
    padding: "16 16 8 16",
    marginBottom: 16,
    borderBottom: "solid 3px #0E9CD5",
  },
  headerText: {
    fontSize: 14,
    color: "#1f497d",
    margin: "0 10",
  },
  bodyText: {
    fontSize: "10px",
    color: "#343a40",
    margin: "0",
    lineHeight: "1.5px",
  },
  secondaryHeader: {
    fontSize: 16,
    margin: "8 0",
  },
  flex: {
    display: "flex",
  },
  bold: {
    fontFamily: "PoppinsBold",
  },
  italic: {
    fontFamily: "PoppinsItalic",
  },
  justifiedRight: {
    textAlign: "right",
  },
  orangeText: {
    color: "#E9682A",
  },
  grayText: {
    color: "#343a40",
  },

  blueText: {
    color: "#2C4596",
  },

  table: {
    width: "100%",
  },
  tableRow: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-around",
  },
  cell: {
    paddingTop: "5",
    paddingBottom: "3",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    border: "1 solid #ccc",
    width: "33.333333%",
    height: "100%",
    fontSize: "9",
  },
  headerCell: {
    backgroundColor: "#E9682A",
    color: "#FFFFFF",
  },
  totalPrice: {
    marginTop: "8px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    width: "100%",
  },
  upperCase: {
    textTransform: "uppercase",
  },
  lineText: {
    display: "flex",
    flexDirection: "row",
  },
});

function AgreementPageTwo() {

  return (
    <Page size="A4" style={styles.page}>
      {/* Page Container */}
      <View
        style={{
          width: "100%",
          display: "flex",
          // border: "1px solid #ccc",
          boxSizing: "border-box",
          justifyContent: "space-between",
          height: "100%",
        }}
      >
        {/* Header */}
        <View
          style={[
            {
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
              flexDirection: "row",
              alignItems: "center",
              backgroundColor: "#1D4091",
              color: "#fff",
              padding: "20 16 20 16",
              borderBottom: "4px solid #0E9CD5",
              marginBottom: 0,
              fontSize: 14,
              height: "72px",
            },
            styles.upperCase,
          ]}
        >
          {/* Page title */}
          {/* <Text>Cotización Comercial</Text> */}
          <Image
            style={{
              height: "28px",
            }}
            src={`/assets/commercial quotes images/powersolar-logo-white.png`}
          />
        </View>

        {/* Page Content */}
        <View
          style={[
            {
              padding: '48 48 24 48',
              display: "flex",
              flexDirection: "column",
              height: "100%",
            },
            styles.bodyText,
          ]}
        >
          <View
            style={[
              {
                fontSize: "16px",
                marginBottom: 16,
                textAlign: "center",
              },
              styles.bold,
              styles.upperCase,
              styles.grayText,
            ]}
          >
            <Text>TÉRMINOS, CONDICIONES Y LIMITACIONES</Text>
          </View>
          <Text style={[{ marginBottom: 12 }]}>
            Esta garantía cubre solamente el área tratada indicada
            anteriormente. El dueño del edificio debe notificar por escrito a
            Power Solar, LLC antes de un término de treinta (30) días a partir
            de la fecha en que descubra una filtración o defecto.
          </Text>

          <Text style={[{ marginBottom: 12 }]}>
            El dueño debe otorgar a Power Solar, LLC oportunidad razonable para
            inspeccionar, probar y evaluar las condiciones y defectos que puedan
            estar involucrados en cualquier reclamo.
          </Text>

          <Text style={[{ marginBottom: 12 }]}>
            Power Solar, LLC, o su representante autorizado, inspeccionará el
            sistema de impermeabilizante y si se determina que la causa de la
            filtración es debido a una falla en los materiales o mano de obra
            utilizada en su instalación, corregirá o hará los arreglos para
            corregir la filtración sin costo alguno a el dueño. Dicha reparación
            estará sujeta a las limitaciones de costo antes mencionadas.
          </Text>

          <Text style={[{ marginBottom: 12 }]}>
            Esta garantía no aplica si el daño ocurrido al sistema
            impermeabilizante es debido a, o como consecuencia de lo siguiente:
          </Text>

          {/* a. */}
          <View
            style={[
              {
                display: "flex",
                flexDirection: "row",
                marginBottom: 16,
              },
            ]}
          >
            <Text style={{ paddingLeft: 12, width: "30px" }}>a. {"  "}</Text>
            <Text
              style={{
                width: "calc(100% - 30px)",
                textAlign: "justify",
                textJustify: "inter-word",
              }}
            >
              Desastres naturales tales como, pero no limitados a rayos,
              huracanes, tormentas, tornados, temblores de tierra y ráfagas de
              viento o granizo.
            </Text>
          </View>

          {/* b. */}
          <View
            style={[
              {
                display: "flex",
                flexDirection: "row",
                marginBottom: 16,
              },
            ]}
          >
            <Text style={{ paddingLeft: 12, width: "30px" }}>b. {"  "}</Text>
            <Text
              style={{
                width: "calc(100% - 30px)",
                textAlign: "justify",
                textJustify: "inter-word",
              }}
            >
              Negligencia, mal uso, maltrato de la superficie tratada por parte
              de cualquier persona o falta de el dueño en proveer un
              mantenimiento razonable al techo.
            </Text>
          </View>

          {/* c. */}
          <View
            style={[
              {
                display: "flex",
                flexDirection: "row",
                marginBottom: 16,
              },
            ]}
          >
            <Text style={{ paddingLeft: 12, width: "30px" }}>c. {"  "}</Text>
            <Text
              style={{
                width: "calc(100% - 30px)",
                textAlign: "justify",
                textJustify: "inter-word",
              }}
            >
              Accidentes, vandalismo y otros eventos incontrolables.
            </Text>
          </View>

          {/* d. */}
          <View
            style={[
              {
                display: "flex",
                flexDirection: "row",
                marginBottom: 16,
              },
            ]}
          >
            <Text style={{ paddingLeft: 12, width: "30px" }}>d. {"  "}</Text>
            <Text
              style={{
                width: "calc(100% - 30px)",
                textAlign: "justify",
                textJustify: "inter-word",
              }}
            >
              Filtraciones causadas o atribuibles a daños o deterioro en áreas
              del techo o áreas de este como resultado de un drenaje inadecuado.
            </Text>
          </View>

          {/* e. */}
          <View
            style={[
              {
                display: "flex",
                flexDirection: "row",
                marginBottom: 16,
              },
            ]}
          >
            <Text style={{ paddingLeft: 12, width: "30px" }}>e. {"  "}</Text>
            <Text
              style={{
                width: "calc(100% - 30px)",
                textAlign: "justify",
                textJustify: "inter-word",
              }}
            >
              Cualquier daño incidental o por consecuencia, incluyendo, pero no
              limitado a estructuras construidas en el techo luego de la
              impermeabilización, condensadoras que tengan fallas de vapor
              instaladas en el techo, trabajos en acero u obstrucciones
              metálicas (corroídas) que tengan un efecto negativo en la membrana
              instalada.
            </Text>
          </View>

          {/* f. */}
          <View
            style={[
              {
                display: "flex",
                flexDirection: "row",
                marginBottom: 16,
              },
            ]}
          >
            <Text style={{ paddingLeft: 12, width: "30px" }}>f. {"  "}</Text>
            <Text
              style={{
                width: "calc(100% - 30px)",
                textAlign: "justify",
                textJustify: "inter-word",
              }}
            >
              Deterioro de la membrana causado por condensación de humedad
              alrededor o debajo de la membrana instalada.
            </Text>
          </View>

          {/* g. */}
          <View
            style={[
              {
                display: "flex",
                flexDirection: "row",
                marginBottom: 16,
              },
            ]}
          >
            <Text style={{ paddingLeft: 12, width: "30px" }}>g. {"  "}</Text>
            <Text
              style={{
                width: "calc(100% - 30px)",
                textAlign: "justify",
                textJustify: "inter-word",
              }}
            >
              Falla de otros componentes del techo no aprobados por escrito por
              escrito por Power Solar, LLC.
            </Text>
          </View>

          {/* h. */}
          <View
            style={[
              {
                display: "flex",
                flexDirection: "row",
                marginBottom: 16,
              },
            ]}
          >
            <Text style={{ paddingLeft: 12, width: "30px" }}>h. {"  "}</Text>
            <Text
              style={{
                width: "calc(100% - 30px)",
                textAlign: "justify",
                textJustify: "inter-word",
              }}
            >
              Abuso debido al tráfico excesivo, movimiento o almacenaje de
              cualquier equipo u objetos ajenos al sistema de
              impermeabilización.
            </Text>
          </View>

          {/* i. */}
          <View
            style={[
              {
                display: "flex",
                flexDirection: "row",
                marginBottom: 16,
              },
            ]}
          >
            <Text style={{ paddingLeft: 12, width: "30px" }}>i. {"  "}</Text>
            <Text
              style={{
                width: "calc(100% - 30px)",
                textAlign: "justify",
                textJustify: "inter-word",
              }}
            >
              Fallas debido a, o como consecuencia de, fallas estructurales
              tales como, pero no limitadas a asentamiento de los cimientos,
              rajaduras en la placa del techo, paredes o vigas
            </Text>
          </View>
        </View>
        {/* Power Solar Contact Info */}
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: "100%",
            backgroundColor: "#F2F2F2",
            padding: "16px",
            fontSize: 10,
            color: "#2C4596",
          }}
        >
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <Image
              style={{
                height: "14px",
                display: "flex",
                flexDirection: "row",
                marginRight: 4,
                padding: 2,
              }}
              src="/assets/icons8-phone-64-blue.png"
            />
            <Text style={[styles.whiteSmallText]}>787.331.100</Text>
          </View>

          <View
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <Image
              style={{
                height: "14px",
                display: "flex",
                flexDirection: "row",
                marginRight: 4,
                padding: 2,
              }}
              src="/assets/icons8-website-64-blue.png"
            />
            <Text style={[styles.whiteSmallText]}>powersolarpr.com</Text>
          </View>

          <View
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <Image
              style={{
                height: "14px",
                width: "auto",
                display: "flex",
                flexDirection: "row",
                marginRight: 4,
                padding: 2,
              }}
              src="/assets/icons8-facebook-64-blue.png"
            />
            <Text style={[styles.whiteSmallText]}>PowerSolarPR</Text>
          </View>

          <View
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <Image
              style={{
                height: "14px",
                display: "flex",
                flexDirection: "row",
                marginRight: 4,
                padding: 2,
              }}
              src="/assets/icons8-location-64-blue.png"
            />
            <Text style={[styles.whiteSmallText]}>
              279 calle César L. Gónzalez • San Juan, P.R. 00918
            </Text>
          </View>
        </View>
      </View>
    </Page>
  );
}

export default AgreementPageTwo;
