import React, { useState, useEffect } from "react";
import { BasicFormWrapper } from "../../../../containers/styled";
import Heading from "../../../heading/heading";
import { Cards } from "../../../cards/frame/cards-frame";
import { Link } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import { connect } from "react-redux";
import { formatter } from "../../../../helpers/helper";
import { getProductPrice } from "../../../helpers/helper";
import { Form, Input } from "antd";

function QuoteReview(props) {
  const { form, products, setStep } = props;

  const [formValues, setFormValues] = useState({});

  useEffect(() => {
    setFormValues({ ...formValues, ...form.getFieldsValue(true) });
    // eslint-disable-next-line
  }, [form]);

  useEffect(() => {
    form.setFieldsValue({
      ...form.getFieldsValue(true),
      comments: formValues.comments,
      discount: formValues.discount,
    });
    // eslint-disable-next-line
  }, [formValues.comments, formValues.discount]);

  const hasFormValues =
    formValues["solarPanels"] ||
    formValues["inverters"] ||
    formValues["batteries"];

  const generateProducts = () => {
    if (hasFormValues) {
      let formProducts = [];
      if (formValues["solarPanels"]) {
        formProducts = [...formProducts, ...formValues["solarPanels"]];
      }
      if (formValues["inverters"]) {
        formProducts = [...formProducts, ...formValues["inverters"]];
      }
      if (formValues["batteries"]) {
        formProducts = [...formProducts, ...formValues["batteries"]];
      }
      return formProducts.map((p) => {
        let data = products.find((product) => product.id === p.id);
        return (
          <div>
            <hr style={{ borderTop: "1px solid #eeeeee" }} />
            <h3 className="orange-text">{data.name}</h3>
            {/* <p>{data.description}</p> */}
            <p>
              {/* <strong>Price:</strong>{" "} */}
              {formatter.format(
                getProductPrice(
                  data,
                  p.quantity,
                  formProducts.map((fp) => ({
                    ...fp,
                    product: products.find((p1) => p1.id === fp.id),
                  })),
                  formValues["finance_method"]
                ) / 100
              )}
            </p>
            <li>
              <strong>Quantity: </strong>
              {p.quantity}
            </li>
            <li>
              <strong>Total Price:</strong>{" "}
              {formatter.format(
                (getProductPrice(
                  data,
                  p.quantity,
                  formProducts.map((fp) => ({
                    ...fp,
                    product: products.find((p1) => p1.id === fp.id),
                  })),
                  formValues["finance_method"]
                ) *
                  parseInt(p.quantity, 10)) /
                100
              )}
            </li>
          </div>
        );
      });
    }

    return [];
  };

  const handleChange = (e) => {
    setFormValues({ ...formValues, [e.target.id]: e.target.value });
  };

  const generateDiscount = () => {
    if (formValues.discount && parseFloat(formValues.discount) > 0) {
      return parseFloat(formValues.discount);
    }

    return 0;
  };

  // Get united cost (10%)
  const generateUnitedCost = () => {
    return generateFinalPrice() - generateFinalPrice() / 1.1;
  };

  const generateFinalPrice = () => {
    if (hasFormValues) {
      let formProducts = [];
      if (formValues["solarPanels"]) {
        formProducts = [...formProducts, ...formValues["solarPanels"]];
      }
      if (formValues["inverters"]) {
        formProducts = [...formProducts, ...formValues["inverters"]];
      }
      if (formValues["batteries"]) {
        formProducts = [...formProducts, ...formValues["batteries"]];
      }
      let result = formProducts.reduce((sumPrice, p) => {
        let data = products.find((product) => product.id === p.id);
        return (sumPrice +=
          getProductPrice(
            data,
            p.quantity,
            formProducts.map((fp) => ({
              ...fp,
              product: products.find((p1) => p1.id === fp.id),
            })),
            formValues["finance_method"]
          ) * parseInt(p.quantity, 10));
      }, 0);

      // If financed through United, add 10% cost
      if (formValues["finance_method"] === "United") {
        result += result * 0.1;
      }

      return result / 100;
    }

    return 0;
  };

  const generateTotal = () => {
    let cost = generateFinalPrice() - generateDiscount();
    cost = cost > 0 ? cost : 0;

    return formatter.format(cost);
  };

  return (
    <BasicFormWrapper style={{ width: "100%" }}>
      <div className="atbd-review-order" style={{ width: "100%" }}>
        <Heading as="h4">4. Review And Confirm Quotation</Heading>
        <Cards
          bodyStyle={{ backgroundColor: "#F8F9FB", borderRadius: 10 }}
          headless
        >
          <div className="atbd-review-order__single">
            <Cards headless>
              <div className="atbd-review-order__shippingTitle">
                <Heading as="h5">
                  Customer Information
                  <Link to="#" onClick={() => setStep(0)}>
                    <FeatherIcon icon="edit" />
                    Edit
                  </Link>
                </Heading>
              </div>
              <article className="atbd-review-order__shippingInfo">
                <p>Customer Name: {formValues["customer_name"]}</p>
                <p>Address Line 1: {formValues["address_line1"]}</p>
                {formValues["address_line2"] && (
                  <p>Address Line 2: {formValues["address_line2"]}</p>
                )}
                <p>City: {formValues["state"]}</p>
                <p>Country: {formValues["country"]}</p>
                {formValues["luma_monthly_bill"] && (
                  <p>LUMA Monthly Bill: ${formValues["luma_monthly_bill"]}</p>
                )}
              </article>
            </Cards>
          </div>
          <div className="atbd-review-order__single">
            <Cards headless>
              <div className="atbd-review-order__shippingTitle">
                <Heading as="h5">
                  Finance Method
                  <Link to="#" onClick={() => setStep(1)}>
                    <FeatherIcon icon="edit" />
                    Edit
                  </Link>
                </Heading>
              </div>
              <p>{formValues["finance_method"]} {formValues["finance_method"] === "United" ? `(+${formatter.format(generateUnitedCost())})` : ""}</p>
            </Cards>
          </div>
          <div className="atbd-review-order__single">
            <Cards headless>
              <div className="atbd-review-order__shippingTitle">
                <Heading as="h5">
                  Products
                  <Link to="#" onClick={() => setStep(2)}>
                    <FeatherIcon icon="edit" />
                    Edit
                  </Link>
                </Heading>
              </div>
              {generateProducts()}
            </Cards>
          </div>
          <div className="atbd-review-order__single">
            <Cards headless bodypadding={"10px"}>
              <div>
                <Heading as="h5">Discount</Heading>
              </div>
              <div className="promo-apply-form">
                <Form.Item name="discount" label="" onChange={handleChange}>
                  <Input placeholder="500" />
                </Form.Item>
              </div>
              <br></br>
            </Cards>
          </div>
          <div className="atbd-review-order__single">
            <Cards headless>
              <div>
                <Heading as="h5">Quote Summary </Heading>
              </div>
              {formValues.discount && parseFloat(formValues.discount) > 0 && (
                <>
                  <p>
                    Sub-Total:{" "}
                    <strong>{formatter.format(generateFinalPrice())}</strong>
                  </p>

                  <p className="text-danger">
                    Discount:{" "}
                    <strong>-{formatter.format(generateDiscount())}</strong>
                  </p>

                  <hr></hr>
                </>
              )}
              <h2>
                Total: <strong>{generateTotal()}</strong>
              </h2>
            </Cards>
          </div>
          <div className="atbd-review-order__single">
            <Cards headless bodypadding={"10px"}>
              <div>
                <Heading as="h5">Comments</Heading>
              </div>
              <div className="promo-apply-form">
                <Form.Item
                  name="comments"
                  label=""
                  onChange={handleChange}
                  value={formValues.comments ? formValues.comments : ""}
                >
                  <Input.TextArea
                    placeholder="Add any comments here"
                    rows={3}
                  />
                </Form.Item>
              </div>
            </Cards>
          </div>
        </Cards>
      </div>
    </BasicFormWrapper>
  );
}

let mapStateToProps = (state) => ({
  products: state.products.products,
});

export default connect(mapStateToProps)(QuoteReview);
