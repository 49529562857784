import { API_URL } from "../constants/apiURLConstants";
import {
  LOADING_ROOF_OBJECTS,
  CLEAR_LOADING_ROOF_OBJECTS,
  FETCH_ROOF_OBJECTS,
} from "../constants/roofObjectConstants";
import axios from "axios";
import { errorHandler } from "../helpers.js/helper";

export function fetchRoofObjectsAction() {
  return (dispatch) => {
    dispatch({ type: LOADING_ROOF_OBJECTS });
    return (
      axios
        .get(`${API_URL}roof-objects`, {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        // eslint-disable-next-line consistent-return
        .then((responseJSON) => {
          if (responseJSON && responseJSON.data) {
            dispatch({ type: FETCH_ROOF_OBJECTS, payload: responseJSON.data });
            return responseJSON.data;
          }
          dispatch({ type: CLEAR_LOADING_ROOF_OBJECTS });
          return {};
        })
        .catch((error) => {
          errorHandler(dispatch, error, () => {
            dispatch({ type: CLEAR_LOADING_ROOF_OBJECTS });
          });
        })
    );
  };
}



