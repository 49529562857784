import React from "react";
import { Form, Input } from "antd";
import moment from 'moment'


function EditQuotationForm(props) {
  const {
    handleSubmit,
    id,
    created_at,
    customer_name,
    address_line1,
    address_line2,
    state,
    country,
    // comments,
    // user,
    // quote_products,
    form,
  } = props;



  return (
    <Form
      name="editQuote"
      form={form}
      onFinish={handleSubmit}
      layout="vertical"
    >
      <p>ID: {id}</p>
      <p>Created at: {moment(created_at).format('DD/MMM/YYYY  hh:mm a')}</p>
      <Form.Item
        name="customer_name"
        rules={[{ message: "Please input customer name!", required: true }]}
        initialValue={customer_name ? customer_name : ""}
        label="Customer name"
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="address_line1"
        rules={[
          { message: "Please input customer address line!", required: true },
        ]}
        initialValue={address_line1 ? address_line1 : ""}
        label="Address Line 1"
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="address_line2"
        initialValue={address_line2 ? address_line2 : ""}
        label="Address Line 2"
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="state"
        initialValue={state ? state : ""}
        label="State"
        rules={[{ message: "Please input customer state!", required: true }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="country"
        initialValue={country ? country : ""}
        label="Country"
        rules={[{ message: "Please input customer country!", required: true }]}
      >
        <Input />
      </Form.Item>

      {/* <Form.Item>
                <Button className="btn-signin" htmlType="submit" type="primary" size="large">
                {loading ? 'Loading...' : 'Sign In'}
                </Button>
            </Form.Item> */}
    </Form>
  );
}

export default EditQuotationForm;
