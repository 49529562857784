import React from "react";
import { Page, Text, View, StyleSheet, Image, Font } from "@react-pdf/renderer";
import moment from "moment";
import "moment/locale/es";
import { newLanguages as text } from "./lang";
import PoppinsRegular from "../../assets/fonts/Poppins-Regular.ttf";
import PoppinsBold from "../../assets/fonts/Poppins-Bold.ttf";
import PoppinsBoldItalic from "../../assets/fonts/Poppins-BoldItalic.ttf";
import ZeyadaRegular from "../../assets/fonts/Zeyada-Regular.ttf";

Font.register({
  family: "Zeyada",
  format: "truetype",
  src: ZeyadaRegular,
});

Font.register({
  family: "Poppins",
  format: "truetype",
  src: PoppinsRegular,
});

Font.register({
  family: "PoppinsBold",
  format: "truetype",
  src: PoppinsBold,
});

Font.register({
  family: "PoppinsBoldItalic",
  format: "truetype",
  src: PoppinsBoldItalic,
});

const styles = StyleSheet.create({
  page: {
    backgroundColor: "#FFF",
    boxSizing: "border-box",
    fontSize: 11,
    fontFamily: "Poppins",
  },
  header: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
  },
  headerText: {
    fontSize: 24,
    color: "#1f497d",
    margin: "0 10",
    fontFamily: "PoppinsBold",
  },
  secondaryHeader: {
    fontSize: 16,
    margin: "8 0",
    fontFamily: "PoppinsBold",
  },
  bodyText: {
    fontSize: 11,
    color: "#343a40",
    margin: "0 10",
  },
  flex: {
    display: "flex",
  },
  bold: {
    fontFamily: "PoppinsBold",
  },
  boldItalic: {
    fontFamily: "PoppinsBoldItalic",
  },
  justifiedRight: {
    textAlign: "right",
  },
  upperCase: {
    textTransform: "uppercase",
  },
  orangeText: {
    color: "#E9682A",
  },
  grayText: {
    color: "#343a40",
  },
  blueText: {
    color: "#2C4596",
  },
  table: {
    width: "100%",
    borderRight: "1px solid #FFF",
  },
  tableRow: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-around",
  },
  cell: {
    paddingTop: "12",
    paddingBottom: "10",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    borderLeft: "1px solid #E6E6E6",
    borderBottom: "1px solid #E6E6E6",
    width: "33.333333%",
    height: "100%",
    fontSize: "10",
    backgroundColor: "#E6E6E6",
  },
  cell100: {
    paddingTop: "12",
    paddingBottom: "10",
    paddingLeft: "8",
    paddingRight: "8",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    borderLeft: "1px solid #E6E6E6",
    borderBottom: "1px solid #E6E6E6",
    width: "100%",
    height: "100%",
    fontSize: "10",
    backgroundColor: "#E6E6E6",
  },
  cell15: {
    padding: "0px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    borderLeft: "1px solid #E6E6E6",
    borderBottom: "1px solid #E6E6E6",
    width: "15%",
    fontSize: "10",
    backgroundColor: "#E6E6E6",
  },
  headerCell: {
    backgroundColor: "#2C4596",
    color: "#FFFFFF",
    border: "1px solid #2C4596",
  },
});

export default function AgreementsPage(props) {
  const { quote, lang } = props;

  const generateDate = () => {
    let localLocale = moment();
    localLocale.locale("es");

    return localLocale.format("DD [de] MMMM [de] YYYY");
  };

  const generateKW = () => {
    if (quote.quote_products) {
      let result = quote.quote_products.reduce((sum, qp) => {
        if (qp.product.category === "Solar Panel")
          return sum + parseFloat(qp.product.kw, 10) * qp.quantity;
        return sum;
      }, 0);

      return result;
    }
    return 0;
  };

  // const generateExpiryDate = () => {
  //   let localLocale = moment().add(5, "days");
  //   localLocale.locale("es");

  //   return localLocale.format("DD [de] MMMM [de] YYYY");
  // };

  return (
    <Page size="A4" style={styles.page}>
      {/* Page Container */}
      <View
        style={{
          width: "100%",
          display: "flex",
          border: "1px solid #ccc",
          padding: "48 0 0 0",
          boxSizing: "border-box",
          justifyContent: "space-between",
          alignItems: "center",
          height: "100%",
        }}
      >
        {/* Page title  */}
        <View
          style={[
            styles.headerText,
            styles.upperCase,
            { width: "100%", textAlign: "center" },
          ]}
        >
          <Text
            style={[
              styles.blueText,
              styles.bold,
              { fontSize: 28, width: "100%", textAlign: "center" },
            ]}
          >
            {text[lang].agreementTitleBlue}
          </Text>
          <Text
            style={[
              styles.orangeText,
              styles.bold,
              {
                fontSize: 28,
                width: "100%",
                textAlign: "center",
              },
            ]}
          >
            {text[lang].agreementTitleOrange}
          </Text>
        </View>

        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            width: "100%",
            padding: "48px",
          }}
        >
          {/*Column 1 */}
          <View
            style={{
              display: "flex",
              flexDirection: "column",
              width: "50%",
              marginRight: 8,
            }}
          >
            <View style={[styles.lineText, { marginBottom: 16 }]}>
              <Text>• {text[lang].agreementFirstParagraph(generateKW())}</Text>
            </View>

            <View style={[styles.lineText, { marginBottom: 16 }]}>
              <Text>• {text[lang].agreementSecondParagraph}</Text>
            </View>

            <View style={[styles.lineText, { marginBottom: 16 }]}>
              <Text>• {text[lang].agreementThirdParagraph}</Text>
            </View>

            <View style={[styles.lineText, { marginBottom: 16 }]}>
              <Text>• {text[lang].agreementFourthParagraph}</Text>
            </View>
          </View>
          {/*Column 2 */}
          <View
            style={{
              display: "flex",
              flexDirection: "column",
              width: "50%",
              marginLeft: 8,
            }}
          >
            <View style={[styles.lineText, { marginBottom: 16 }]}>
              <Text>• {text[lang].agreementFifthParagraph}</Text>
            </View>

            <View style={[styles.lineText, { marginBottom: 16 }]}>
              <Text>• {text[lang].agreementSixthParagraph}</Text>
            </View>

            <View style={[styles.lineText, { marginBottom: 16 }]}>
              <Text>• {text[lang].agreementSeventhParagraph}</Text>
            </View>

            <View style={[styles.lineText, { marginBottom: 16 }]}>
              <Text>• {text[lang].agreementReview}</Text>
            </View>
          </View>
        </View>

        {/* Signature Section */}
        <View
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            width: "100%",
            padding: "0px 48px",
          }}
        >
          {/* Consultor y fecha */}
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              borderBottom: "1px solid #7b7b7b",
              paddingBottom: "5px",
              marginBottom: "12px",
            }}
          >
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                width: "50%",
                justifyContent: "center",
              }}
            >
              <Text style={styles.bold}>
                {" "}
                {text[lang].agreementConsultant}:
              </Text>
              <Text> {quote.user.name}</Text>
            </View>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                width: "50%",
                justifyContent: "center",
              }}
            >
              <Text style={styles.bold}> {text[lang].agreementDate}: </Text>
              <Text> {generateDate()}</Text>
            </View>
          </View>

          {/* Firmas */}

          <View
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <View
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                width: "49%",
              }}
            >
              <Text
                style={{
                  fontSize: "24px",
                  color: "#2C4596",
                  textAlign: "center",
                  //   marginBottom: "4px",
                  fontFamily: "Zeyada",
                }}
              >
                {quote.user.name}
              </Text>
              <Text style={[{ textAlign: "center" }, styles.bold]}>
                {text[lang].agreementSignConsultant}
              </Text>
            </View>

            <View
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                width: "49%",
              }}
            >
              <Text
                style={{
                  fontSize: "24px",
                  color: "#2C4596",
                  textAlign: "center",
                  //   marginBottom: "4px",
                  fontFamily: "Zeyada",
                }}
              >
                {" "}
              </Text>
              <Text style={[{ textAlign: "center" }, styles.bold]}>
                {text[lang].agreementSignOwner}
              </Text>
            </View>
          </View>
        </View>

        {/* Footer */}
        <Image
          style={{
            width: "100%",
            display: "flex",
            alignSelf: "center",
          }}
          src={`/assets/bottom-triangles-border.png`}
        />
      </View>
    </Page>
  );
}
