import React, { useState, useEffect } from "react";
import { BasicFormWrapper } from "../../../../containers/styled";
import Heading from "../../../heading/heading";
import FeatherIcon from "feather-icons-react";
import { Row, Col, Form, Input } from "antd";

function ViviendaInformationForm(props) {
  const { form } = props;

  const [result, setResult] = useState("");
  const [showResult, setShowResult] = useState(false);

  const handleChange = () => {
    let formValues = form.getFieldsValue();

    if (
      formValues.annual_client_consumption_capacity &&
      parseInt(formValues.annual_client_consumption_capacity, 10) > 0 &&
      formValues.annual_client_consumption &&
      parseInt(formValues.annual_client_consumption, 10) > 0
    ) {
      let txt = "";
      txt = formValues.annual_client_consumption_capacity + " / ";
      txt += formValues.annual_client_consumption + " = ";
      txt +=
        Math.round(
          (parseInt(
            formValues.annual_client_consumption_capacity.replace(/,/g, ""),
            10
          ) /
            parseInt(
              formValues.annual_client_consumption.replace(/,/g, ""),
              10
            )) *
            1000
        ) /
          10 +
        "%";

      setResult(txt);
      setShowResult(true);
    } else {
      setShowResult(false);
      setResult("");
    }
  };

  useEffect(() => {
    handleChange();
    // eslint-disable-next-line
  }, []);

  return (
    <BasicFormWrapper className="basic-form-inner">
      <div className="atbd-form-checkout">
        <Row justify="center">
          <Col sm={22} xs={24}>
            <div className="shipping-form">
              <Heading as="h4">2. Información Energía 2</Heading>
              <Form form={form} onFieldsChange={handleChange} name="address">
                <Form.Item name="ticket_number" label="Número de Boleto">
                  <Input placeholder="" />
                </Form.Item>
                <Form.Item
                  name="annual_client_consumption"
                  label="Suma de Factura kWh (12 meses)"
                  // eslint-disable-next-line react/jsx-no-duplicate-props
                  rules={[
                    { required: true, message: "Suma de factura es requerido" },
                  ]}
                >
                  <Input placeholder="" />
                </Form.Item>
                <Form.Item
                  name="annual_client_consumption_capacity"
                  label="Producción de PVWatts anual kW"
                  style={{ marginBottom: "5px" }}
                >
                  <Input placeholder="" />
                </Form.Item>
                <a
                  href="https://pvwatts.nrel.gov"
                  style={{
                    textDecoration: "underline",
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Calculadora de PVWatts{" "}
                  <FeatherIcon
                    style={{ marginLeft: "2px" }}
                    size={16}
                    icon="external-link"
                  />
                </a>

                <hr style={{ marginTop: "24px" }} />
                {showResult && (
                  <Form.Item
                    label=" Producción Anual Simulada por PVWatts kWh / Consumo de Energía
                  Anual kWh Anual kW:"
                  >
                    <p
                      className="orange-text .text-center"
                      style={{ fontSize: "24px" }}
                    >
                      <strong>{result}</strong>
                    </p>
                  </Form.Item>
                )}
              </Form>
            </div>
          </Col>
        </Row>
      </div>
    </BasicFormWrapper>
  );
}

export default ViviendaInformationForm;
