import { LOGOUT } from "../constants/authConstants";

export const errorHandler = (dispatch, error, callback) => {
  if (
    error &&
    error.response && error.response.data &&
    error.response.data.message &&
    error.response.data.message === "Unauthenticated."
  ) {
    localStorage.removeItem("token");
    localStorage.removeItem("currentUser");

    dispatch({ type: LOGOUT });
  }

  return callback();
};
