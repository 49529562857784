import React, { useState, useCallback } from "react";
import { connect } from "react-redux";
// eslint-disable-next-line import/no-extraneous-dependencies
import { AuthWrapper } from "./style";
import { sendResetPasswordLinkAction } from "../../redux/actions/authActions";
import AuthLayout from "../../components/layouts/AuthLayout";
import ResetForm from "../../components/forms/ResetForm";

const ResetPassword = (props) => {
  const { sendResetPasswordLink, loading } = props;

  const [submitResponse, setSubmitResponse] = useState({});

  const submitForm = useCallback((values) => {
    sendResetPasswordLink({
      email: values.email,
    }).then((result) => {
      setSubmitResponse(result);
    });

  }, [sendResetPasswordLink, setSubmitResponse]);

  return (
    <AuthLayout>
      <AuthWrapper>
        <div className="auth-contents">
          <ResetForm
            onSubmit={(values) => submitForm(values)}
            loading={loading}
            submitResponse={submitResponse}
          />
        </div>
      </AuthWrapper>
    </AuthLayout>
  );
};

const mapStateToProps = (state) => ({
  loading: state.auth.loading,
});

const mapDispatchToProps = (dispatch) => ({
  sendResetPasswordLink: (formValues) =>
    dispatch(sendResetPasswordLinkAction(formValues)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
