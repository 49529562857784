import React, { Suspense } from 'react';
import { Row, Col, Skeleton } from 'antd';
import { WizardBlock } from './Style';
import { PageHeader } from '../../../components/page-headers/page-headers';
import { Main } from '../../styled';
import { Cards } from '../../../components/cards/frame/cards-frame';
import CommercialQuotationForm from '../../../components/forms/CommercialQuotationForm/CommercialQuotationForm';

const CreateCommercialQuotationContainer = () => {

  return (
    <>
      <PageHeader
        title="Create Commercial Quotation"
      />
      <Main>
        <Row gutter={25}>
          <Col sm={24} xs={24}>
            <Suspense
              fallback={
                <Cards headless>
                  <Skeleton paragraph={{ rows: 20 }} active />
                </Cards>
              }
            >
                <WizardBlock>
                    <Cards headless>
                    <Row justify="center">
                        <Col xxl={20} xs={24}>
                            <CommercialQuotationForm />
                        </Col>
                    </Row>
                    </Cards>
                </WizardBlock>
            </Suspense>
          </Col>
        </Row>
      </Main>
    </>
  );
};

export default CreateCommercialQuotationContainer;
