import React from "react";
import { Page, Text, View, StyleSheet, Image, Font } from "@react-pdf/renderer";
import { formatter } from "../../helpers/helper";
import { getProductPrice } from "../helpers/helper";
import moment from "moment";
import "moment/locale/es";
import PoppinsRegular from "../../assets/fonts/Poppins-Regular.ttf";
import PoppinsBold from "../../assets/fonts/Poppins-Bold.ttf";
import ZeyadaRegular from "../../assets/fonts/Zeyada-Regular.ttf";

Font.register({
  family: "Poppins",
  format: "truetype",
  src: PoppinsRegular,
});

Font.register({
  family: "PoppinsBold",
  format: "truetype",
  src: PoppinsBold,
});

Font.register({
  family: "Zeyada",
  format: "truetype",
  src: ZeyadaRegular,
});

const styles = StyleSheet.create({
  page: {
    // flexDirection: "row",
    backgroundColor: "#FFF",
    boxSizing: "border-box",
    fontSize: 8,
    padding: 24,
    fontFamily: "Poppins",
  },
  // section: {
  //   margin: 10,
  //   padding: 10,
  //   flexGrow: 1,
  // },
  header: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
  },
  headerText: {
    fontSize: 12,
    color: "#1f497d",
    margin: "0 10",
  },
  bodyText: {
    fontSize: 8,
    color: "#343a40",
    // margin: "0 10",
  },
  secondaryHeader: {
    fontSize: 10,
    margin: "8 0",
  },
  flex: {
    display: "flex",
  },
  bold: {
    fontFamily: "PoppinsBold",
  },
  justifiedRight: {
    textAlign: "right",
  },
  orangeText: {
    color: "#E9682A",
  },
  grayText: {
    color: "#343a40",
  },
  table: {
    width: "100%",
    borderRight: "1px solid #E9682A",
  },
  tableRow: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-around",
  },
  cell: {
    paddingTop: "5",
    paddingBottom: "3",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    borderLeft: "1px solid #E9682A",
    borderBottom: "1px solid #E9682A",
    width: "33.333333%",
    height: "100%",
    fontSize: "8",
  },
  headerCell: {
    backgroundColor: "#E9682A",
    color: "#FFFFFF",
    border: "0px solid #E9682A",
  },
  totalPrice: {
    marginTop: "8px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    width: "100%",
  },
  upperCase: {
    textTransform: "uppercase",
  },
  lineText: {
    display: "flex",
    flexDirection: "row",
  },

  boxedText: {
    padding: "4px 8px 2px 8px",
    borderRadius: "3px",
    border: "1px solid #dcdcdc",
    width: "100%",
  },

  marginSpacer: {
    marginTop: "6px",
    marginBottom: "4px",
  },
});

export default function ViviendaEstimatePage(props) {
  const { values, products, user } = props;

  const generateDate = () => {
    let localLocale = moment();
    localLocale.locale("es");

    return localLocale.format("DD [de] MMMM [de] YYYY");
  };

  const generateProducts = () => {
    let formProducts = [];
    let hasSolarPanels = false;
    if (values["solarPanels"] && values["solarPanels"].length > 0) {
      formProducts = [...formProducts, ...values["solarPanels"]];
      hasSolarPanels = true;
    }
    if (values["inverters"] && values["inverters"].length > 0) {
      formProducts = [...formProducts, ...values["inverters"]];
    }
    if (values["batteries"] && values["batteries"].length > 0) {
      formProducts = [...formProducts, ...values["batteries"]];
    }

    let productRows = formProducts.map((p) => {
      let data = products.find((product) => product.id === p.id);

      let productName = "";

      switch (data.category) {
        case "Solar Panel":
          productName = "Panel Solar";
          break;
        case "Battery":
          productName = "Batería Almacenamiento";
          break;
        case "Inverter":
          productName = "Inversor";
          break;
        default:
      }

      return (
        <View style={[styles.tableRow]} key={data.id}>
          <Text style={[styles.cell]}>{productName}</Text>
          <Text style={[styles.cell]}>{data.description}</Text>
          <Text style={[styles.cell]}>{p.quantity}</Text>
        </View>
      );
    });

    if (hasSolarPanels) {
      productRows.push(
        <View style={[styles.tableRow]}>
          <Text style={[styles.cell]}>Anclaje</Text>
          <Text style={[styles.cell]}>Unirac</Text>
          <Text style={[styles.cell]}>Incluído</Text>
        </View>
      );
    }

    productRows.push(
      <View style={[styles.tableRow]}>
        <Text style={[styles.cell]}></Text>
        <Text style={[styles.cell]}>Costo del Sistema</Text>
        <Text style={[styles.cell]}>
          {formatter.format(generateFinalPrice())}
        </Text>
      </View>
    );

    productRows.push(
      <View style={[styles.tableRow]}>
        <Text style={[styles.cell]}></Text>
        <Text style={[styles.cell]}>
          Costo del Contrato (
          {generateFinalPrice() * 0.3 > 15000 ? "$15,000.00" : "30%"})
        </Text>
        <Text style={[styles.cell]}>
          {formatter.format(
            generateFinalPrice() * 0.3 > 15000
              ? 15000
              : generateFinalPrice() * 0.3
          )}
        </Text>
      </View>
    );

    productRows.push(
      <View style={[styles.tableRow]}>
        <Text style={[styles.cell]}></Text>
        <Text style={[styles.cell]}>Total </Text>
        <Text style={[styles.cell]}>
          {formatter.format(generateTotalPrice())}
        </Text>
      </View>
    );

    return productRows;
  };

  // Returns the sum of all prices in the products
  const generateFinalPrice = () => {
    let formProducts = [];
    if (values["solarPanels"] && values["solarPanels"].length > 0) {
      formProducts = [...formProducts, ...values["solarPanels"]];
    }
    if (values["inverters"] && values["inverters"].length > 0) {
      formProducts = [...formProducts, ...values["inverters"]];
    }
    if (values["batteries"] && values["batteries"].length > 0) {
      formProducts = [...formProducts, ...values["batteries"]];
    }

    let sumPrice = 0;

    formProducts.forEach((p) => {
      let data = products.find((product) => product.id === p.id);

      sumPrice += getProductPrice(data, p.quantity) * parseInt(p.quantity, 10);
    });

    return sumPrice / 100;
  };

  // Returns the sum of all prices in the products with the applied discount
  const generateTotalPrice = () => {
    let price = generateFinalPrice();

    // Applies 30% or 15k discount, whichever one ios lower
    if (price * 0.3 > 15000) {
      price -= 15000;
    } else {
      price *= 0.7;
    }

    return price;
  };

  const generateTotalProduction = () => {
    let formProducts = [];
    if (values["solarPanels"] && values["solarPanels"].length > 0) {
      formProducts = [...formProducts, ...values["solarPanels"]];
    }
    if (values["inverters"] && values["inverters"].length > 0) {
      formProducts = [...formProducts, ...values["inverters"]];
    }
    if (values["batteries"] && values["batteries"].length > 0) {
      formProducts = [...formProducts, ...values["batteries"]];
    }

    let wattsTotal = 0;

    // Loop over form products
    formProducts.forEach((p) => {
      // Find form product info
      let data = products.find((product) => product.id === p.id);
      // Calculate total Watts sum
      wattsTotal += data.kw
        ? parseInt(data.kw, 10) * parseInt(p.quantity, 10)
        : 0;
    });

    // Apply production generated formula and round to one decimal
    wattsTotal = Math.trunc((wattsTotal / 1000) * 10) / 10;

    return wattsTotal;
  };

  return (
    <Page size="A4" style={styles.page}>
      {/* Page Container */}
      <View
        style={{
          width: "100%",
          display: "flex",
          boxSizing: "border-box",
          justifyContent: "space-between",
          height: "100%",
          padding: "30px",
          color: "#343434",
        }}
      >
        {/* Header */}
        <View
          style={[
            {
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "row",
              alignItems: "flex-start",
              marginBottom: 0,
              fontSize: 12,
            },
          ]}
        >
          <View
            style={[
              {
                width: "100%",
                display: "flex",
                flexDirection: "column",
              },
            ]}
          >
            <View
              style={[
                {
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  fontSize: "20px",
                },
                styles.bold,
              ]}
            >
              <Text>Acuerdo de Renta con Opción a Compra</Text>
            </View>
            <Text style={styles.grayText}>Power Solar, LLC</Text>
          </View>
          <Image
            style={{
              height: "34px",
            }}
            src={`/assets/commercial quotes images/powersolar-logo-black.png`}
          />
        </View>

        {/* Page Content */}

        <View
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          {/* Informacion de boleto */}

          {/* Nombre,  Sunnova/PS ID y # de boleto */}
          <View
            style={[
              {
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                borderBottom: "1px solid #7b7b7b",
                paddingBottom: "10px",
              },
              styles.marginSpacer,
            ]}
          >
            {/* Name Field */}
            <View
              style={[
                {
                  display: "flex",
                  width: "44%",
                  flexDirection: "row",
                  alignItems: "center",
                  textAlign: "left",
                },
              ]}
            >
              <Text
                style={[
                  {
                    textAlign: "left",
                    width: "46px",
                    marginRight: "5px",
                  },
                  styles.bold,
                  styles.bodyText,
                ]}
              >
                Nombre
              </Text>
              <Text style={[styles.boxedText]}>{values.customer_name}</Text>
            </View>

            {/* Ticket Number Field */}

            <View
              style={[
                {
                  display: "flex",
                  width: "27%",
                  flexDirection: "row",
                  alignItems: "center",
                },
              ]}
            >
              <Text
                style={[
                  {
                    textAlign: "left",
                    width: "38px",
                    marginRight: "5px",
                  },
                  styles.bold,
                  styles.bodyText,
                ]}
              >
                Boleto
              </Text>
              <Text style={[styles.boxedText]}>
                {values.ticket_number ? values.ticket_number : " "}
              </Text>
            </View>

            {/* SUnnova /  PS ID Field */}
            <View
              style={[
                {
                  display: "flex",
                  width: "26%",
                  flexDirection: "row",
                  alignItems: "center",
                },
              ]}
            >
              <Text
                style={[
                  {
                    textAlign: "left",
                    width: "128px",
                  },
                  styles.bold,
                  styles.bodyText,
                ]}
              >
                sunnova/PS ID
              </Text>
              <Text style={[styles.boxedText]}>
                {values.sunnova_id ? values.sunnova_id : " "}
              </Text>
            </View>
          </View>

          {/* direccion y latitud*/}
          <View
            style={[
              {
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                // marginBottom: "8px",
              },
              styles.marginSpacer,
            ]}
          >
            {/* Direccion Field */}
            <View
              style={[
                {
                  display: "flex",
                  width: "61%",
                  flexDirection: "row",
                  alignItems: "center",
                },
              ]}
            >
              <Text
                style={[
                  {
                    textAlign: "left",
                    width: "80px",
                    marginRight: "4px",
                  },
                  styles.bold,
                  styles.bodyText,
                ]}
              >
                Dirección
              </Text>
              <Text style={[styles.boxedText]}>{values.address_line}</Text>
            </View>

            {/* Latitud Field */}

            <View
              style={[
                {
                  display: "flex",
                  width: "35%",
                  flexDirection: "row",
                  alignItems: "center",
                },
              ]}
            >
              <Text
                style={[
                  {
                    // paddingRight: "4px",
                    textAlign: "left",
                    width: "60px",
                    marginRight: "4px",
                  },
                  styles.bold,
                  styles.bodyText,
                ]}
              >
                Latitud
              </Text>
              <Text style={[styles.boxedText]}>{values.latitude}</Text>
            </View>
          </View>
          {/* municipio y longitud*/}
          <View
            style={[
              {
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                // marginBottom: "8px",
              },
              styles.marginSpacer,
            ]}
          >
            {/* Municipio Field */}
            <View
              style={[
                {
                  display: "flex",
                  width: "61%",
                  flexDirection: "row",
                  alignItems: "center",
                },
              ]}
            >
              <Text
                style={[
                  {
                    textAlign: "left",
                    width: "80px",
                    marginRight: "4px",
                  },
                  styles.bold,
                  styles.bodyText,
                ]}
              >
                Municipio
              </Text>
              <Text style={[styles.boxedText]}>{values.municipality}</Text>
            </View>

            {/* Longitud Field */}

            <View
              style={[
                {
                  display: "flex",
                  width: "35%",
                  flexDirection: "row",
                  alignItems: "center",
                },
              ]}
            >
              <Text
                style={[
                  {
                    paddingRight: "4px",
                    textAlign: "left",
                    width: "60px",
                    marginRight: "4px",
                  },
                  styles.bold,
                  styles.bodyText,
                ]}
              >
                Longitud
              </Text>
              <Text style={[styles.boxedText]}>-{values.longitude}</Text>
            </View>
          </View>

          {/* Consumo Energético Doméstico*/}
          <View
            style={[
              {
                width: "100%",
                borderBottom: "1px solid #E9682A",
              },
              styles.marginSpacer,
            ]}
          >
            <View
              style={[
                {
                  width: "50%",
                  padding: "2px 12px",
                  textAlign: "left",
                  color: "#fff",
                  borderBottom: "1px solid #E9682A",
                  backgroundColor: "#E9682A",
                  //   borderRadius: "5px 5px 0 0",
                },
                styles.bold,
                styles.upperCase,
              ]}
            >
              <Text>Consumo Energético Doméstico</Text>
            </View>
          </View>

          <View
            style={[
              {
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              },
              styles.marginSpacer,
            ]}
          >
            {/* Consumo de Energía Anual kWh Field */}
            <View
              style={[
                {
                  display: "flex",
                  width: "48%",
                  flexDirection: "row",
                  alignItems: "center",
                  textAlign: "center",
                },
              ]}
            >
              <Text
                style={[
                  {
                    paddingRight: "4px",
                    textAlign: "left",
                    width: "380px",
                  },
                  styles.bold,
                  styles.bodyText,
                ]}
              >
                Consumo de Energía Anual kWh
              </Text>
              <Text style={[{}, styles.boxedText]}>
                {values.annual_client_consumption}
              </Text>
            </View>

            {/* Producción Anual Simulada por PVWatts kWh */}
            <View
              style={[
                {
                  display: "flex",
                  width: "48%",
                  flexDirection: "row",
                  alignItems: "center",
                  textAlign: "center",
                },
              ]}
            >
              <Text
                style={[
                  {
                    paddingRight: "4px",
                    textAlign: "left",
                    width: "350px",
                  },
                  styles.bold,
                  styles.bodyText,
                ]}
              >
                Producción Anual Simulada por PVWatts kWh
              </Text>
              <Text style={[styles.boxedText]}>
                {values.annual_client_consumption_capacity
                  ? values.annual_client_consumption_capacity
                  : " "}
              </Text>
            </View>
          </View>

          {values.annual_client_consumption &&
            values.annual_client_consumption_capacity && (
              <View
                style={[
                  {
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    width: "100%",
                    alignItems: "center",
                  },
                  styles.marginSpacer,
                ]}
              >
                <Text style={[{ textAlign: "center" }, styles.bold]}>
                  Producción Anual Simulada por PVWatts / Consumo de Energía Anual kWh
                  Anual kW =
                </Text>
                <Text
                  style={{
                    fontSize: "14px",
                    color: "#E9682A",
                    textAlign: "center",
                  }}
                >
                  {" " +
                    Math.round(
                      (parseInt(values.annual_client_consumption_capacity.replace(/,/g, ''), 10) /
                        parseInt(values.annual_client_consumption.replace(/,/g, ''), 10)) *
                        1000
                    ) /
                      10 +
                    "%"}
                </Text>
              </View>
            )}

          {/* Propuesta del sistema */}
          <View
            style={[
              {
                width: "100%",
                borderBottom: "1px solid #E9682A",
              },
              styles.marginSpacer,
            ]}
          >
            <View
              style={[
                {
                  width: "50%",
                  padding: "2px 12px",
                  //   textAlign: "left",
                  color: "#fff",
                  borderBottom: "1px solid #E9682A",
                  backgroundColor: "#E9682A",
                  //   borderRadius: "5px",
                },
                styles.bold,
                styles.upperCase,
              ]}
            >
              <Text>Propuesta del sistema</Text>
            </View>
          </View>
          <View
            style={[
              {
                width: "100%",
                padding: "0px 24px",
              },
              styles.marginSpacer,
            ]}
          >
            <View
              style={[
                {
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                },
                styles.marginSpacer,
              ]}
            >
              <View
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  width: "50%",
                }}
              >
                <Text style={[{ textAlign: "center" }, styles.bold]}>
                  Empresa Instaladora:
                </Text>
                <Text
                  style={{
                    fontSize: "14px",
                    color: "#E9682A",
                    textAlign: "center",
                  }}
                >
                  Power Solar, LLC
                </Text>
              </View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  width: "50%",
                }}
              >
                <Text style={[{ textAlign: "center" }, styles.bold]}>
                  Tipo de Sistema:
                </Text>
                <Text
                  style={{
                    fontSize: "14px",
                    color: "#E9682A",
                    textAlign: "center",
                  }}
                >
                  {values["solarPanels"] && values["solarPanels"].length > 0
                    ? "PVS + BSS"
                    : "BSS"}
                </Text>
              </View>
            </View>

            {/* Table */}
            <View style={[{}, styles.table, styles.marginSpacer]}>
              <View style={[styles.tableRow]}>
                <Text
                  style={[
                    styles.cell,
                    styles.headerCell,
                    styles.upperCase,
                    styles.bold,
                  ]}
                >
                  Producto
                </Text>
                <Text
                  style={[
                    styles.cell,
                    styles.headerCell,
                    styles.upperCase,
                    styles.bold,
                  ]}
                >
                  Modelo
                </Text>
                <Text
                  style={[
                    styles.cell,
                    styles.headerCell,
                    styles.upperCase,
                    styles.bold,
                  ]}
                >
                  Cantidad
                </Text>
              </View>
              {generateProducts()}
            </View>

            <View
              style={[
                {
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  padding: "10px 0px",
                  width: "100%",
                },
                styles.marginSpacer,
              ]}
            >
              {/* Capacidad del sistema fotovoltaico Field */}
              <View
                style={[
                  {
                    display: "flex",
                    width: "49%",
                    flexDirection: "column",
                    alignItems: "center",
                    textAlign: "center",
                  },
                ]}
              >
                <Text
                  style={[
                    {
                      textAlign: "center",
                      width: "100%",
                    },
                    styles.bold,
                    styles.bodyText,
                  ]}
                >
                  Capacidad del sistema
                </Text>
                <Text
                  style={[
                    {
                      textAlign: "center",
                      width: "100%",
                    },
                    styles.bold,
                    styles.bodyText,
                  ]}
                >
                  fotovoltáico kW
                </Text>
                <Text style={[styles.boxedText]}>
                  {generateTotalProduction()}
                </Text>
              </View>

              {/* Capacidad Almacenamiento de Batería kWh Field */}
              <View
                style={[
                  {
                    display: "flex",
                    width: "49%",
                    flexDirection: "column",
                    alignItems: "center",
                    textAlign: "center",
                  },
                ]}
              >
                <Text
                  style={[
                    {
                      textAlign: "center",
                      width: "100%",
                    },
                    styles.bold,
                    styles.bodyText,
                  ]}
                >
                  Capacidad Almacenamiento
                </Text>
                <Text
                  style={[
                    {
                      textAlign: "center",
                      width: "100%",
                    },
                    styles.bold,
                    styles.bodyText,
                  ]}
                >
                  de Batería kWh
                </Text>
                <Text style={[styles.boxedText]}>13.5</Text>
              </View>
            </View>

            <Text style={[{}, styles.bodyText]}>
              Mediante este acuerdo, el cliente tiene la opción de proceder con
              la renta de su sistema solar u optar por la compra del mismo,
              según le sea más conveniente. Se apercibe al cliente, que en el
              caso de que obtenga el incentivo del Departamento de la Vivienda
              financiado por fondos CDBG MIT para la adquisición de sistemas
              solares, este incentivo es solo de aplicación para la compra de un
              sistema y no puede ser aplicado a un acuerdo de renta. Por tanto,
              en caso de que el cliente reciba algún incentivo de parte del
              Gobierno que sea de aplicación exclusiva a la adquisición del
              sistema, el cliente tendrá la opción de convertir este acuerdo de
              renta por uno compra y financiamiento del sistema sin penalidad
              alguna
            </Text>
          </View>
        </View>

        {/* Signature Section */}
        <View
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          {/* Consultor y fecha */}
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              borderBottom: "1px solid #7b7b7b",
              paddingBottom: "5px",
              marginBottom: "12px",
            }}
          >
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                width: "50%",
                justifyContent: "center",
              }}
            >
              <Text style={styles.bold}> Consultor:</Text>
              <Text> {user.name}</Text>
            </View>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                width: "50%",
                justifyContent: "center",
              }}
            >
              <Text style={styles.bold}> Fecha: </Text>
              <Text> {generateDate()}</Text>
            </View>
          </View>

          {/* Firmas */}

          <View
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <View
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                width: "49%",
              }}
            >
              <Text
                style={{
                  fontSize: "24px",
                  color: "#2C4596",
                  textAlign: "center",
                  //   marginBottom: "4px",
                  fontFamily: "Zeyada",
                }}
              >
                {user.name}
              </Text>
              <Text style={[{ textAlign: "center" }, styles.bold]}>
                Firma de Consultor
              </Text>
            </View>

            <View
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                width: "49%",
              }}
            >
              <Text
                style={{
                  fontSize: "24px",
                  color: "#2C4596",
                  textAlign: "center",
                  //   marginBottom: "4px",
                  fontFamily: "Zeyada",
                }}
              >
                {" "}
              </Text>
              <Text style={[{ textAlign: "center" }, styles.bold]}>
                Firma de Propietario
              </Text>
            </View>
          </View>
        </View>
      </View>
    </Page>
  );
}
