import React, { useEffect } from "react";
import { BasicFormWrapper } from "../../../../containers/styled";
import Heading from "../../../heading/heading";
import { Row, Col, Form, Input } from "antd";

function CustomerForm(props) {
  const { form } = props;

  useEffect(() => {
    form.setFieldsValue({
      ...form.getFieldsValue(true),
    });
  }, [form]);

  return (
    <BasicFormWrapper className="basic-form-inner">
      <div className="atbd-form-checkout">
        <Row justify="center">
          <Col sm={22} xs={24}>
            <div className="create-account-form">
              <Heading as="h4">1. Información del Cliente</Heading>
              <Form form={form} name="account">
                <Form.Item
                  name="customer_name"
                  label="Nombre de Cliente"
                  rules={[
                    {
                      required: true,
                      message: "Nombre de cliente es requerido",
                    },
                  ]}
                >
                  <Input placeholder="Justin Rivera" />
                </Form.Item>
                <Form.Item
                  name="address_line"
                  label="Dirección Según Factura"
                  // eslint-disable-next-line react/jsx-no-duplicate-props
                  rules={[
                    { required: true, message: "Dirección es requerido" },
                  ]}
                >
                  <Input placeholder="" />
                </Form.Item>
                <Form.Item
                  name="municipality"
                  label="Municipio"
                  // eslint-disable-next-line react/jsx-no-duplicate-props
                  rules={[
                    { required: true, message: "Municipio es requerido" },
                  ]}
                >
                  <Input placeholder="San Juan" />
                </Form.Item>
                <Form.Item
                  name="latitude"
                  label="Latitud"
                  // eslint-disable-next-line react/jsx-no-duplicate-props
                  rules={[
                    { required: true, message: "Latitud es requerido" },
                    {
                      pattern: new RegExp(/^[0-9]{1,3}\.[0-9]{6}$/),
                      message:
                        "La coordenada debe incluir seis números decimales y solo puede incluir números.",
                    },
                  ]}
                >
                  <Input placeholder="18.466333" />
                </Form.Item>
                <Form.Item
                  name="longitude"
                  label="Longitud"
                  // eslint-disable-next-line react/jsx-no-duplicate-props
                  rules={[
                    { required: true, message: "Longitud es requerido" },
                    {
                      pattern: new RegExp(/^[0-9]{1,3}\.[0-9]{6}$/),
                      message:
                        "La coordenada debe incluir seis números decimales y solo puede incluir números.",
                    },
                  ]}
                >
                  <Input placeholder="66.105721" prefix="-" />
                </Form.Item>
              </Form>
            </div>
          </Col>
        </Row>
      </div>
    </BasicFormWrapper>
  );
}

export default CustomerForm;
