import React, { useState } from "react";
import ProductCard from "../../components/ProductCard/ProductCard";
import { Row, Col, Pagination } from "antd";

function ProductsList(props) {
  const { products, pageSize, setOpen, setOpenEnableModal, setCurrentProduct } =
    props;

  const [page, setPage] = useState(1);

  const handleOpenModal = (product) => {
    setCurrentProduct({ ...product });
    setOpen(true);
  };

  const handleOpenEnableModal = (product) => {
    setCurrentProduct({ ...product });
    setOpenEnableModal(true);
  };

  const handleChange = (pageNumber) => {
    setPage(pageNumber);
  };

  const generateProducts = () => {
    return products
      .sort((a, b) => b.id - a.id)
      .slice((page - 1) * pageSize, page * pageSize)
      .map((q) => (
        <Col key={q.id} md={8} sm={12} xs={24}>
          <ProductCard
            {...q}
            setOpenModal={() => handleOpenModal(q)}
            setOpenEnableModal={handleOpenEnableModal}
          />
        </Col>
      ));
  };

  return (
    <div style={{ marginTop: "15px" }}>
      <Row gutter={25} justify="center">
        <Col xs={24}>
          <p className="text-center">
            Showing {page * pageSize - 47} -{" "}
            {products.length > page * pageSize
              ? page * pageSize
              : products.length}{" "}
            of {products.length}
          </p>
        </Col>
        {generateProducts()}
        <Col xs={24}>
          <div className="text-center">
            <Pagination
              onChange={handleChange}
              total={products.length}
              pageSize={pageSize}
              showSizeChanger={false}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
}

ProductsList.defaultProps = {
  pageSize: 48,
};

export default ProductsList;
