import {
    LOADING_PRODUCTS,
    CLEAR_LOADING_PRODUCTS,
    FETCH_PRODUCTS,
    CREATE_PRODUCT,
    UPDATE_PRODUCT,
} from '../constants/productConstants';

const initialState = {
    products: [],
    loading: false,
};

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case CLEAR_LOADING_PRODUCTS:
            return {
                ...state,
                loading: false,
            }
        case LOADING_PRODUCTS:
            return {
                ...state,
                loading: true,
            }
        case FETCH_PRODUCTS:
            return {
                ...state,
                loading: false,
                products: action.payload
            }
        case CREATE_PRODUCT:
            return {
                ...state,
                loading: false,
                products: [...state.products, action.payload]
            }
        case UPDATE_PRODUCT:
            return {
                ...state,
                loading: false,
                products: [
                    ...state.products.filter(p => p.id !== action.payload.id),
                    action.payload
                ]
            }
        default:
            return state;
    }
}
