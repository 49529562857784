import React, { useState, useEffect } from "react";
import { BasicFormWrapper } from "../../../../containers/styled";
import Heading from "../../../heading/heading";
import { Row, Col, Form, Select, Skeleton, Radio, Input } from "antd";
import { connect } from "react-redux";
import FormObjectRemovalFieldList from "../../../inputs/FormObjectRemovalFieldList";
// import { calculateRemoveSealingCost } from "../../../helpers/helper";
// import { formatter } from "../../../../helpers/helper";

const { Option } = Select;
function SelectRoofObjectForm(props) {
  const { form, roofObjects, loading } = props;

  // const [showCostEstimate, setShowCostEstimate] = useState(false);
  const [showObjectInputs, setShowObjectInputs] = useState(false);
  const [
    showExistingRoofSealingSquareFoot,
    setShowExistingRoofSealingSquareFoot,
  ] = useState(false);

  const [formValues, setFormValues] = useState({});

  const handleSetValues = () => {
    let formValues = form.getFieldsValue();

    //   if (formValues["remove_roof_sealing"]) {
    //     setShowCostEstimate(true);
    //   } else {
    //     setShowCostEstimate(false);
    //   }

    if (formValues["remove_roof_sealing"]) {
      setShowExistingRoofSealingSquareFoot(true);
    } else {
      setShowExistingRoofSealingSquareFoot(false);
    }

    if (formValues["remove_roof_object"]) {
      setShowObjectInputs(true);
    } else {
      setShowObjectInputs(false);
    }
  };

  const handleChange = (changed, allFields) => {
    handleSetValues();
  };

  const generateRoofObjectOptions = () => {
    let filteredRoofObjects = roofObjects.filter((ro) => ro.is_enabled);
    if (filteredRoofObjects.length > 0) {
      return filteredRoofObjects.map((ro) => (
        <Option key={ro.id} value={ro.id}>
          {ro.name}
        </Option>
      ));
    }
  };

  useEffect(() => {
    let fieldValues = { ...form.getFieldsValue(true) };

    setFormValues({ ...formValues, ...fieldValues });

    // Set Initial values
    handleSetValues();
    // eslint-disable-next-line
  }, [form]);

  return (
    <BasicFormWrapper className="basic-form-inner">
      {loading && (
        <Col sm={24} xs={24}>
          <Skeleton paragraph={{ rows: 20 }} active />
        </Col>
      )}
      {!loading && (
        <div className="atbd-form-checkout">
          <Row justify="center">
            <Col sm={22} xs={24}>
              <div className="payment-method-form">
                <Heading as="h4">3. Remoción o Corrección de Objetos</Heading>
                <div className="shipping-selection">
                  <Form
                    form={form}
                    name="roofObjects"
                    onFieldsChange={handleChange}
                  >
                    <Form.Item
                      name="remove_roof_sealing"
                      initialValue=""
                      label="¿Se debe remover SELLADO existente?"
                      rules={[
                        {
                          required: true,
                          message: "Es requerido",
                        },
                      ]}
                    >
                      <Radio.Group style={{ width: "100%", display: "flex" }}>
                        <Radio value={true} style={{ width: "100%" }}>
                          <p>Si</p>
                        </Radio>
                        <Radio value={false} style={{ width: "100%" }}>
                          <p>No</p>
                        </Radio>
                      </Radio.Group>
                    </Form.Item>
                    {showExistingRoofSealingSquareFoot && (
                      <Form.Item
                        name="existing_roof_sealing_square_foot"
                        label="Medida del sellado existente a remover (pies cuadrados)"
                        rules={[
                          {
                            required: true,
                            message: "Pies cuadrados es requerido",
                          },
                          {
                            pattern: /^[0-9]+$/,
                            message:
                              "Ingrese solo caracteres numéricos para pies cuadrados",
                          },
                        ]}
                      >
                        <Input placeholder={formValues.square_foot} />
                      </Form.Item>
                    )}
                    {/* {showCostEstimate && (
                      <Form.Item name="cost_estimate" label="Costo estimado:">
                        {formValues["square_foot"]}' x{" "}
                        {formatter.format(formValues["membrane"])} + IVU ={" "}
                        {formatter.format(
                          calculateRemoveSealingCost(
                            formValues["square_foot"],
                            formValues["membrane"]
                          )
                        )}
                      </Form.Item>
                    )} */}
                    <Form.Item
                      name="remove_roof_object"
                      initialValue=""
                      label="¿Se debe remover o corregir OBJETOS existentes?"
                      rules={[
                        {
                          required: true,
                          message: "Es requerido",
                        },
                      ]}
                    >
                      <Radio.Group style={{ width: "100%", display: "flex" }}>
                        <Radio value={true} style={{ width: "100%" }}>
                          <p>Si</p>
                        </Radio>
                        <Radio value={false} style={{ width: "100%" }}>
                          <p>No</p>
                        </Radio>
                      </Radio.Group>
                    </Form.Item>

                    {showObjectInputs && (
                      <>
                        <p
                          style={{
                            fontSize: "15px",
                          }}
                        >
                          Indique los objetos y la cantidad para remover o
                          corregir de cada uno:
                        </p>

                        <FormObjectRemovalFieldList
                          name="roof_objects"
                          objects={generateRoofObjectOptions()}
                        />
                        {/* <Form.Item
                          name="roof_quote_object_ids"
                          label="¿Qué objetos?"
                        >
                          <Select
                            mode="multiple"
                            allowClear
                            style={{
                              minWidth: "300px",
                            }}
                            placeholder="Selecciona todos los que apliquen"
                            onChange={handleSelectChange}
                            options={roofObjects
                              .filter((o) => o.is_enabled)
                              .map((o) => {
                                return {
                                  label: o.name,
                                  value: o.name,
                                };
                              })}
                          />
                        </Form.Item>
                        <Form.Item
                          name="removal_cost"
                          label="Costo de remoción de objetos"
                          rules={[
                            {
                              required: true,
                              message:
                                "Costo de remoción de objetos es requerido",
                            },
                          ]}
                        >
                          <Input placeholder="200" />
                        </Form.Item> */}
                      </>
                    )}
                  </Form>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      )}
    </BasicFormWrapper>
  );
}

let mapStateToProps = (state) => ({
  roofObjects: state.roofObjects.roofObjects,
  loading: state.roofObjects.loading,
});

export default connect(mapStateToProps, null)(SelectRoofObjectForm);
