import {
    LOADING_WARRANTIES,
    CLEAR_LOADING_WARRANTIES,
    FETCH_WARRANTIES,
} from '../constants/warrantyConstants';

const initialState = {
    warranties: [],
    loading: false,
};

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case CLEAR_LOADING_WARRANTIES:
            return {
                ...state,
                loading: false,
            }
        case LOADING_WARRANTIES:
            return {
                ...state,
                loading: true,
            }
        case FETCH_WARRANTIES:
            return {
                ...state,
                loading: false,
                warranties: action.payload
            }
        default:
            return state;
    }
}
