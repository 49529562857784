import React from "react";
import { Page, Text, View, StyleSheet, Image } from "@react-pdf/renderer";
import { formatter } from "../../helpers/helper";
import { getProductPrice } from "../helpers/helper";
import { hasSolarPanels } from "../helpers/helper";
import text from "./lang";

const styles = StyleSheet.create({
  page: {
    // flexDirection: "row",
    backgroundColor: "#FFF",
    boxSizing: "border-box",
    fontSize: 14,
    padding: 0,
  },
  // section: {
  //   margin: 10,
  //   padding: 10,
  //   flexGrow: 1,
  // },
  header: {
    width: "100%",
    display: "flex",
    alignSelf: "center",
    backgroundColor: "#1D4091",
    color: "#fff",
    padding: "16 16 8 16",
    marginBottom: 16,
    borderBottom: "solid 3px #0E9CD5",
  },
  headerText: {
    fontSize: 14,
    color: "#1f497d",
    margin: "0 10",
  },
  bodyText: {
    fontSize: "12px",
    color: "#343a40",
    margin: "0",
    lineHeight: "2px",
  },
  secondaryHeader: {
    fontSize: 16,
    margin: "8 0",
  },
  flex: {
    display: "flex",
  },
  bold: {
    fontFamily: "Helvetica-Bold",
  },
  justifiedRight: {
    textAlign: "right",
  },
  orangeText: {
    color: "#E9682A",
  },
  grayText: {
    color: "#343a40",
  },

  blueText: {
    color: "#2C4596",
  },

  table: {
    width: "100%",
  },
  tableRow: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-around",
  },
  cell: {
    paddingTop: "5",
    paddingBottom: "3",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    border: "1 solid #ccc",
    width: "33.333333%",
    height: "100%",
    fontSize: "9",
  },
  headerCell: {
    backgroundColor: "#2C4596",
    color: "#FFFFFF",
  },
  totalPrice: {
    marginTop: "8px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    width: "100%",
  },
  upperCase: {
    textTransform: "uppercase",
  },
  lineText: {
    display: "flex",
    flexDirection: "row",
  },
});

function QuotePage(props) {
  const { lang, quote } = props;

  const generateProducts = () => {
    let productRows = [];

    productRows = [
      ...quote.quote_products.map((qp) => {
        return (
          <View style={[styles.tableRow]} key={qp.id}>
            <Text style={[styles.cell]}>{qp.quantity}</Text>
            <Text style={[styles.cell]}>{qp.product.name}</Text>
            <Text style={[styles.cell]}>
              {lang === "en" && qp.product.english_description
                ? qp.product.english_description
                : qp.product.description}
            </Text>
            <Text style={[styles.cell]}>
              {getProductPrice(qp.product, qp.quantity, quote.quote_products) >
              0
                ? formatter.format(
                    (getProductPrice(
                      qp.product,
                      qp.quantity,
                      quote.quote_products
                    ) *
                      parseInt(qp.quantity, 10)) /
                      100
                  )
                : text[lang].includedPrice}
            </Text>
          </View>
        );
      }),
    ];

    if (hasSolarPanels(quote)) {
      productRows.push(
        <View style={[styles.tableRow]}>
          <Text style={[styles.cell]}></Text>
          <Text style={[styles.cell]}>Unirac</Text>
          <Text style={[styles.cell]}>{text[lang].uniracDescription} </Text>
          <Text style={[styles.cell]}>{text[lang].includedPrice}</Text>
        </View>
      );
    }

    // Push additional rows that are always included
    productRows.push(
      <View style={[styles.tableRow]}>
        <Text style={[styles.cell]}></Text>
        <Text style={[styles.cell]}>Componentes Eléctricos</Text>
        <Text style={[styles.cell]}>
          Todo equipo y componentes eléctricos requeridos estan considerados
        </Text>
        <Text style={[styles.cell]}>{text[lang].includedPrice}</Text>
      </View>
    );

    productRows.push(
      <View style={[styles.tableRow]}>
        <Text style={[styles.cell]}></Text>
        <Text style={[styles.cell]}>Monitoreo Remoto</Text>
        <Text style={[styles.cell]}>
          Acceso Web y APP para monitoreo de Sistema propuesto
        </Text>
        <Text style={[styles.cell]}>{text[lang].includedPrice}</Text>
      </View>
    );

    productRows.push(
      <View style={[styles.tableRow]}>
        <Text style={[styles.cell]}></Text>
        <Text style={[styles.cell]}>Permisología</Text>
        <Text style={[styles.cell]}>
          Toda permisología relacionada a PREPA, esta incluída en nuestra
          propuesta. Cualquier permiso adicional que se requiera se considerará
          antes de proveer propuesta.
        </Text>
        <Text style={[styles.cell]}>{text[lang].includedPrice}</Text>
      </View>
    );

    productRows.push(
      <View style={[styles.tableRow]}>
        <Text style={[styles.cell]}></Text>
        <Text style={[styles.cell]}>Operación y Mantenimiento</Text>
        <Text style={[styles.cell]}>
          Operación y/o mantenimiento de ser necesario se coordinará con
          encargado de localidad para inspección y/o mantenimiento
        </Text>
        <Text style={[styles.cell]}>{text[lang].includedPrice}</Text>
      </View>
    );

    if (quote.allowance && quote.allowance > 0) {
      productRows.push(
        <View style={[styles.tableRow]}>
          <Text style={[styles.cell]}></Text>
          <Text style={[styles.cell]}>Allowance</Text>
          <Text style={[styles.cell]}></Text>
          <Text style={[styles.cell]}>
            {formatter.format(quote.allowance / 100)}
          </Text>
        </View>
      );
    }

    return productRows;
  };


  return (
    <Page size="A4" style={styles.page}>
      {/* Page Container */}
      <View
        style={{
          width: "100%",
          display: "flex",
          // border: "1px solid #ccc",
          boxSizing: "border-box",
          justifyContent: "space-between",
          height: "100%",
        }}
      >
        {/* Header */}
        <View
          style={[
            {
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
              flexDirection: "row",
              alignItems: "center",
              backgroundColor: "#1D4091",
              color: "#fff",
              padding: "20 16 20 16",
              borderBottom: "4px solid #0E9CD5",
              marginBottom: 0,
              fontSize: 14,
              height: "72px",
            },
            styles.upperCase,
          ]}
        >
          {/* Page title */}
          {/* <Text>Cotización Comercial</Text> */}
          <Image
            style={{
              height: "28px",
            }}
            src={`/assets/commercial quotes images/powersolar-logo-white.png`}
          />
        </View>

        {/* Page Content */}
        <View
          style={{
            padding: 48,
            display: "flex",
            flexDirection: "column",
            height: "100%",
            justifyContent: "space-between",
          }}
        >
          <View>
            {/* Page Title */}
            <View
              style={[
                {
                  fontSize: "24px",
                  marginBottom: 24,
                  textAlign: "left",
                },
                styles.bold,
                styles.upperCase,
                styles.grayText,
              ]}
            >
              <Text>EQUIPO PROPUESTO Y/O SERVICIOS</Text>
              <Text>INCLUIDOS</Text>
            </View>

            {/* Table */}
            <View style={[styles.table]}>
              <View style={[styles.tableRow]}>
                <Text
                  style={[
                    styles.cell,
                    styles.headerCell,
                    styles.upperCase,
                    styles.bold,
                  ]}
                >
                  {text[lang].productQuantityHeader}
                  Cantidad
                </Text>
                <Text
                  style={[
                    styles.cell,
                    styles.headerCell,
                    styles.upperCase,
                    styles.bold,
                  ]}
                >
                  {text[lang].productProductHeader}
                  Componente
                </Text>
                <Text
                  style={[
                    styles.cell,
                    styles.headerCell,
                    styles.upperCase,
                    styles.bold,
                  ]}
                >
                  {text[lang].productDescriptionHeader}
                  Descripción
                </Text>
                <Text
                  style={[
                    styles.cell,
                    styles.headerCell,
                    styles.upperCase,
                    styles.bold,
                  ]}
                >
                  {text[lang].productPriceHeader}
                  Costo
                </Text>
              </View>
              {generateProducts()}
            </View>
          </View>
          {/* Side Note */}
          <View>
            <Text
              style={[
                {
                  fontSize: "12px",
                  paddingBottom: "8px",
                },
                styles.orangeText,
                styles.bold,
              ]}
            >
              Nota Aclaratoria
            </Text>
            <Text
              style={[
                {
                  fontSize: "12px",
                },
              ]}
            >
              Power Solar LLC asistirá en el proceso de interconexión con PREPA
              en acuerdo con las regulaciones de PREPA y el Gobierno de Puerto
              Rico para el cumplimiento de Act 114/211. No obstante Power Solar
              LLC, no puede garantizar un tiempo determinado para dicha gestion
              ya que puede estar sujeto a factores adicionales de cumplimiento
              del Proyecto propuesto.
            </Text>
          </View>
        </View>
      </View>
    </Page>
  );
}

export default QuotePage;
