import React from "react";
import { Page, Text, View, StyleSheet, Image, Font } from "@react-pdf/renderer";
import { formatter } from "../../helpers/helper";
import moment from "moment";
import "moment/locale/es";
import PoppinsRegular from "../../assets/fonts/Poppins-Regular.ttf";
import PoppinsBold from "../../assets/fonts/Poppins-Bold.ttf";
import PoppinsItalic from "../../assets/fonts/Poppins-Italic.ttf";
import ZeyadaRegular from "../../assets/fonts/Zeyada-Regular.ttf";
import {
  calculateSquareFootCost,
  calculateRemoveSealingCost,
} from "../helpers/helper";

Font.register({
  family: "Poppins",
  format: "truetype",
  src: PoppinsRegular,
});

Font.register({
  family: "PoppinsBold",
  format: "truetype",
  src: PoppinsBold,
});

Font.register({
  family: "PoppinsItalic",
  format: "truetype",
  src: PoppinsItalic,
});

Font.register({
  family: "Zeyada",
  format: "truetype",
  src: ZeyadaRegular,
});

const styles = StyleSheet.create({
  page: {
    // flexDirection: "row",
    backgroundColor: "#FFF",
    boxSizing: "border-box",
    fontFamily: "Poppins",
  },
  // section: {
  //   margin: 10,
  //   padding: 10,
  //   flexGrow: 1,
  // },
  header: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
  },
  headerText: {
    fontSize: 12,
    color: "#1f497d",
    margin: "0 10",
  },
  bodyText: {
    fontSize: 10,
    color: "#343a40",
    // margin: "0 10",
  },
  secondaryHeader: {
    fontSize: 10,
    margin: "8 0",
  },
  flex: {
    display: "flex",
  },
  bold: {
    fontFamily: "PoppinsBold",
  },
  justifiedRight: {
    textAlign: "right",
  },
  orangeText: {
    color: "#E9682A",
  },
  grayText: {
    color: "#343a40",
  },
  whiteText: {
    color: "#ffffff",
  },

  orangeBackground: {
    backgroundColor: "#E9682A",
  },
  grayBackground: {
    backgroundColor: "#343a40",
  },
  lightGrayBackground: {
    backgroundColor: "#F2F2F2",
  },
  blueBackground: {
    backgroundColor: "#2C4596",
  },
  whiteSmallText: {
    color: "#ffffff",
    fontSize: "9px",
  },

  table: {
    width: "100%",
    borderRight: "1px solid #fff",
  },
  tableRow: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-around",
    alignItems: "center",
    backgroundColor: "#E6E6E6",
  },
  cell: {
    paddingTop: "10",
    paddingBottom: "10",
    paddingLeft: "10px",
    paddingRight: "10px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    borderLeft: "1px solid #E6E6E6",
    borderBottom: "1px solid #E6E6E6",
    width: "44%",
    fontSize: "10",
    backgroundColor: "#E6E6E6",
  },
  cell100: {
    paddingTop: "10",
    paddingBottom: "10",
    paddingLeft: "8",
    paddingRight: "8",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    borderLeft: "1px solid #E6E6E6",
    borderBottom: "1px solid #E6E6E6",
    width: "100%",

    fontSize: "10",
    backgroundColor: "#E6E6E6",
  },
  cell25: {
    paddingTop: "10",
    paddingBottom: "10",
    paddingLeft: "8",
    paddingRight: "8",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    borderLeft: "1px solid #E6E6E6",
    borderBottom: "1px solid #E6E6E6",
    width: "25%",
    fontSize: "10",
    backgroundColor: "#E6E6E6",
  },
  headerCell: {
    backgroundColor: "#2C4596",
    color: "#FFFFFF",
    border: "1px solid #2C4596",
  },
  totalPrice: {
    marginTop: "8px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    width: "100%",
  },
  upperCase: {
    textTransform: "uppercase",
  },
  lineText: {
    display: "flex",
    flexDirection: "row",
  },

  boxedText: {
    padding: "4px 8px 2px 8px",
    borderRadius: "3px",
    border: "1px solid #dcdcdc",
    width: "100%",
  },

  marginSpacer: {
    marginTop: "6px",
    marginBottom: "6px",
  },
});

export default function CoverPage(props) {
  const { quote } = props;

  const generateDate = () => {
    let localLocale = moment();
    localLocale.locale("en");

    return localLocale.format(" MM[/]DD[/]YYYY");
  };

  const generateDiscount = () => {
    if (quote.discount && parseFloat(quote.discount) > 0) {
      return parseFloat(quote.discount / 100);
    }

    return 0;
  };

  const generateproductRows = () => {
    let productRows = [];

    // Add roof sealing cost
    productRows.push(
      <View style={[styles.tableRow]} key={1 + ""}>
        <Text style={[styles.cell]}>1 Membrana</Text>
        <Text style={[styles.cell100]}>
          {quote.square_foot} pies cuadrados x $5.50
        </Text>
        <Text style={[styles.cell25]}>
          {formatter.format(quote.square_foot * 5.5)}
        </Text>
      </View>
    );

    // Remove existing roof sealing
    if (quote.remove_roof_sealing) {
      productRows.push(
        <View style={[styles.tableRow]} key={1 + ""}>
          <Text style={[styles.cell]}>
            Remoción de Sellado <br />
            Existente
          </Text>
          <Text style={[styles.cell100]}>
            {quote.existing_roof_sealing_square_foot} pies cuadrados x $1.00
          </Text>
          <Text style={[styles.cell25]}>
            {formatter.format(quote.existing_roof_sealing_square_foot * 1)}
          </Text>
        </View>
      );
    }

    if (quote.roof_quote_objects && quote.roof_quote_objects.length > 0) {
      let objectPrice = quote.roof_quote_objects.reduce(
        (accumulator, item) =>
          accumulator +
          parseFloat(item.quantity) * (item.roof_object.cost / 100),
        0
      );

      productRows.push(
        <View style={[styles.tableRow]} key={1 + ""}>
          <Text style={[styles.cell]}>
            Remoción o Corrección
            <br /> de Objetos Existentes
          </Text>
          <View style={[styles.cell100, { flexDirection: "column" }]}>
            {quote.roof_quote_objects.map((item, index) => {
              let padding = "4px";
              if (index === 0 && quote.roof_quote_objects.length === 0) {
                padding = "0px 4px";
              } else if (index === 0) {
                padding = "0px 4px 4px 4px";
              } else if (index + 1 === quote.roof_quote_objects.length) {
                padding = "4px 4px 0px 4px";
              }
              return (
                <Text
                  style={[
                    {
                      width: "100%",
                      padding: padding,
                      borderBottom:
                        index + 1 === quote.roof_quote_objects.length
                          ? "0px solid #D9D9D9"
                          : "1px solid #D9D9D9",
                    },
                  ]}
                >
                  {item.quantity} x {item.roof_object.name} (
                  {formatter.format(item.roof_object.cost / 100)} c/u)
                </Text>
              );
            })}
          </View>
          <Text style={[styles.cell25]}>{formatter.format(objectPrice)}</Text>
        </View>
      );
    }

    // Add parapet cost
    if (quote.has_parapet) {
      productRows.push(
        <View style={[styles.tableRow]} key={1 + ""}>
          <Text style={[styles.cell]}>Pretil</Text>
          <Text style={[styles.cell100]}>
            {quote.parapet_foot} pies lineales x $3.00
          </Text>
          <Text style={[styles.cell25]}>
            {formatter.format(quote.parapet_foot * 3)}
          </Text>
        </View>
      );
    }

    // Add puddle cost
    if (quote.has_puddle) {
      productRows.push(
        <View style={[styles.tableRow]} key={1 + ""}>
          <Text style={[styles.cell]}>Empozamiento</Text>
          <Text style={[styles.cell100]}>
            {quote.puddle_square_foot} pies cuadrados x $3.00
          </Text>
          <Text style={[styles.cell25]}>
            {formatter.format(quote.puddle_square_foot * 3)}
          </Text>
        </View>
      );
    }

    return productRows;
  };

  const calculateRoofQuoteCost = () => {
    let totalCost = 0;

    // Add square foot cost
    totalCost += parseFloat(calculateSquareFootCost(quote["square_foot"], 1));

    // Add parapet cost
    if (quote.has_parapet) {
      totalCost += quote.parapet_foot * 3;
    }

    // Add puddle cost
    if (quote.has_puddle) {
      totalCost += quote.puddle_square_foot * 3;
    }

    // Add roof sealing removal cost
    if (quote.remove_roof_sealing) {
      totalCost += parseFloat(
        calculateRemoveSealingCost(quote["existing_roof_sealing_square_foot"], 1)
      );
    }

    // Add roof objects removal cost
    if (quote.roof_quote_objects && quote.roof_quote_objects.length > 0) {
      totalCost += quote.roof_quote_objects.reduce((accumulator, item) => {
        return (
          accumulator +
          parseFloat(item.quantity) * (item.roof_object.cost / 100)
        );
      }, 0);
    }

    return totalCost;
  };

  return (
    <Page size="A4" style={styles.page}>
      {/* Page Container */}
      <View
        style={[
          {
            width: "100%",
            display: "flex",
            boxSizing: "border-box",
            justifyContent: "space-between",
            height: "100%",
            padding: "0px",
            color: "#343434",
          },
          styles.bodyText,
        ]}
      >
        {/* Header */}
        <View
          style={[
            styles.whiteText,
            {
              width: "100%",
              height: "170px",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              fontSize: "10px",
            },
          ]}
        >
          {/* Logo */}
          <View
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "40%",
              height: "100%",
              margin: "8px",
              padding: "28px",
            }}
          >
            <Image
              style={{
                width: "75%",
                objectFit: "contain",
              }}
              src={`/assets/commercial quotes images/powersolar-logo-black.png`}
            />
          </View>

          {/* Invoice info */}
          <View
            style={[
              {
                width: "30%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                padding: "20px",
                borderTopLeftRadius: "50px",
              },
              styles.blueBackground,
              styles.whiteText,
            ]}
          >
            <View
              style={[
                {
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                },
                styles.upperCase,
              ]}
            >
              <Text style={{ fontSize: "20px", lineHeight: 1 }}>
                Estimado de
              </Text>
              <Text style={[styles.bold, { fontSize: "26px", lineHeight: 1 }]}>
                Sellado de techo
              </Text>
            </View>
          </View>

          {/* Company Contact info */}
          <View
            style={[
              {
                width: "30%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                padding: "14px",
                fontSize: "10px",
                textAlign: "left",
              },
              styles.orangeBackground,
              styles.whiteText,
            ]}
          >
            <View
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
                marginBottom: "8px",
              }}
            >
              <Image
                style={{
                  height: "16px",
                  width: "16px",
                  marginRight: "4px",
                  display: "flex",
                  flexDirection: "row",
                }}
                src="/assets/roof quote images/phone-alt-icon-white-40.png"
              />
              <Text style={[styles.whiteSmallText]}>787.331.100</Text>
            </View>

            <View
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
                marginBottom: "8px",
              }}
            >
              <Image
                style={{
                  height: "16px",
                  width: "16px",
                  marginRight: "4px",
                  display: "flex",
                  flexDirection: "row",
                }}
                src="/assets/roof quote images/link-icon-white-40.png"
              />
              <Text style={[styles.whiteSmallText]}>powersolarpr.com</Text>
            </View>

            <View
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
                marginBottom: "8px",
              }}
            >
              <Image
                style={{
                  height: "16px",
                  width: "16px",
                  marginRight: "4px",
                  display: "flex",
                  flexDirection: "row",
                }}
                src="/assets/roof quote images/facebook-icon-white-40.png"
              />
              <Text style={[styles.whiteSmallText]}>PowerSolarPR</Text>
            </View>

            <View
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
                marginBottom: "8px",
              }}
            >
              <Image
                style={{
                  height: "16px",
                  width: "16px",
                  marginRight: "4px",
                  display: "flex",
                  flexDirection: "row",
                }}
                src="/assets/roof quote images/map-marker-alt-icon-white-40.png"
              />
              <View
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <Text
                  style={[
                    { width: "100%", textAlign: "left" },
                    styles.whiteSmallText,
                  ]}
                >
                  279 calle César L. Gónzalez
                </Text>
                <Text
                  style={[
                    { width: "100%", textAlign: "left" },
                    styles.whiteSmallText,
                  ]}
                >
                  San Juan, PR 00918
                </Text>
              </View>
            </View>
          </View>
        </View>

        {/* Page Content */}

        <View
          style={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            width: "100%",
            padding: "12px 32px",
          }}
        >
          {/* Bill To and Consultant and Date*/}

          <View
            style={[
              {
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                textAlign: "left",
              },
              styles.marginSpacer,
              styles.bodyText,
            ]}
          >
            {/* Bill To */}
            <View
              style={[
                {
                  display: "flex",
                  width: "60%",
                  flexDirection: "column",
                  alignItems: "center",
                },
              ]}
            >
              <Text
                style={[
                  {
                    width: "100%",
                    fontSize: "16px",
                  },
                  styles.bold,
                ]}
              >
                Cotización para
              </Text>
              {/* Name */}
              <Text style={[{ width: "100%" }]}>{quote.client_name}</Text>
              {/* Direction */}
              <Text style={[[{ width: "100%" }]]}>
                {quote.address_line1}, {quote.municipality}, PR
              </Text>
            </View>

            {/* Consultor y fecha  */}
            <View
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                // padding: "0px 0px 0px 20px",
                backgroundColor: "#FFF",
                width: "38%",
              }}
            >
              {/* Consultor  */}
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  marginBottom: "8px",
                }}
              >
                <Image
                  style={{
                    height: "28px",
                    width: "28px",
                    display: "flex",
                    flexDirection: "row",
                    marginRight: "8px",
                  }}
                  src="/assets/roof quote images/consultant-icon-blue-68.png"
                />
                <View
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Text style={[styles.bold]}>Consultor</Text>
                  <Text>{quote.user.name}</Text>
                </View>
              </View>
              {/* Estimate Date  */}
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <Image
                  style={{
                    height: "28px",
                    width: "28px",
                    display: "flex",
                    flexDirection: "row",
                    marginRight: "8px",
                  }}
                  src="/assets/roof quote images/calendar-icon-blue-68.png"
                />
                <View
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Text style={[styles.bold]}>Fecha</Text>
                  <Text>{generateDate()}</Text>
                </View>
              </View>
            </View>
          </View>

          {/* Detalles del Proyecto */}
          <View
            style={[
              {
                width: "100%",
                padding: "12px",
              },
              styles.lightGrayBackground,
              styles.marginSpacer,
            ]}
          >
            <Text style={[styles.bold]}>Detalles del Proyecto</Text>
            <View
              style={[
                {
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  padding: "4px",
                  alignItems: "center",
                },
                styles.lightGrayBackground,
              ]}
            >
              {/* Roof square feet */}
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "32%",
                }}
              >
                <Image
                  style={{
                    height: "28px",
                    width: "28px",
                    display: "flex",
                    flexDirection: "row",
                    marginRight: "8px",
                  }}
                  src="/assets/roof quote images/roof-icon-blue-68.png"
                />
                <View
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Text>Medida del Techo:</Text>
                  <Text style={[styles.bold, styles.orangeText]}>
                    {quote.square_foot} pies cuadrados
                  </Text>
                </View>
              </View>
              {/* Parapet feet */}
              {quote.has_parapet && (
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "32%",
                  }}
                >
                  <Image
                    style={{
                      height: "28px",
                      width: "28px",
                      display: "flex",
                      flexDirection: "row",
                      marginRight: "8px",
                    }}
                    src="/assets/roof quote images/parapet-icon-blue-68.png"
                  />
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Text>Medida del Pretil:</Text>
                    <Text style={[styles.bold, styles.orangeText]}>
                      {quote.parapet_foot} pies lineales
                    </Text>
                  </View>
                </View>
              )}

              {/* Medida Empozamiento */}
              {quote.has_puddle && (
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "32%",
                  }}
                >
                  <Image
                    style={{
                      height: "28px",
                      width: "28px",
                      display: "flex",
                      flexDirection: "row",
                      marginRight: "8px",
                    }}
                    src="/assets/roof quote images/puddle-icon-blue-68.png"
                  />
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Text>Empozamiento:</Text>
                    <Text style={[styles.bold, styles.orangeText]}>
                      {quote.puddle_square_foot} pies cuadrados
                    </Text>
                  </View>
                </View>
              )}
            </View>
          </View>
          {/* Tabla, costos, costo por pie */}
          <View
            style={[
              {
                width: "100%",
                display: "flex",
                flexDirection: "column",
              },
              styles.marginSpacer,
            ]}
          >
            {/* Tabla de Productos*/}
            <View
              style={[
                {
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                },
              ]}
            >
              {/* Table */}
              <View style={[{}, styles.table]}>
                <View style={[styles.tableRow]}>
                  <Text style={[styles.cell, styles.headerCell, styles.bold]}>
                    Producto
                  </Text>
                  <Text
                    style={[styles.cell100, styles.headerCell, styles.bold]}
                  >
                    Descripción
                  </Text>
                  <Text style={[styles.cell25, styles.headerCell, styles.bold]}>
                    Precio
                  </Text>
                </View>
                {generateproductRows()}
              </View>
            </View>

            {/* Comentarios, Total y Subtotal */}
            <View
              style={[
                {
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  padding: "12px",
                },
                styles.lightGrayBackground,
              ]}
            >
              {/* Comentarios */}
              <View
                style={[
                  {
                    width: "48%",
                    display: "flex",
                    justifyContent: "center",
                  },
                  styles.marginSpacer,
                ]}
              >
                {quote.comments && quote.comments.length > 0 && (
                  <>
                    <Text style={[styles.blueText, styles.bold]}>
                      Comentarios adicionales:
                    </Text>
                    <Text style={[styles.blueText]}>{quote.comments}</Text>
                  </>
                )}
              </View>

              {/* Total, IVU, Descuentos y Subtotal */}
              <View
                style={[
                  {
                    width: "48%",
                    display: "flex",
                    justifyContent: "center",
                  },
                ]}
              >

                {/* SUBTOTAL ROW */}
                <View
                  style={[
                    {
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  {/* Columna 1 */}
                  <View
                    style={[
                      {
                        width: "40%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignContent: "flex-end",
                        padding: "4px",
                        textAlign: "right",
                      },
                    ]}
                  >
                    <Text>SUBTOTAL:</Text>
                  </View>

                  {/* Columna 2 */}

                  <View
                    style={[
                      {
                        display: "flex",
                        justifyContent: "flex-end",
                        padding: "4px",
                        textAlign: "right",
                      },
                    ]}
                  >
                    <Text>{formatter.format(calculateRoofQuoteCost())}</Text>
                  </View>
                </View>
                {/* IVU ROW */}
                <View
                  style={[
                    {
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  {/* Columna 1 */}
                  <View
                    style={[
                      {
                        width: "40%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignContent: "flex-end",
                        padding: "4px",
                        textAlign: "right",
                      },
                    ]}
                  >
                    <Text>IVU:</Text>
                  </View>

                  {/* Columna 2 */}

                  <View
                    style={[
                      {
                        display: "flex",
                        justifyContent: "flex-end",
                        padding: "4px",
                        textAlign: "right",
                      },
                    ]}
                  >
                    <Text>{formatter.format(
                      (calculateRoofQuoteCost() * 0.115)
                    )}</Text>
                  </View>
                </View>
                {/* DISCOUNT ROW */}
                {generateDiscount() > 0 && (
                  <>
                    <View
                      style={[
                        {
                          width: "100%",
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        },
                      ]}
                    >
                      {/* Columna 1 */}
                      <View
                        style={[
                          {
                            width: "40%",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignContent: "flex-end",
                            padding: "4px",
                            textAlign: "right",
                          },
                        ]}
                      >
                        <Text>DESCUENTO:</Text>
                      </View>

                      {/* Columna 2 */}

                      <View
                        style={[
                          {
                            display: "flex",
                            justifyContent: "flex-end",
                            padding: "4px",
                            textAlign: "right",
                          },
                        ]}
                      >
                        <Text>-{formatter.format(generateDiscount())}</Text>
                      </View>
                    </View>
                  </>
                )}
                {/* TOTAL ROW */}
                <View
                  style={[
                    {
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  {/* Columna 1 */}
                  <View
                    style={[
                      {
                        width: "40%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignContent: "flex-end",
                        padding: "4px",
                        textAlign: "right",
                      },
                    ]}
                  >
                    <Text>TOTAL:</Text>
                  </View>

                  {/* Columna 2 */}

                  <View
                    style={[
                      {
                        display: "flex",
                        justifyContent: "flex-end",
                        padding: "4px",
                        textAlign: "right",
                        fontSize: "20px",
                      },
                      styles.orangeText,
                      styles.bold,
                    ]}
                  >
                    <Text>{formatter.format(
                      (calculateRoofQuoteCost() + (calculateRoofQuoteCost() *
                        0.115) - generateDiscount()
                      )
                    )}</Text>
                  </View>
                </View>
              </View>
            </View>

            {/* Costo por pie cuadrado */}
            <View
              style={[
                {
                  width: "100%",
                  padding: "8px",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  backgroundColor: "#E6E6E6",
                },
              ]}
            >
              <Text style={{ marginRight: "8px" }}>
                Costo por pie cuadrado:
              </Text>
              <Text style={[styles.bold, styles.orangeText]}>
                {" "}
                {formatter.format(
                  ((calculateRoofQuoteCost() *
                    1.115) - generateDiscount()) /
                  quote.square_foot
                )}
              </Text>
            </View>
          </View>
        </View>

        {/* Footer */}

        <View
          style={[
            {
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              padding: "16px",
            },
            styles.blueBackground,
            styles.whiteText,
          ]}
        >
          <View
            style={[
              {
                width: "100%",
                textAlign: "center",
                fontSize: "20px",
                marginBottom: "8px",
              },
            ]}
          >
            <Text>¡Gracias por su confianza!</Text>
          </View>
        </View>
      </View>
    </Page>
  );
}
