import React, { useEffect } from "react";
import { Modal } from "./antd-modals";
import ProductForm from "../forms/ProductForm/ProductForm";
import { connect } from "react-redux";
import { Form } from "antd";
import {
  createProductAction,
  updateProductAction,
} from "../../redux/actions/productActions";

function ProductFormModal(props) {
  const { product, open, setOpen, updateProduct, createProduct } = props;

  const [form] = Form.useForm();

  const handleSubmit = () => {
    let data = form.getFieldsValue(true);
    if (data && data.product_prices) {
      data.product_prices = data.product_prices.map((pp) => ({
           ...pp,
          price: Math.round(pp.price * 100),
      }));
    }
    if (data && data.price) {
      data.price = Math.round(data.price * 100);
    }

    if (product && product.id) {
      updateProduct(product.id, data).then((result) => {
        if (result && result.id) {
          setOpen(false);
        }
      });
    } else {
      createProduct(data).then((result) => {
        if (result && result.id) {
          setOpen(false);
        }
      });
    }
  };

  useEffect(() => {
    form.resetFields();
  }, [product, form]);

  return (
    <Modal
      title={product && product.id ? "Edit Product" : "Create Product"}
      visible={open}
      onOk={() => handleSubmit()}
      onCancel={() => setOpen(false)}
      type="primary"
      footer={false}
    >
      <ProductForm {...product} form={form} />
    </Modal>
  );
}

let mapDispatchToProps = (dispatch) => ({
  createProduct: (formData) => dispatch(createProductAction(formData)),
  updateProduct: (id, formData) => dispatch(updateProductAction(id, formData)),
});

export default connect(null, mapDispatchToProps)(ProductFormModal);
