import React, { useEffect } from "react";
import { Row, Col, Form, Input, Select } from "antd";
import { Button } from "../../components/buttons/buttons";
import FeatherIcon from "feather-icons-react";
import roles from "../../api/data/roles";

const { Option } = Select;
const FilterRow = (props) => {
  const { setFilteredUsers, users } = props;

  const [form] = Form.useForm();

  const handleFilter = () => {
    let filteredUsers = [...users];

    if (form.getFieldValue("name")) {
      filteredUsers = filteredUsers.filter((u) =>
        u.name.toLowerCase().includes(form.getFieldValue("name").toLowerCase())
      );
    }

    if (form.getFieldValue("is_enabled") !== "") {
      filteredUsers = filteredUsers.filter(
        (u) => u.is_enabled === form.getFieldValue("is_enabled")
      );
    }

    if (form.getFieldValue("role")) {
      filteredUsers = filteredUsers.filter(
        (u) => u.role === form.getFieldValue("role")
      );
    }

    setFilteredUsers([...filteredUsers]);
  };

  useEffect(() => {
    handleFilter();
    // eslint-disable-next-line
  }, [users]);

  const handleClear = () => {
    setFilteredUsers([...users]);
    form.resetFields();
    handleFilter();
  };

  const generateRoleOptions = () => {
    return roles.map((role, i) => (
      <Option key={i} value={role}>
        {role}
      </Option>
    ));
  };

  return (
    <Form
      name="users-filter"
      form={form}
      style={{ paddingBottom: "15px", borderBottom: "1px solid #d6d6d6" }}
      initialValues={{ is_enabled: true }}
    >
      <Row gutter={30}>
        <Col sm={8} xs={24} className="mb-25">
          <Form.Item name="name">
            <Input placeholder="Name" />
          </Form.Item>
        </Col>
        <Col sm={8} xs={24} className="mb-25">
          <Form.Item name="is_enabled">
            <Select style={{ minWidth: "300px" }} value={""}>
              <Option value={true}>Enabled</Option>
              <Option value="">All</Option>
              <Option value={false}>Disabled</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col sm={8} xs={24} className="mb-25">
          <Form.Item name="role">
            <Select style={{ minWidth: "300px" }} value={""}>
              <Option value="">Roles</Option>
              {generateRoleOptions()}
            </Select>
          </Form.Item>
        </Col>
        {/* <Col sm={12} xs={24} className="mb-25">
          <Form.Item name="phone">
            <Input placeholder="Customer Phone" />
          </Form.Item>
        </Col> */}
      </Row>
      <Row></Row>
      <Row>
        <Col sm={12} xs={24}>
          <div className="sDash_form-action">
            <Button
              type="primary"
              style={{ marginRight: "15px" }}
              onClick={handleFilter}
            >
              <FeatherIcon icon="search" size={14} />
              Apply
            </Button>
            <Button type="light" onClick={handleClear}>
              <FeatherIcon icon="x" size={14} />
              Clear
            </Button>
          </div>
        </Col>
      </Row>
    </Form>
  );
};

export default FilterRow;
