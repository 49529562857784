import React from "react";
import { Document } from "@react-pdf/renderer";
import CoverPage from "./CoverPage";
import QuotePage from "./QuotePage";
import AboutPage from "./AboutPage";
import TimelinePage from "./TimelinePage";
import DetailsPage from "./DetailsPage";
import CostPage from "./CostPage";
import ProductionsPage from "./ProductionsPage";

function CommercialQuotationPDF(props) {
  const { quote, lang } = props;
  return (
    <Document>
      <CoverPage quote={quote} lang={lang} />
      <AboutPage lang={lang} />
      <TimelinePage lang={lang} />
      <QuotePage quote={quote} lang={lang} />
      <ProductionsPage quote={quote} lang={lang} />
      <DetailsPage quote={quote} lang={lang} />
      <CostPage quote={quote} lang={lang} />
    </Document>
  );
}

CommercialQuotationPDF.defaultProps = {
  lang: 'en'
}

export default CommercialQuotationPDF;