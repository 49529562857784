import React from 'react';
import { Route } from 'react-router-dom';
import SignIn from '../Auth/SignIn';
import Register from '../Auth/Register';
import ResetPassword from '../Auth/ResetPassword'
import NewPassword from '../Auth/NewPassword'

const Auth = [
    <Route path="/sign-in" element={<SignIn />} />,
    <Route path="/register" element={<Register />} />,
    <Route path="/forgot-password" element={<ResetPassword />} />,
    <Route path="/new-password" element={<NewPassword />} />
]

export default Auth;
