import React, { useState } from "react";
import { BasicFormWrapper } from "../../../../containers/styled";
import Heading from "../../../heading/heading";
import { Row, Col, Form, Select, Radio } from "antd";

const { Option } = Select;
function SelectProductForm(props) {
  const { form } = props;
  const [productOption, setProductOption] = useState("");

  let amounts = [8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18];

  let generateOptions = () => {
    if (productOption.includes("Paneles solares")) {
      return amounts.map((amount) => (
        <option value={amount}>
          {amount} - {(amount * 400) / 1000}kW
        </option>
      ));
    } 
    else if (productOption === "Baterías") {
      return (
        <>
          <option value={1}>1</option>
        </>
      );
    }
  };

  const handleChange = (changed, allFields) => {
    let formValues = form.getFieldsValue(true);

    if (
      changed[0] &&
      changed[0].name &&
      changed[0].name[0] === "product" &&
      changed[0].value !== productOption
    ) {
      form.setFieldsValue({
        ...form.getFieldsValue(true),
        quantity: "",
      });
    }

    setProductOption(formValues["product"]);
  };

  return (
    <BasicFormWrapper className="basic-form-inner">
      <div className="atbd-form-checkout">
        <Row justify="center">
          <Col sm={22} xs={24}>
            <div className="payment-method-form">
              <Heading as="h4">3. Añade Productos</Heading>
              <div className="shipping-selection">
                <Form form={form} name="products" onFieldsChange={handleChange}>
                  <Form.Item
                    name="product"
                    initialValue=""
                    label="Seleccione los productos que incluirá el sistema"
                    rules={[{ required: true, message: "Tipo de producto es requerido." }]}
                  >
                    <Radio.Group style={{ width: "100%" }}>
                      <Radio
                        value="Paneles solares y baterías"
                        style={{ width: "100%" }}
                      >
                        <p>Paneles solares y baterías</p>
                      </Radio>
                      <Radio
                        value="Paneles solares y baterías +"
                        style={{ width: "100%" }}
                      >
                        <p>Paneles solares y baterías PLUS</p>
                      </Radio>
                      <Radio value="Baterías" style={{ width: "100%" }}>
                        <p>Baterías</p>
                      </Radio>
                    </Radio.Group>
                  </Form.Item>
                  {productOption && productOption.length > 0 && (
                    <Form.Item
                      name="quantity"
                      rules={[
                        {
                          message: "Por favor escoge una cantidad!",
                          required: true,
                        },
                      ]}
                      initialValue={""}
                      label={productOption.includes("Paneles solares") ? "Cantidad de paneles solares" : "Cantidad de baterías"}
                    >
                      <Select style={{ minWidth: "300px" }}>
                        <Option value="">Seleccionar</Option>
                        {generateOptions()}
                      </Select>
                    </Form.Item>
                  )}
                </Form>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </BasicFormWrapper>
  );
}

export default SelectProductForm;
