// import React from "react";

const languages = {
  en: {
    includedPrice: "Included",
  },
  es: {
    includedPrice: "Incluido",
  }
};

export default languages;
