import React from "react";
import { Page, Text, View, StyleSheet, Image } from "@react-pdf/renderer";
// import text from "./lang";
import moment from "moment";
moment.locale("es");

const styles = StyleSheet.create({
  page: {
    // flexDirection: "row",
    backgroundColor: "#FFF",
    boxSizing: "border-box",
    fontSize: 14,
    padding: 0,
  },
  // section: {
  //   margin: 10,
  //   padding: 10,
  //   flexGrow: 1,
  // },
  header: {
    width: "100%",
    display: "flex",
    alignSelf: "center",
    backgroundColor: "#1D4091",
    color: "#fff",
    padding: "16 16 8 16",
    marginBottom: 16,
    borderBottom: "solid 3px #0E9CD5",
  },
  headerText: {
    fontSize: 14,
    color: "#1f497d",
    margin: "0 10",
  },
  bodyText: {
    fontSize: "12px",
    color: "#343a40",
    margin: "0",
    lineHeight: "2px",
  },
  secondaryHeader: {
    fontSize: 16,
    margin: "8 0",
  },
  flex: {
    display: "flex",
  },
  bold: {
    fontFamily: "Helvetica-Bold",
  },
  justifiedRight: {
    textAlign: "right",
  },
  orangeText: {
    color: "#E9682A",
  },
  grayText: {
    color: "#343a40",
  },
  blueText: {
    color: "#2C4596",
  },
  table: {
    width: "100%",
  },
  tableRow: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-around",
  },
  cell: {
    paddingTop: "5",
    paddingBottom: "3",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    border: "1 solid #ccc",
    width: "33.333333%",
    height: "100%",
    fontSize: "9",
  },
  headerCell: {
    backgroundColor: "#E9682A",
    color: "#FFFFFF",
  },
  totalPrice: {
    marginTop: "8px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    width: "100%",
  },
  upperCase: {
    textTransform: "uppercase",
  },
  lineText: {
    display: "flex",
    flexDirection: "row",
  },
});

function CoverPage(props) {
  const {
    // lang,
    quote,
  } = props;
  return (
    <Page size="A4" style={styles.page}>
      {/* Page Container */}
      <View
        style={{
          width: "100%",
          display: "flex",
          // border: "1px solid #ccc",
          boxSizing: "border-box",
          justifyContent: "space-between",
          height: "100%",
        }}
      >
        {/* Cover Image */}
        <View>
          <Image
            style={{
              height: "600px",
              objectFit: "cover",
            }}
            src={`/assets/commercial quotes images/power-solar-top-view-gradient.png`}
          />
        </View>

        {/* Consultor, client and quote title */}
        <View
          style={[
            {
              borderBottom: "4px solid #0E9CD5",
              width: "100%",
              display: "flex",
              flexDirection: "row",
              height: "25%",
            },
          ]}
        >
          {/* Document Title */}
          <View
            style={[
              {
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                height: "100%",
                width: "60%",
                backgroundColor: "#1D4091",
                color: "#fff",
                padding: "40 32 40 32",
                marginBottom: 0,
                textAlign: "center",
                fontSize: 20,
              },
              styles.upperCase,
            ]}
          >
            <Text>
              Cotización <Text style={[{}, styles.bold]}>Comercial</Text>
            </Text>
          </View>

          {/* Consultor y cliente Info*/}
          <View
            style={[
              {
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                height: "100%",
                width: "40%",
                minHeight: "100px",
                padding: 24,
                backgroundColor: "#FFF",
                fontSize: 12,
              },
              styles.blueText,
            ]}
          >
            <Text>Consultor</Text>
            <Text
              style={[
                {
                  fontSize: 16,
                },
                styles.bold,
              ]}
            >
              {quote.user.name}
            </Text>

            <Text style={[{ marginTop: "12px" }]}>Nombre de Cliente</Text>
            <Text
              style={[
                {
                  fontSize: 16,
                },
                styles.bold,
              ]}
            >
              {quote.client_name}
            </Text>
            <Text
              style={[
                {
                  marginTop: "12px",
                },
              ]}
            >
              Fecha
            </Text>
            <Text
              style={[
                {
                  fontSize: 16,
                },
                styles.bold,
              ]}
            >
              {moment().format("MMMM DD, YYYY").charAt(0).toUpperCase() +
                moment().format("MMMM DD, YYYY").slice(1)}
            </Text>
          </View>
        </View>

        {/* Contact Info */}
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: "100%",
            backgroundColor: "#E9682A",
            padding: "16px",
            fontSize: 10,
            color: "white",
          }}
        >
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <Image
              style={{
                height: "14px",
                display: "flex",
                flexDirection: "row",
                marginRight: 4,
              }}
              src="/assets/icons8-phone-64.png"
            />
            <Text style={[styles.whiteSmallText]}>787.331.100</Text>
          </View>

          <View
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <Image
              style={{
                height: "14px",
                display: "flex",
                flexDirection: "row",
                marginRight: 4,
              }}
              src="/assets/icons8-website-64.png"
            />
            <Text style={[styles.whiteSmallText]}>powersolarpr.com</Text>
          </View>

          <View
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <Image
              style={{
                height: "14px",
                width: "auto",
                display: "flex",
                flexDirection: "row",
                marginRight: 4,
              }}
              src="/assets/icons8-facebook-64.png"
            />
            <Text style={[styles.whiteSmallText]}>PowerSolarPR</Text>
          </View>

          <View
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <Image
              style={{
                height: "14px",
                display: "flex",
                flexDirection: "row",
                marginRight: 4,
              }}
              src="/assets/icons8-location-64.png"
            />
            <Text style={[styles.whiteSmallText]}>
              279 calle César L. Gónzalez • San Juan, P.R. 00918
            </Text>
          </View>
        </View>
      </View>
    </Page>
  );
}

export default CoverPage;
