import React from "react";
import { Page, Text, View, StyleSheet, Image, Font } from "@react-pdf/renderer";
import { formatter } from "../../helpers/helper";
import moment from "moment";
import "moment/locale/es";
import PoppinsRegular from "../../assets/fonts/Poppins-Regular.ttf";
import PoppinsBold from "../../assets/fonts/Poppins-Bold.ttf";
import PoppinsItalic from "../../assets/fonts/Poppins-Italic.ttf";
import ZeyadaRegular from "../../assets/fonts/Zeyada-Regular.ttf";
import {
  costosNuevaEnergia2,
  costoBatería,
} from "../../api/data/costosNuevaEnergia2";

Font.register({
  family: "Poppins",
  format: "truetype",
  src: PoppinsRegular,
});

Font.register({
  family: "PoppinsBold",
  format: "truetype",
  src: PoppinsBold,
});

Font.register({
  family: "PoppinsItalic",
  format: "truetype",
  src: PoppinsItalic,
});

Font.register({
  family: "Zeyada",
  format: "truetype",
  src: ZeyadaRegular,
});

const styles = StyleSheet.create({
  page: {
    // flexDirection: "row",
    backgroundColor: "#FFF",
    boxSizing: "border-box",
    fontSize: 8,
    padding: 24,
    fontFamily: "Poppins",
  },
  // section: {
  //   margin: 10,
  //   padding: 10,
  //   flexGrow: 1,
  // },
  header: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
  },
  headerText: {
    fontSize: 12,
    color: "#1f497d",
    margin: "0 10",
  },
  bodyText: {
    fontSize: 8,
    color: "#343a40",
    // margin: "0 10",
  },
  secondaryHeader: {
    fontSize: 10,
    margin: "8 0",
  },
  flex: {
    display: "flex",
  },
  bold: {
    fontFamily: "PoppinsBold",
  },
  justifiedRight: {
    textAlign: "right",
  },
  orangeText: {
    color: "#E9682A",
  },
  grayText: {
    color: "#343a40",
  },
  table: {
    width: "100%",
    borderRight: "1px solid #E9682A",
  },
  tableRow: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-around",
  },
  cell: {
    paddingTop: "5",
    paddingBottom: "3",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    borderLeft: "1px solid #E9682A",
    borderBottom: "1px solid #E9682A",
    width: "33.333333%",
    height: "100%",
    fontSize: "8",
  },
  headerCell: {
    backgroundColor: "#E9682A",
    color: "#FFFFFF",
    border: "0px solid #E9682A",
  },
  totalPrice: {
    marginTop: "8px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    width: "100%",
  },
  upperCase: {
    textTransform: "uppercase",
  },
  lineText: {
    display: "flex",
    flexDirection: "row",
  },

  boxedText: {
    padding: "4px 8px 2px 8px",
    borderRadius: "3px",
    border: "1px solid #dcdcdc",
    width: "100%",
  },

  marginSpacer: {
    marginTop: "6px",
    marginBottom: "4px",
  },
});

export default function ViviendaEstimatePage(props) {
  const { values, user } = props;

  const generateDate = () => {
    let localLocale = moment();
    localLocale.locale("es");

    return localLocale.format("DD [de] MMMM [de] YYYY");
  };

  const generateProducts = () => {
    let productRows = [];

    if (values && values["quantity"]) {
      if (values.product.includes("Paneles solares")) {
        productRows.push(
          <View style={[styles.tableRow]} key={1 + ""}>
            <Text style={[styles.cell]}>Panel Solar</Text>
            <Text style={[styles.cell]}>Canadian Solar - CS6R-400W</Text>
            <Text style={[styles.cell]}>{values["quantity"]}</Text>
            <Text style={[styles.cell]}>
              {formatter.format(
                costosNuevaEnergia2[values["quantity"]].costoPaneles
              )}
            </Text>
          </View>
        );
      }
      if (values.product === "Paneles solares y baterías") {
        productRows.push(
          <View style={[styles.tableRow]} key={2 + ""}>
            <Text style={[styles.cell]}>Inversor</Text>
            <Text style={[styles.cell]}>Enphase - IQ8H-72-2-US</Text>
            <Text style={[styles.cell]}>{values["quantity"]}</Text>
            <Text style={[styles.cell]}>Incluído</Text>
          </View>
        );
      }
      productRows.push(
        <View style={[styles.tableRow]} key={3 + ""}>
          <Text style={[styles.cell]}>Batería Almacenamiento</Text>
          <Text style={[styles.cell]}>
            Tesla - Powerwall {values.product.includes(" +") ? "+" : "2"} AC
          </Text>
          <Text style={[styles.cell]}>
            {values.product.includes("Paneles solares") ? 1 : values.quantity}
          </Text>
          <Text style={[styles.cell]}>
            {formatter.format(
              values.product.includes("Paneles solares")
                ? costosNuevaEnergia2[values.quantity].costoBatería
                : costoBatería[values.quantity]
            )}
          </Text>
        </View>
      );
      if (values.product.includes("Paneles solares")) {
        productRows.push(
          <View style={[styles.tableRow]}>
            <Text style={[styles.cell]}>Anclaje</Text>
            <Text style={[styles.cell]}>Unirac</Text>
            <Text style={[styles.cell]}>Incluído</Text>
            <Text style={[styles.cell]}>Incluído</Text>
          </View>
        );
      }
      productRows.push(
        <View style={[styles.tableRow]}>
          <Text style={[styles.cell]}></Text>
          <Text style={[styles.cell]}></Text>
          <Text style={[styles.cell]}>Total</Text>
          <Text style={[styles.cell]}>
            {formatter.format(generateFinalPrice())}
          </Text>
        </View>
      );
    }

    return productRows;
  };

  const generateFinalPrice = () => {
    if (values && values.quantity) {
      if (values.product.includes("Paneles solares")) {
        return (
          costosNuevaEnergia2[values.quantity].costoBatería +
          costosNuevaEnergia2[values.quantity].costoPaneles
        );
      } else if (values.product === "Baterías") {
        return costoBatería[values.quantity];
      }
    }
    return 0;
  };

  const generateTotalProduction = () => {
    if (values.product.includes("Paneles solares")) {
      return (values["quantity"] * 400) / 1000;
    }
    return 0;
  };

  return (
    <Page size="A4" style={styles.page}>
      {/* Page Container */}
      <View
        style={{
          width: "100%",
          display: "flex",
          boxSizing: "border-box",
          justifyContent: "space-between",
          height: "100%",
          padding: "30px",
          color: "#343434",
        }}
      >
        {/* Header */}
        <View
          style={[
            {
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "row",
              alignItems: "flex-start",
              marginBottom: 0,
              fontSize: 14,
            },
          ]}
        >
          <View
            style={[
              {
                width: "100%",
                display: "flex",
                flexDirection: "column",
              },
            ]}
          >
            <View
              style={[
                {
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  fontSize: "24px",
                },
                styles.bold,
              ]}
            >
              <Text>Cotización de Sistema Solar</Text>
            </View>
            <Text style={styles.grayText}>Power Solar, LLC</Text>
          </View>
          <Image
            style={{
              height: "34px",
            }}
            src={`/assets/commercial quotes images/powersolar-logo-black.png`}
          />
        </View>

        {/* Page Content */}

        <View
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          {/* Informacion de boleto */}

          {/* Nombre y # de boleto */}
          <View
            style={[
              {
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                borderBottom: "1px solid #7b7b7b",
                paddingBottom: "10px",
              },
              styles.marginSpacer,
            ]}
          >
            {/* Name Field */}
            <View
              style={[
                {
                  display: "flex",
                  width: "44%",
                  flexDirection: "row",
                  alignItems: "center",
                  textAlign: "left",
                },
              ]}
            >
              <Text
                style={[
                  {
                    textAlign: "left",
                    width: "46px",
                    marginRight: "5px",
                  },
                  styles.bold,
                  styles.bodyText,
                ]}
              >
                Nombre
              </Text>
              <Text style={[styles.boxedText]}>{values.customer_name}</Text>
            </View>

            {/* Ticket Number Field */}

            <View
              style={[
                {
                  display: "flex",
                  width: "27%",
                  flexDirection: "row",
                  alignItems: "center",
                },
              ]}
            >
              <Text
                style={[
                  {
                    textAlign: "left",
                    width: "38px",
                    marginRight: "5px",
                  },
                  styles.bold,
                  styles.bodyText,
                ]}
              >
                Boleto
              </Text>
              <Text style={[styles.boxedText]}>
                {values.ticket_number ? values.ticket_number : " "}
              </Text>
            </View>

            {/* Quote ID Field */}
            <View
              style={[
                {
                  display: "flex",
                  width: "26%",
                  flexDirection: "row",
                  alignItems: "center",
                },
              ]}
            >
              <Text
                style={[
                  {
                    textAlign: "left",
                    width: "16px",
                  },
                  styles.bold,
                  styles.bodyText,
                ]}
              >
                ID
              </Text>
              <Text style={[styles.boxedText]}>
                PS-{(values.id + "").padStart(5, "0")}
              </Text>
            </View>
          </View>

          {/* direccion y latitud*/}
          <View
            style={[
              {
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                // marginBottom: "8px",
              },
              styles.marginSpacer,
            ]}
          >
            {/* Direccion Field */}
            <View
              style={[
                {
                  display: "flex",
                  width: "61%",
                  flexDirection: "row",
                  alignItems: "center",
                },
              ]}
            >
              <Text
                style={[
                  {
                    textAlign: "left",
                    width: "80px",
                    marginRight: "4px",
                  },
                  styles.bold,
                  styles.bodyText,
                ]}
              >
                Dirección
              </Text>
              <Text style={[styles.boxedText]}>{values.address_line}</Text>
            </View>

            {/* Latitud Field */}

            <View
              style={[
                {
                  display: "flex",
                  width: "35%",
                  flexDirection: "row",
                  alignItems: "center",
                },
              ]}
            >
              <Text
                style={[
                  {
                    // paddingRight: "4px",
                    textAlign: "left",
                    width: "60px",
                    marginRight: "4px",
                  },
                  styles.bold,
                  styles.bodyText,
                ]}
              >
                Latitud
              </Text>
              <Text style={[styles.boxedText]}>{values.latitude}</Text>
            </View>
          </View>
          {/* municipio y longitud*/}
          <View
            style={[
              {
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                // marginBottom: "8px",
              },
              styles.marginSpacer,
            ]}
          >
            {/* Municipio Field */}
            <View
              style={[
                {
                  display: "flex",
                  width: "61%",
                  flexDirection: "row",
                  alignItems: "center",
                },
              ]}
            >
              <Text
                style={[
                  {
                    textAlign: "left",
                    width: "80px",
                    marginRight: "4px",
                  },
                  styles.bold,
                  styles.bodyText,
                ]}
              >
                Municipio
              </Text>
              <Text style={[styles.boxedText]}>{values.municipality}</Text>
            </View>

            {/* Longitud Field */}

            <View
              style={[
                {
                  display: "flex",
                  width: "35%",
                  flexDirection: "row",
                  alignItems: "center",
                },
              ]}
            >
              <Text
                style={[
                  {
                    paddingRight: "4px",
                    textAlign: "left",
                    width: "60px",
                    marginRight: "4px",
                  },
                  styles.bold,
                  styles.bodyText,
                ]}
              >
                Longitud
              </Text>
              <Text style={[styles.boxedText]}>-{values.longitude}</Text>
            </View>
          </View>

          {/* Consumo Energético Doméstico*/}
          <View
            style={[
              {
                width: "100%",
                borderBottom: "1px solid #E9682A",
              },
              styles.marginSpacer,
            ]}
          >
            <View
              style={[
                {
                  width: "50%",
                  padding: "2px 12px",
                  textAlign: "left",
                  color: "#fff",
                  borderBottom: "1px solid #E9682A",
                  backgroundColor: "#E9682A",
                  //   borderRadius: "5px 5px 0 0",
                },
                styles.bold,
                styles.upperCase,
              ]}
            >
              <Text>Consumo Energético Doméstico</Text>
            </View>
          </View>

          <View
            style={[
              {
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              },
              styles.marginSpacer,
            ]}
          >
            {/* Consumo de Energía Anual kWh Field */}
            <View
              style={[
                {
                  display: "flex",
                  width: "48%",
                  flexDirection: "row",
                  alignItems: "center",
                  textAlign: "center",
                },
              ]}
            >
              <Text
                style={[
                  {
                    paddingRight: "4px",
                    textAlign: "left",
                    width: "380px",
                  },
                  styles.bold,
                  styles.bodyText,
                ]}
              >
                Consumo de Energía Anual kWh
              </Text>
              <Text style={[{}, styles.boxedText]}>
                {values.annual_client_consumption}
              </Text>
            </View>

            {/* Producción Anual Simulada por PVWatts kWh */}
            <View
              style={[
                {
                  display: "flex",
                  width: "48%",
                  flexDirection: "row",
                  alignItems: "center",
                  textAlign: "center",
                },
              ]}
            >
              <Text
                style={[
                  {
                    paddingRight: "4px",
                    textAlign: "left",
                    width: "350px",
                  },
                  styles.bold,
                  styles.bodyText,
                ]}
              >
                Producción Anual Simulada por PVWatts kWh
              </Text>
              <Text style={[styles.boxedText]}>
                {values.annual_client_consumption_capacity
                  ? values.annual_client_consumption_capacity
                  : " "}
              </Text>
            </View>
          </View>

          {values.annual_client_consumption &&
            values.annual_client_consumption_capacity && (
              <View
                style={[
                  {
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    width: "100%",
                    alignItems: "center",
                  },
                  styles.marginSpacer,
                ]}
              >
                <Text style={[{ textAlign: "center" }, styles.bold]}>
                  Producción Anual Simulada por PVWatts kWh / Consumo de Energía
                  Anual kWh Anual kW =
                </Text>
                <Text
                  style={{
                    fontSize: "14px",
                    color: "#E9682A",
                    textAlign: "center",
                  }}
                >
                  {" " +
                    Math.round(
                      (parseInt(
                        values.annual_client_consumption_capacity.replace(
                          /,/g,
                          ""
                        ),
                        10
                      ) /
                        parseInt(
                          values.annual_client_consumption.replace(/,/g, ""),
                          10
                        )) *
                        1000
                    ) /
                      10 +
                    "%"}
                </Text>
              </View>
            )}

          {/* Propuesta del sistema */}
          <View
            style={[
              {
                width: "100%",
                borderBottom: "1px solid #E9682A",
              },
              styles.marginSpacer,
            ]}
          >
            <View
              style={[
                {
                  width: "50%",
                  padding: "2px 12px",
                  //   textAlign: "left",
                  color: "#fff",
                  borderBottom: "1px solid #E9682A",
                  backgroundColor: "#E9682A",
                  //   borderRadius: "5px",
                },
                styles.bold,
                styles.upperCase,
              ]}
            >
              <Text>Propuesta del sistema</Text>
            </View>
          </View>
          <View
            style={[
              {
                width: "100%",
                padding: "0px 24px",
              },
              styles.marginSpacer,
            ]}
          >
            <View
              style={[
                {
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                },
                styles.marginSpacer,
              ]}
            >
              <View
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  width: "50%",
                  padding: "10px 0px",
                }}
              >
                <Text style={[{ textAlign: "center" }, styles.bold]}>
                  Empresa Instaladora:
                </Text>
                <Text
                  style={{
                    fontSize: "16px",
                    color: "#E9682A",
                    textAlign: "center",
                  }}
                >
                  Power Solar, LLC
                </Text>
              </View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  width: "50%",
                  padding: "10px 0px",
                }}
              >
                <Text style={[{ textAlign: "center" }, styles.bold]}>
                  Tipo de Sistema:
                </Text>
                <Text
                  style={{
                    fontSize: "16px",
                    color: "#E9682A",
                    textAlign: "center",
                  }}
                >
                  {values.product.includes("Paneles solares")
                    ? "PVS + BSS"
                    : "BSS"}
                </Text>
              </View>
            </View>

            {/* Table */}
            <View style={[{}, styles.table, styles.marginSpacer]}>
              <View style={[styles.tableRow]}>
                <Text
                  style={[
                    styles.cell,
                    styles.headerCell,
                    styles.upperCase,
                    styles.bold,
                  ]}
                >
                  Producto
                </Text>
                <Text
                  style={[
                    styles.cell,
                    styles.headerCell,
                    styles.upperCase,
                    styles.bold,
                  ]}
                >
                  Modelo
                </Text>
                <Text
                  style={[
                    styles.cell,
                    styles.headerCell,
                    styles.upperCase,
                    styles.bold,
                  ]}
                >
                  Cantidad
                </Text>
                <Text
                  style={[
                    styles.cell,
                    styles.headerCell,
                    styles.upperCase,
                    styles.bold,
                  ]}
                >
                  Precio
                </Text>
              </View>
              {generateProducts()}
            </View>

            <View
              style={[
                {
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  padding: "10px 0px",
                  width: "100%",
                },
                styles.marginSpacer,
              ]}
            >
              {/* Capacidad del sistema fotovoltaico Field */}
              <View
                style={[
                  {
                    display: "flex",
                    width: "49%",
                    flexDirection: "column",
                    alignItems: "center",
                    textAlign: "center",
                  },
                ]}
              >
                <Text
                  style={[
                    {
                      textAlign: "center",
                      width: "100%",
                    },
                    styles.bold,
                    styles.bodyText,
                  ]}
                >
                  Capacidad del sistema
                </Text>
                <Text
                  style={[
                    {
                      textAlign: "center",
                      width: "100%",
                    },
                    styles.bold,
                    styles.bodyText,
                  ]}
                >
                  fotovoltáico kW
                </Text>
                <Text style={[styles.boxedText]}>
                  {generateTotalProduction()}
                </Text>
              </View>

              {/* Capacidad Almacenamiento de Batería kWh Field */}
              <View
                style={[
                  {
                    display: "flex",
                    width: "49%",
                    flexDirection: "column",
                    alignItems: "center",
                    textAlign: "center",
                  },
                ]}
              >
                <Text
                  style={[
                    {
                      textAlign: "center",
                      width: "100%",
                    },
                    styles.bold,
                    styles.bodyText,
                  ]}
                >
                  Capacidad Almacenamiento
                </Text>
                <Text
                  style={[
                    {
                      textAlign: "center",
                      width: "100%",
                    },
                    styles.bold,
                    styles.bodyText,
                  ]}
                >
                  de Batería kWh
                </Text>
                <Text style={[styles.boxedText]}>
                  {values.product.includes("Paneles solares")
                    ? 13.5
                    : 13.5 * values.quantity}
                </Text>
              </View>
            </View>
          </View>
        </View>

        {/* Signature Section */}
        <View
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              borderBottom: "1px solid #7b7b7b",
              paddingBottom: "5px",
              marginBottom: "12px",
            }}
          >
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                width: "50%",
                justifyContent: "center",
              }}
            >
              <Text style={styles.bold}> Consultor:</Text>
              <Text> {user.name}</Text>
            </View>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                width: "50%",
                justifyContent: "center",
              }}
            >
              <Text style={styles.bold}> Fecha: </Text>
              <Text> {generateDate()}</Text>
            </View>
          </View>

          <Text
            style={{
              fontSize: "26px",
              color: "#2C4596",
              textAlign: "center",
              marginBottom: "4px",
              fontFamily: "Zeyada",
            }}
          >
            {user.name}
          </Text>
          <Text style={[{ textAlign: "center" }, styles.bold]}>
            Firma de Consultor
          </Text>
        </View>
      </View>
    </Page>
  );
}
