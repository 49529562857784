import {
  LOADING_QUOTES,
  CLEAR_LOADING_QUOTES,
  CREATE_QUOTE,
  FETCH_QUOTES,
  UPDATE_QUOTE,
} from "../constants/quoteConstants";

const initialState = {
  quotes: [],
  loading: false,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case CLEAR_LOADING_QUOTES:
      return {
        ...state,
        loading: false,
      };
    case LOADING_QUOTES:
      return {
        ...state,
        loading: true,
      };
    case FETCH_QUOTES:
      return {
        ...state,
        loading: false,
        quotes: [...state.quotes, ...action.payload],
      };
    case CREATE_QUOTE:
      return {
        ...state,
        loading: false,
        quotes: state.quotes.length === 0 ? [] : [...state.quotes, action.payload],
      };
    case UPDATE_QUOTE:
      return {
        ...state,
        loading: false,
        quotes: [
            ...state.quotes.filter(q => q && q.id && q.id !== action.payload.id),
            action.payload
        ]
      };
    default:
      return state;
  }
}
