import {
    LOADING_USERS,
    CLEAR_LOADING_USERS,
    FETCH_USERS,
    UPDATE_USER,
  } from "../constants/userConstants";
  
  const initialState = {
    users: [],
    loading: false,
  };
  
  export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
      case CLEAR_LOADING_USERS:
        return {
          ...state,
          loading: false,
        };
      case LOADING_USERS:
        return {
          ...state,
          loading: true,
        };
      case FETCH_USERS:
        return {
          ...state,
          loading: false,
          users: action.payload,
        };
      case UPDATE_USER:
        return {
          ...state,
          loading: false,
          users: [
              ...state.users.filter(u => u && u.id && u.id !== action.payload.id),
              action.payload
          ]
        };
      default:
        return state;
    }
  }
  