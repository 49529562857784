import React, { useState } from "react";
import { Row, Col } from "antd";
import Heading from "../../../heading/heading";
import { Cards } from "../../../cards/frame/cards-frame";
import { Button } from "../../../buttons/buttons";
import FeatherIcon from "feather-icons-react";
import { connect } from "react-redux";
import { generateQuotePDF } from "../../../helpers/helper";
import { setCurrentKeyAction } from "../../../../redux/actions/uiActions";

function SuccessStep(props) {
  const { quote, setCurrentKey, resetForm } = props;

  const [loading, setLoading] = useState({en: false, es: false});

  const handleGeneratePDF = async (lang) => {
    setLoading({...loading, [lang]: true});

    console.log(quote, props)

    await generateQuotePDF(quote, lang, "ecoFlow");

    setLoading({...loading, [lang]: false});
  };

  return (
    <Row justify="center" style={{ width: "100%" }}>
      <Col xl={22} xs={24}>
        <div className="checkout-successful">
          <Cards
            headless
            bodyStyle={{
              backgroundColor: "#F8F9FB",
              borderRadius: "20px",
            }}
          >
            <div style={{ padding: "52px 0px" }}>
              <FeatherIcon icon="check" size={48} className="text-success" />
              <Heading as="h3">
                The Quote Has Been Created Successfully!
              </Heading>

              {/* <p style={{ marginBottom: "8px" }}>Choose a language:</p> */}
              <div>
                <Button
                  type="info"
                  size="default"
                  onClick={() => handleGeneratePDF("en")}
                  style={{ width: "220px", marginBottom: "8px" }}
                >
                  <FeatherIcon
                    icon={loading.en ? "loader" : "download"}
                    size={18}
                    className={`pdf-loader-icon ${loading.en ? "loading" : ""}`}
                  />
                  {loading.en ? "Generating PDF..." : "Download PDF"}
                </Button>
              </div>
              {/* <div>
                <Button
                  type="info"
                  size="default"
                  onClick={() => handleGeneratePDF("es")}
                  style={{ width: "220px" }}
                >
                  <FeatherIcon
                    icon={loading.es ? "loader" : "download"}
                    size={18}
                    className={`pdf-loader-icon ${loading.es ? "loading" : ""}`}
                  />
                  {loading.es ? "Generating PDF..." : "Download PDF (Español)"}
                </Button>
              </div> */}
            </div>

            <Cards headless>
              <p>What would you like to do next?</p>
              <br />
              <Row gutter={16} justify="center">
                <Col md={6} xs={24}>
                  <Button
                    type="primary"
                    outlined="1"
                    size="default"
                    style={{ marginBottom: "16px", width: "100%" }}
                    onClick={() => setCurrentKey("/app/quotations")}
                  >
                    Go To Quotations
                    <FeatherIcon
                      style={{ paddingLeft: "8px", width: "24px" }}
                      icon="arrow-right"
                      size={18}
                    />
                  </Button>
                </Col>
                <Col md={6} xs={24}>
                  <Button
                    type="primary"
                    size="default"
                    style={{ width: "100%" }}
                    onClick={() => resetForm()}
                  >
                    <FeatherIcon icon="plus" size={18} /> Create Quote
                  </Button>
                </Col>
              </Row>
            </Cards>
          </Cards>
        </div>
      </Col>
    </Row>
  );
}

let mapStateToProps = (state) => ({
  products: state.products.products,
});

const mapDispatchToProps = (dispatch) => ({
  setCurrentKey: (key) => dispatch(setCurrentKeyAction(key)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SuccessStep);
