import { API_URL } from "../constants/apiURLConstants";
import {
  LOADING_NEW_ENERGY_2_QUOTES,
  CLEAR_LOADING_NEW_ENERGY_2_QUOTES,
  CREATE_NEW_ENERGY_2_QUOTE,
  FETCH_NEW_ENERGY_2_QUOTES,
  UPDATE_NEW_ENERGY_2_QUOTE,
} from "../constants/newEnergy2QuoteConstants";
import axios from "axios";
import { errorHandler } from "../helpers.js/helper";

export function fetchNewEnergy2QuotesAction() {
  return (dispatch) => {
    dispatch({ type: LOADING_NEW_ENERGY_2_QUOTES });
    return (
      axios
        .get(`${API_URL}new-energy-2-quotes`, {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        // eslint-disable-next-line consistent-return
        .then((responseJSON) => {
          if (responseJSON && responseJSON.data) {
            dispatch({
              type: FETCH_NEW_ENERGY_2_QUOTES,
              payload: responseJSON.data,
            });
            return responseJSON.data;
          }
          dispatch({ type: CLEAR_LOADING_NEW_ENERGY_2_QUOTES });
          return {};
        })
        .catch((error) => {
          errorHandler(dispatch, error, () => {
            dispatch({ type: CLEAR_LOADING_NEW_ENERGY_2_QUOTES });
          });
        })
    );
  };
}

export function createNewEnergy2QuoteAction(formData) {
  return (dispatch) => {
    dispatch({ type: LOADING_NEW_ENERGY_2_QUOTES });
    return (
      axios
        .post(`${API_URL}new-energy-2-quotes`, formData, {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        // eslint-disable-next-line consistent-return
        .then((responseJSON) => {
          if (responseJSON && responseJSON.data && responseJSON.data.quote) {
            dispatch({ type: CREATE_NEW_ENERGY_2_QUOTE, payload: responseJSON.data.quote });
            return responseJSON.data;
          }
          dispatch({ type: CLEAR_LOADING_NEW_ENERGY_2_QUOTES });
          return responseJSON;
        })
        .catch((error) => {
          return errorHandler(dispatch, error, () => {
            dispatch({ type: CLEAR_LOADING_NEW_ENERGY_2_QUOTES });
            if (
              error.response &&
              error.response.data &&
              error.response.data.errors &&
              Object.keys(error.response.data.errors).length > 0
            ) {
              return error.response.data.errors;
            }
            return false;
          });
        })
    );
  };
}

export function updateNewEnergy2QuoteAction(quoteId, formData) {
  return (dispatch) => {
    dispatch({ type: LOADING_NEW_ENERGY_2_QUOTES });
    return (
      axios
        .patch(`${API_URL}new-energy-2-quotes/${quoteId}`, formData, {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        // eslint-disable-next-line consistent-return
        .then((responseJSON) => {
          if (responseJSON && responseJSON.data && responseJSON.data.quote) {
            dispatch({
              type: UPDATE_NEW_ENERGY_2_QUOTE,
              payload: responseJSON.data.quote,
            });
            return responseJSON.data.quote;
          }
          dispatch({ type: CLEAR_LOADING_NEW_ENERGY_2_QUOTES });
          return responseJSON;
        })
        .catch((error) => {
          return errorHandler(dispatch, error, () => {
            dispatch({ type: CLEAR_LOADING_NEW_ENERGY_2_QUOTES });
            if (
              error.response &&
              error.response.data &&
              error.response.data.errors &&
              Object.keys(error.response.data.errors).length > 0
            ) {
              return error.response.data.errors;
            }
            return false;
          });
        })
    );
  };
}
