import React, { useEffect } from "react";
import { BasicFormWrapper } from "../../../../containers/styled";
import Heading from "../../../heading/heading";
import { Row, Col, Form, Input } from "antd";

function CustomerForm(props) {
  const { form } = props;

  useEffect(() => {
    form.setFieldsValue({
      ...form.getFieldsValue(true),
      country: "PR",
    });
  }, [form]);

  return (
    <BasicFormWrapper className="basic-form-inner">
      <div className="atbd-form-checkout">
        <Row justify="center">
          <Col sm={22} xs={24}>
            <div className="create-account-form">
              <Heading as="h4">1. Please Add Customer Information</Heading>
              <Form form={form} name="account">
                <Form.Item
                  name="customer_name"
                  label="Customer Name"
                  rules={[
                    { required: true, message: "Customer name is required!" },
                  ]}
                >
                  <Input placeholder="Justin Rivera" />
                </Form.Item>
                <Form.Item
                  name="address_line1"
                  label="Address Line"
                  // eslint-disable-next-line react/jsx-no-duplicate-props
                  rules={[
                    { required: true, message: "Address Line 1 is required" },
                  ]}
                >
                  <Input placeholder="" />
                </Form.Item>
                {/* <Form.Item name="address_line2" label="Address Line 2">
                  <Input placeholder="" />
                </Form.Item> */}
                <Form.Item
                  name="state"
                  label="City"
                  // eslint-disable-next-line react/jsx-no-duplicate-props
                  rules={[{ required: true, message: "City is required" }]}
                >
                  <Input placeholder="San Juan" />
                </Form.Item>
                <Form.Item name="country" label="Country">
                  <Input placeholder="PR" disabled />
                </Form.Item>
                <Form.Item name="luma_monthly_bill" label="Luma Monthly Bill" >
                  <Input placeholder="price" addonAfter="$" />
                </Form.Item>
              </Form>
            </div>
          </Col>
        </Row>
      </div>
    </BasicFormWrapper>
  );
}

export default CustomerForm;
