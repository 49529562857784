import React from "react";
import { Page, Text, View, StyleSheet, Image } from "@react-pdf/renderer";
// import text from "./lang";
import moment from "moment";
import "moment/locale/es";
import { getProductPrice } from "../helpers/helper";
import { formatter } from "../../helpers/helper";

const styles = StyleSheet.create({
  page: {
    // flexDirection: "row",
    backgroundColor: "#FFF",
    boxSizing: "border-box",
    fontSize: 14,
    padding: 0,
  },
  // section: {
  //   margin: 10,
  //   padding: 10,
  //   flexGrow: 1,
  // },
  header: {
    width: "100%",
    display: "flex",
    alignSelf: "center",
    backgroundColor: "#1D4091",
    color: "#fff",
    padding: "16 16 8 16",
    marginBottom: 16,
    borderBottom: "solid 3px #0E9CD5",
  },
  headerText: {
    fontSize: 14,
    color: "#1f497d",
    margin: "0 10",
  },
  bodyText: {
    fontSize: "12px",
    color: "#343a40",
    margin: "0",
    lineHeight: "2px",
  },
  secondaryHeader: {
    fontSize: 16,
    margin: "8 0",
  },
  flex: {
    display: "flex",
  },
  bold: {
    fontFamily: "Helvetica-Bold",
  },
  justifiedRight: {
    textAlign: "right",
  },
  orangeText: {
    color: "#E9682A",
  },
  grayText: {
    color: "#343a40",
  },

  blueText: {
    color: "#2C4596",
  },

  greenText: {
    color: "#198754",
  },

  redText: {
    color: "#CC0000",
  },

  table: {
    width: "100%",
  },
  tableRow: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-around",
  },
  cell: {
    paddingTop: "5",
    paddingBottom: "3",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    border: "1 solid #ccc",
    width: "33.333333%",
    height: "100%",
    fontSize: "9",
  },
  headerCell: {
    backgroundColor: "#2C4596",
    color: "#FFFFFF",
  },
  totalPrice: {
    marginTop: "8px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    width: "100%",
  },
  upperCase: {
    textTransform: "uppercase",
  },
  lineText: {
    display: "flex",
    flexDirection: "row",
  },
});

function CostPage(props) {
  const {
    quote,
    //lang
  } = props;

  const generateExpiryDate = () => {
    let localLocale = moment().add(30, "days");
    localLocale.locale("es");

    return localLocale.format("DD [de] MMMM [de] YYYY");
  };

  const generateDiscountHTML = () => {
    if (generateDiscount() > 0) {
      return (
        <View
          style={[
            {
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            },
          ]}

        >
          <Text
            style={[
              {
                fontSize: "11px",
              },
              styles.grayText,
            ]}
          >
            Descuento:
          </Text>
          <Text
            style={[
              {
                fontSize: "11px",
              },
              styles.bold,
              styles.upperCase,
              styles.grayText,
            ]}
          >
            -{formatter.format(parseInt(quote.discount, 10) / 100)}
          </Text>
        </View>
      );
    }
  };

  const generateDiscount = () => {
    if (quote.discount && parseFloat(quote.discount) > 0) {
      return parseFloat(quote.discount) / 100;
    }

    return 0;
  };

  const generateProductsPrice = () => {
    if (quote.quote_products) {
      let result = quote.quote_products.reduce((sumPrice, qp) => {
        return (sumPrice +=
          getProductPrice(qp.product, qp.quantity, quote.quote_products) *
          parseInt(qp.quantity, 10));
      }, 0);

      return result / 100;
    }

    return 0;
  };

  const generateFinalPrice = () => {
    let finalPrice = 0;
    if (quote.quote_products) {
      finalPrice += generateProductsPrice() - generateDiscount();
    }

    if (quote.allowance && quote.allowance > 0) {
      finalPrice += quote.allowance / 100;
    }

    return finalPrice;
  };

  const generateProductsPriceHtml = () => {
    return (
      <View
        style={[
          {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            paddingBottom: 24,
          },
        ]}
      >
        <Text
          style={[
            {
              fontSize: "11px",
            },
            styles.grayText,
          ]}
        >
          Subtotal
        </Text>
        <Text
          style={[
            {
              fontSize: "11px",
            },
            styles.bold,
            styles.upperCase,
            styles.blueText,
          ]}
        >
          {formatter.format(generateProductsPrice())}
        </Text>
      </View>
    );
  };

  const generateFinalPriceHtml = () => {
    return (
      <View
        style={[
          {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            paddingBottom: 24,
            paddingTop: 12,
          },
        ]}
      >
        <Text
          style={[
            {
              fontSize: "11px",
            },
            styles.grayText,
          ]}
        >
          TOTAL
        </Text>
        <Text
          style={[
            {
              fontSize: "20px",
            },
            styles.bold,
            styles.upperCase,
            styles.greenText,
          ]}
        >
          {formatter.format(generateFinalPrice())}
        </Text>
      </View>
    );
  };

  const generateRoiByYear = () => {
    let roiRows = [];

    let price = generateFinalPrice();

    let years =
      Math.round((price / (quote.luma_monthly_bill / 100) / 12) * 10) / 10;

    let costoRestante = generateFinalPrice();

    let anual = (quote.luma_monthly_bill / 100) * 12;

    for (let i = 0; i <= years + 1 && i <= 25; i++) {
      roiRows.push(
        <View style={[styles.tableRow]} key={i + ""}>
          <Text style={[styles.cell]}>{i}</Text>
          <Text style={[styles.cell]}>{formatter.format(anual * i)}</Text>
          <Text
            style={[
              styles.cell,
              costoRestante * -1 < 0 ? styles.redText : styles.greenText,
            ]}
          >
            {formatter.format(costoRestante * -1)}
          </Text>
        </View>
      );

      costoRestante -= anual;
    }

    return roiRows;
  };

  return (
    <Page size="A4" style={styles.page}>
      {/* Page Container */}
      <View
        style={{
          width: "100%",
          display: "flex",
          // border: "1px solid #ccc",
          boxSizing: "border-box",
          justifyContent: "space-between",
          height: "100%",
        }}
      >
        {/* Header */}
        <View
          style={[
            {
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
              flexDirection: "row",
              alignItems: "center",
              backgroundColor: "#1D4091",
              color: "#fff",
              padding: "20 16 20 16",
              borderBottom: "4px solid #0E9CD5",
              marginBottom: 0,
              fontSize: 14,
              height: "72px",
            },
            styles.upperCase,
          ]}
        >
          {/* Page title */}
          {/* <Text>Cotización Comercial</Text> */}
          <Image
            style={{
              height: "28px",
            }}
            src={`/assets/commercial quotes images/powersolar-logo-white.png`}
          />
        </View>
        {/* Page Content */}

        <View
          style={{
            padding: 48,
            display: "flex",
            flexDirection: "column",
            height: "100%",
            justifyContent: "space-between",
          }}
        >
          <View
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
            }}
          >
            {/* Page Title */}
            <View>
              <View
                style={[
                  {
                    fontSize: "24px",
                    marginBottom: 24,
                    textAlign: "left",
                  },
                  styles.bold,
                  styles.upperCase,
                  styles.grayText,
                ]}
              >
                <Text>COSTO TOTAL DEL PROYECTO</Text>
              </View>
              {generateDiscount() > 0 && (
                <View
                  style={{
                    paddingBottom: 24,
                    borderBottom: "1px solid light-gray",
                  }}
                >
                  {/* SubTotal */}
                  {generateProductsPriceHtml()}

                  {/* Discount */}
                  {generateDiscountHTML()}
                </View>
              )}

              {/* Total */}
              {generateFinalPriceHtml()}
            </View>

            {/* ROI Section */}
            <View
              style={{
                width: "100%",
                display: "flex",
              }}
            >
              {/* ROI Text */}
              <Text
                style={[
                  {
                    fontSize: "12px",
                    paddingBottom: "8px",
                  },
                  styles.orangeText,
                  styles.bold,
                  styles.upperCase,
                ]}
              >
                RETORNO DE INVERSIóN (ROI)
              </Text>

              {/* ROI Table */}
              <View style={[styles.table]}>
                <View style={[styles.tableRow]}>
                  <Text
                    style={[
                      styles.cell,
                      styles.headerCell,
                      styles.upperCase,
                      styles.bold,
                    ]}
                  >
                    Año
                  </Text>
                  <Text
                    style={[
                      styles.cell,
                      styles.headerCell,
                      styles.upperCase,
                      styles.bold,
                    ]}
                  >
                    Ahorro Anual
                  </Text>
                  <Text
                    style={[
                      styles.cell,
                      styles.headerCell,
                      styles.upperCase,
                      styles.bold,
                    ]}
                  >
                    ROI
                  </Text>
                </View>
                {generateRoiByYear()}
              </View>
            </View>
          </View>
          <View />

          {/* Side Note */}
          <View
            style={[
              {
                marginTop: "8px",
              },
            ]}
          >
            <Text
              style={[
                {
                  fontSize: "12px",
                  paddingBottom: "2px",
                },
                styles.blueText,
                styles.bold,
              ]}
            >
              Nota Aclaratoria
            </Text>
            <Text
              style={[
                {
                  fontSize: "12px",
                },
              ]}
            >
              El precio propuesto estará vigente hasta {generateExpiryDate()}.
            </Text>
          </View>
        </View>
      </View>
    </Page>
  );
}

export default CostPage;
