import React from "react";
import { Page, Text, View, StyleSheet, Image } from "@react-pdf/renderer";
// import text from "./lang";

const styles = StyleSheet.create({
  page: {
    // flexDirection: "row",
    backgroundColor: "#FFF",
    boxSizing: "border-box",
    fontSize: 10,
    padding: 0,
    fontFamily: "Poppins",
  },
  header: {
    width: "100%",
    display: "flex",
    alignSelf: "center",
    backgroundColor: "#1D4091",
    color: "#fff",
    padding: "16 16 8 16",
    marginBottom: 16,
    borderBottom: "solid 3px #0E9CD5",
  },
  headerText: {
    fontSize: 14,
    color: "#1f497d",
    margin: "0 10",
  },
  bodyText: {
    fontSize: "10px",
    color: "#343a40",
    margin: "0",
    lineHeight: "1.5px",
  },
  secondaryHeader: {
    fontSize: 16,
    margin: "8 0",
  },
  flex: {
    display: "flex",
  },
  bold: {
    fontFamily: "PoppinsBold",
  },
  italic: {
    fontFamily: "PoppinsItalic",
  },
  justifiedRight: {
    textAlign: "right",
  },
  orangeText: {
    color: "#E9682A",
  },
  grayText: {
    color: "#343a40",
  },

  blueText: {
    color: "#2C4596",
  },

  table: {
    width: "100%",
  },
  tableRow: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-around",
  },
  cell: {
    paddingTop: "5",
    paddingBottom: "3",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    border: "1 solid #ccc",
    width: "33.333333%",
    height: "100%",
    fontSize: "9",
  },
  headerCell: {
    backgroundColor: "#E9682A",
    color: "#FFFFFF",
  },
  totalPrice: {
    marginTop: "8px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    width: "100%",
  },
  upperCase: {
    textTransform: "uppercase",
  },
  lineText: {
    display: "flex",
    flexDirection: "row",
  },
});

function AgreementPageThree() {
  return (
    <Page size="A4" style={styles.page}>
      {/* Page Container */}
      <View
        style={{
          width: "100%",
          display: "flex",
          // border: "1px solid #ccc",
          boxSizing: "border-box",
          justifyContent: "space-between",
          height: "100%",
        }}
      >
        {/* Header */}
        <View
          style={[
            {
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
              flexDirection: "row",
              alignItems: "center",
              backgroundColor: "#1D4091",
              color: "#fff",
              padding: "20 16 20 16",
              borderBottom: "4px solid #0E9CD5",
              marginBottom: 0,
              fontSize: 14,
              height: "72px",
            },
            styles.upperCase,
          ]}
        >
          {/* Page title */}
          {/* <Text>Cotización Comercial</Text> */}
          <Image
            style={{
              height: "28px",
            }}
            src={`/assets/commercial quotes images/powersolar-logo-white.png`}
          />
        </View>

        {/* Page Content */}
        <View
          style={[
            {
              padding: "48 48 24 48",
              display: "flex",
              flexDirection: "column",
              height: "100%",
            },
            styles.bodyText,
          ]}
        >
          <Text style={[{ marginBottom: 16 }]}>
            Esta garantía quedará nula si alguna alteración o reparación al
            techo, trabajos subsiguientes al techo o cambios al uso del edificio
            son hechos sin la aprobación escrita y supervisión de Power Solar,
            LLC. La garantía no cubre perforaciones al producto o membrana para
            montura de equipos tales como y,sin limitarse a, acondicionadores de
            aire, calentadoressolares, cisternas, generadores, antenas etc.
          </Text>

          <Text style={[{ marginBottom: 16 }]}>
            Reparaciones provocadas por daños causados por terceros tendrán un
            costo adicional y no están cubiertas por esta garantía. Power Solar,
            LLC no se hace responsable en la garantía por la intervención de
            otra mano de obra para correcciones de los trabajos realizados.
          </Text>

          <Text style={[{ marginBottom: 16 }]}>
            El dueño del edificio debe asegurar que el sistema de membrana
            instalado y las áreas circundantes se mantengan tratadas con el
            debido cuidado y no sean dañadas por tráfico del área, la
            instalación de equipos en el área y/o cualquier cambio en el uso de
            la estructura.
          </Text>

          <Text style={[{ marginBottom: 16 }]}>
            El dueño del edificio debe proveer libre acceso al techo, en horas
            laborables, durante el término vigente de esta garantía al personal
            de Power Solar, LLC o su representante.
          </Text>

          <Text style={[{ marginBottom: 16 }]}>
            Power Solar, LLC no tendrá la obligación de honrar esta garantía
            hasta que todas las facturas por concepto de materiales, instalación
            o servicios relacionados con este contrato queden saldas en su
            totalidad.
          </Text>

          <Text style={[{ marginBottom: 16 }]}>
            El único propósito de esta garantía es que el sistema de
            impermeabilización instalado en el techo permanezca impermeable, por
            lo tanto, Power Solar, LLC no se hace responsable por daños a otros
            componentes del techo, la estructura del edificio o su contenido.
          </Text>

          <Text style={[{ marginBottom: 16 }]}>
            No hay ninguna otra garantía que se extienda más allá de lo aquí
            descrito. Power Solar, LLC no será responsable por ningún daño
            incidental o consecuente como resultado de la violación de la
            garantía.
          </Text>

          <Text style={[{ marginBottom: 16 }]}>
            Sera necesario la realización de un trabajo de mantenimiento general
            cada 2 años de haber realizado la instalación de la
            impermeabilización.
          </Text>

          <Text style={[{ marginBottom: 16 }]}>
            Ningún representante de Power Solar, LLC tiene autoridad para hacer
            presentaciones o promesas que no sean las que ya se han estipulado
            aquí.
          </Text>

          <View
            style={[
              {
                marginBottom: 16,
                borderTop: "1px solid gray",
                paddingTop: 4,
              },
              styles.upperCase,
            ]}
          >
            <Text>
              ESTA GARANTÍA SUSTITUYE EXPRESAMENTE CUALQUIER OTRA GARANTÍA, ORAL
              O ESCRITA, EXPLÍCITA O IMPLÍCITA, INCLUYENDO CUALQUIER GARANTÍA DE
              COMERCIABILIDAD O IDONEIDAD, O GARANTÍAS REALIZADAS POR TERCEROS.
              EN NINGÚN CASO POWER SOLAR, LLC. SERÁ RESPONSABLE DE CUALQUIER
              DAÑO A LA PROPIEDAD; POR MUERTE O LESIONES CORPORALES A CUALQUIER
              PERSONA; O POR CUALQUIER DAÑO CONSECUENTE O INCIDENTAL.
            </Text>
          </View>

          <Text
            style={[
              {
                marginBottom: 16,
                textAlign: "center",
              },
              styles.bold,
              styles.upperCase,
            ]}
          >
            ESTA GARANTÍA NO ES TRANSFERIBLE
          </Text>

          <View
            style={[
              {
                display: "flex",
                flexDirection: "row",
                width: "100%",
                justifyContent: "space-between",
                textAlign: "center",
              },
              // styles.upperCase,
            ]}
          >
            <View
              style={[
                {
                  marginBottom: 16,
                  marginTop: 16,
                  borderTop: "1px solid gray",
                  width: "40%",
                  paddingTop: 4,
                },
                // styles.upperCase,
              ]}
            >
              <Text>Firma del COMPRADOR</Text>
            </View>

            <View
              style={[
                {
                  marginBottom: 16,
                  marginTop: 16,
                  borderTop: "1px solid gray",
                  width: "40%",
                  paddingTop: 4,
                },
                // styles.upperCase,
              ]}
            >
              <Text>Firma Consultor Power Solar, LLC</Text>
            </View>
          </View>
        </View>

        {/* Power Solar Contact Info */}
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: "100%",
            backgroundColor: "#F2F2F2",
            padding: "16px",
            fontSize: 10,
            color: "#2C4596",
          }}
        >
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <Image
              style={{
                height: "14px",
                display: "flex",
                flexDirection: "row",
                marginRight: 4,
                padding: 2,
              }}
              src="/assets/icons8-phone-64-blue.png"
            />
            <Text style={[styles.whiteSmallText]}>787.331.100</Text>
          </View>

          <View
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <Image
              style={{
                height: "14px",
                display: "flex",
                flexDirection: "row",
                marginRight: 4,
                padding: 2,
              }}
              src="/assets/icons8-website-64-blue.png"
            />
            <Text style={[styles.whiteSmallText]}>powersolarpr.com</Text>
          </View>

          <View
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <Image
              style={{
                height: "14px",
                width: "auto",
                display: "flex",
                flexDirection: "row",
                marginRight: 4,
                padding: 2,
              }}
              src="/assets/icons8-facebook-64-blue.png"
            />
            <Text style={[styles.whiteSmallText]}>PowerSolarPR</Text>
          </View>

          <View
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <Image
              style={{
                height: "14px",
                display: "flex",
                flexDirection: "row",
                marginRight: 4,
                padding: 2,
              }}
              src="/assets/icons8-location-64-blue.png"
            />
            <Text style={[styles.whiteSmallText]}>
              279 calle César L. Gónzalez • San Juan, P.R. 00918
            </Text>
          </View>
        </View>
      </View>
    </Page>
  );
}

export default AgreementPageThree;
