import React from "react";
import { Page, Text, View, StyleSheet, Image, Font } from "@react-pdf/renderer";
import { newLanguages as text } from "./lang";
import PoppinsRegular from "../../assets/fonts/Poppins-Regular.ttf";
import PoppinsBold from "../../assets/fonts/Poppins-Bold.ttf";
import PoppinsBoldItalic from "../../assets/fonts/Poppins-BoldItalic.ttf";

Font.register({
  family: "Poppins",
  format: "truetype",
  src: PoppinsRegular,
});

Font.register({
  family: "PoppinsBold",
  format: "truetype",
  src: PoppinsBold,
});

Font.register({
  family: "PoppinsBoldItalic",
  format: "truetype",
  src: PoppinsBoldItalic,
});

const styles = StyleSheet.create({
  page: {
    backgroundColor: "#FFF",
    boxSizing: "border-box",
    fontSize: 11,
    fontFamily: "Poppins",
  },
  header: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
  },
  headerText: {
    fontSize: 24,
    color: "#1f497d",
    margin: "0 10",
    fontFamily: "PoppinsBold",
  },
  secondaryHeader: {
    fontSize: 15,
    margin: "8 0",
    fontFamily: "PoppinsBold",
    color: "#43A7DF",
  },
  bodyText: {
    fontSize: 11,
    color: "#343a40",
    margin: "0 10",
  },
  flex: {
    display: "flex",
  },
  bold: {
    fontFamily: "PoppinsBold",
  },
  boldItalic: {
    fontFamily: "PoppinsBoldItalic",
  },
  justifiedRight: {
    textAlign: "right",
  },
  upperCase: {
    textTransform: "uppercase",
  },
  orangeText: {
    color: "#E9682A",
  },
  grayText: {
    color: "#343a40",
  },
  whiteText: {
    color: "#FFFFFF",
  },
  blueText: {
    color: "#2C4596",
  },
  lineText: {
    fontSize: "15px",
  },
  marginSpacer: {
    marginTop: "6px",
    marginBottom: "4px",
  },
});

export default function CertificationsAndProceduresPage(props) {
  const { lang } = props;

  return (
    <Page size="A4" style={styles.page}>
      {/* Page Container */}
      <View
        style={{
          width: "100%",
          display: "flex",
          border: "1px solid #ccc",
          padding: "48 0 0 0",
          boxSizing: "border-box",
          justifyContent: "space-between",
          alignItems: "center",
          height: "100%",
        }}
      >
        {/* Page title  */}
        <View
          style={[
            styles.headerText,
            styles.upperCase,
            { width: "100%", textAlign: "center" },
          ]}
        >
          <Text
            style={[
              styles.blueText,
              styles.bold,
              { fontSize: 28, width: "100%", textAlign: "center" },
            ]}
          >
            {text[lang].certificationsTitle}
          </Text>
        </View>

        {/* Top Half */}
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            width: "100%",
            padding: "0px 48px",
          }}
        >
          {/*Column 1 */}
          <View
            style={{
              display: "flex",
              flexDirection: "column",
              width: "50%",
              paddingRight: 12,
            }}
          >
            <View
              style={[
                styles.lineText,
                styles.orangeText,
                styles.bold,
                { marginBottom: 16 },
              ]}
            >
              <Text>• {text[lang].certificationsOne}</Text>
            </View>

            <View
              style={[
                styles.lineText,
                styles.orangeText,
                styles.bold,
                { marginBottom: 16 },
              ]}
            >
              <Text>• {text[lang].certificationsTwo}</Text>
            </View>

            <View
              style={[
                styles.lineText,
                styles.orangeText,
                styles.bold,
                { marginBottom: 16 },
              ]}
            >
              <Text>• {text[lang].certificationsThree}</Text>
            </View>
          </View>

          {/*Column 2 */}
          <View
            style={{
              display: "flex",
              flexDirection: "column",
              width: "50%",
              paddingLeft: 12,
            }}
          >
            <View
              style={[
                styles.lineText,
                styles.orangeText,
                styles.bold,
                { marginBottom: 16 },
              ]}
            >
              <Text>• {text[lang].certificationsFour}</Text>
            </View>

            <View
              style={[
                styles.lineText,
                styles.orangeText,
                styles.bold,
                { marginBottom: 16 },
              ]}
            >
              <Text>• {text[lang].certificationsFive}</Text>
            </View>
          </View>
        </View>

        <View>
          {/* Page title 2  */}
          <View
            style={[
              styles.headerText,
              styles.upperCase,
              { width: "100%", textAlign: "center" },
            ]}
          >
            <Text
              style={[
                styles.blueText,
                styles.bold,
                { fontSize: 28, width: "100%", textAlign: "center" },
              ]}
            >
              {text[lang].proceduresTitle}
            </Text>
          </View>

          {/* Bottom half */}
          <View
            style={[
              styles.marginSpacer,
              {
                display: "flex",
                flexDirection: "column",
                padding: "0px 48px",
              },
            ]}
          >
            {/* Row 1 */}
            <View
              style={[
                {
                  display: "flex",
                  flexDirection: "row",
                },
                styles.marginSpacer,
              ]}
            >
              {/* Columna 1 */}
              <View
                style={[
                  {
                    display: "flex",
                    flexDirection: "column",
                    paddingRight: 16,
                    width: "50%",
                  },
                ]}
              >
                <Text style={[styles.secondaryHeader, { marginBottom: 8 }]}>
                  {text[lang].proceduresTitleOne}
                </Text>
                <Text
                  style={[
                    {
                      // overflowWrap: "break-word",
                      // hyphens: "none",
                      // wordWrap: "break-word",
                    },
                  ]}
                >
                  {text[lang].proceduresParagraphOne}
                </Text>
              </View>

              {/* Columna 2 */}
              <View
                style={[
                  {
                    display: "flex",
                    flexDirection: "column",
                    paddingLeft: "12px",
                    width: "50%",
                  },
                ]}
              >
                <Text style={[styles.secondaryHeader, { marginBottom: 8 }]}>
                  {text[lang].proceduresTitleTwo}
                </Text>
                <Text>{text[lang].proceduresParagraphTwo}</Text>
              </View>
            </View>

            {/* Row 2 */}
            <View
              style={[
                {
                  display: "flex",
                  flexDirection: "row",
                },
                styles.marginSpacer,
              ]}
            >
              {/* Columna 1 */}
              <View
                style={[
                  {
                    display: "flex",
                    flexDirection: "column",
                    paddingRight: 12,
                    width: "50%",
                  },
                ]}
              >
                <Text style={[styles.secondaryHeader, { marginBottom: 8 }]}>
                  {text[lang].proceduresTitleThree}
                </Text>
                <Text
                  style={[
                    {
                      // overflowWrap: "break-word",
                      // hyphens: "none",
                      // wordWrap: "break-word",
                    },
                  ]}
                >
                  {text[lang].proceduresParagraphThree}
                </Text>
              </View>

              {/* Columna 2 */}
              <View
                style={[
                  {
                    display: "flex",
                    flexDirection: "column",
                    paddingLeft: 12,
                    width: "50%",
                  },
                ]}
              >
                <Text style={[styles.secondaryHeader, { marginBottom: 8 }]}>
                  {text[lang].proceduresTitleFour}
                </Text>
                <Text>{text[lang].proceduresParagraphFour}</Text>
              </View>
            </View>
          </View>

          {/* Nota adicional  */}
          <View
            style={[
              {
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
                minHeight: "140px",
                padding: "0px 48px",
                paddingBottom: "48px",
              },
              styles.marginSpacer,
            ]}
          >
            <Text style={[{ marginBottom: 8 }, styles.orangeText, styles.bold]}>
              {text[lang].certificationsNoteHeader}
            </Text>
            <Text>{text[lang].certificationsNoteParagraph}</Text>
          </View>
        </View>

        {/* Footer */}
        <Image
          style={{
            width: "100%",
            display: "flex",
            alignSelf: "center",
          }}
          src={`/assets/bottom-triangles-border.png`}
        />
      </View>
    </Page>
  );
}
