import React from "react";
import { Form, Input, Select } from "antd";
import moment from "moment";
import roles from "../../../api/data/roles";

const { Option } = Select;
function EditUserForm(props) {
  const { handleSubmit, id, created_at, name, email, role, form, authUser } =
    props;

  const generateRoleOptions = () => {
    let roleList = [...roles];
    if (authUser.role !== "admin") {
      roleList = roleList.filter((role) => role !== "admin");
    }
    return roleList.map((role, i) => (
      <Option key={i} value={role}>
        {role}
      </Option>
    ));
  };

  return (
    <Form name="editUser" form={form} onFinish={handleSubmit} layout="vertical">
      <p>ID: {id}</p>
      <p>Created at: {moment(created_at).format("DD/MMM/YYYY  hh:mm a")}</p>
      <Form.Item
        name="name"
        rules={[{ message: "Please input user full name!", required: true }]}
        initialValue={name ? name : ""}
        label="Full Name"
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="email"
        rules={[{ message: "Please input user email!", required: true }]}
        initialValue={email ? email : ""}
        label="Email"
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="role"
        initialValue={role ? role : ""}
        label="Role"
        rules={[{ message: "Please input user role!", required: true }]}
      >
        <Select style={{ minWidth: "300px" }}>
          <Option value="">Roles</Option>
          {generateRoleOptions()}
        </Select>
      </Form.Item>
    </Form>
  );
}

export default EditUserForm;
