import React from "react";
import { Modal } from "./antd-modals";
import { connect } from "react-redux";
import { updateUserAction } from "../../redux/actions/userActions";

function EnableUserModal(props) {
  const { user, open, setOpen, updateUser } = props;

  const handleSubmit = () => {
    if (user && user.id) {
      updateUser(user.id, { is_enabled: !user.is_enabled }).then(
        (result) => {
          if (result && result.id) {
            setOpen(false);
          }
        }
      );
    }
  };

  return (
    <Modal
      title={
        user && user.is_enabled ? "Disable User" : "Enable User"
      }
      visible={open}
      onOk={() => handleSubmit()}
      onCancel={() => setOpen(false)}
      type="primary"
      footer={false}
    >
      <p>
        Are you sure you wish to {user && user.is_enabled ? "Disable" : "Enable"}{" "}
        this user?
      </p>
    </Modal>
  );
}

let mapDispatchToProps = (dispatch) => ({
  updateUser: (id, formData) => dispatch(updateUserAction(id, formData)),
});

export default connect(null, mapDispatchToProps)(EnableUserModal);
