import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Auth from "./Auth";
import Application from "./Application";
import AdminRoutes from "./AdminRoutes";
import AdminRolesRoutes from "./AdminRolesRoutes";
import Main from "../../api/Main";
// import DashboardLayout from '../Layout/DashboardLayout';
import { connect } from "react-redux";
// import DisabledUserError from '../Errors/DisabledUserError';
// import IdleTimer from '../../components/IdleTimer/IdleTimer';
import ThemeLayout from "../Layout/DashboardLayout";
import { adminRoles } from "../../api/data/roles";

function App(props) {
  const { currentUser } = props;

  const isAuthenticated = () => !!(currentUser && currentUser.id);
  // const isEnabled = () => !!(currentUser && currentUser.is_enabled)
  const isEnabled = () => true;
  const isAdmin = () => !!(currentUser && currentUser.role === "admin");
  const isAdminRole = () =>
    !!(currentUser && adminRoles.includes(currentUser.role));

  return (
    <>
      <Main>
        {/* {isAuthenticated() && <IdleTimer />} */}
        <Routes>
          {isAuthenticated && isAdmin() && (
            <Route path="/app" element={<ThemeLayout />}>
              {AdminRoutes}
            </Route>
          )}
          {isAuthenticated && isAdminRole() && (
            <Route path="/app" element={<ThemeLayout />}>
              {AdminRolesRoutes}
            </Route>
          )}
          {isAuthenticated() && isEnabled() && (
            <Route path="/app" element={<ThemeLayout />}>
              {Application}
            </Route>
          )}
          {/* {isAuthenticated() && isEnabled() && (
                        <Route path='/app'>
                            {Application}
                        </Route>
                    )} */}

          {!isAuthenticated() && Auth}
          {!isAuthenticated() && (
            <Route path="/app*" element={<Navigate replace to="/sign-in" />} />
          )}
          {!isAuthenticated() && (
            <Route path="/" element={<Navigate replace to="/sign-in" />} />
          )}
          {isAuthenticated() && (
            <Route
              path="/*"
              element={<Navigate replace to="/app" />}
            />
          )}
          {/* {isAuthenticated() && !isEnabled() && (<Route path="*" element={<DisabledUserError />} />)} */}
        </Routes>
      </Main>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.auth.currentUser,
  };
};

export default connect(mapStateToProps)(App);
