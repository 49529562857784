import React from "react";
import { BasicFormWrapper } from "../../../../containers/styled";
import Heading from "../../../heading/heading";
import { Row, Col, Form, Input } from "antd";

function ViviendaInformationForm(props) {
  const { form, option } = props;

  return (
    <BasicFormWrapper className="basic-form-inner">
      <div className="atbd-form-checkout">
        <Row justify="center">
          <Col sm={22} xs={24}>
            <div className="shipping-form">
              <Heading as="h4">2. Información {option}</Heading>
              <Form form={form} name="address">
                <Form.Item name="ticket_number" label="Número de Boleto">
                  <Input placeholder="" />
                </Form.Item>
                <Form.Item
                  name="annual_client_consumption"
                  label="Suma de Factura kWh (12 meses)"
                  // eslint-disable-next-line react/jsx-no-duplicate-props
                  rules={[
                    { required: true, message: "Suma de factura es requerido" },
                  ]}
                >
                  <Input placeholder="" />
                </Form.Item>
                <Form.Item
                  name="annual_client_consumption_capacity"
                  label="Producción de PVWatts anual kW"
                >
                  <Input placeholder="" />
                </Form.Item>
                <Form.Item name="sunnova_id" label="Sunnova/PS ID">
                  <Input placeholder="" />
                </Form.Item>
              </Form>
            </div>
          </Col>
        </Row>
      </div>
    </BasicFormWrapper>
  );
}

export default ViviendaInformationForm;
