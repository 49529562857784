import React from "react";
import { Page, Text, View, StyleSheet, Image, Font } from "@react-pdf/renderer";
import "moment/locale/es";
import PoppinsRegular from "../../assets/fonts/Poppins-Regular.ttf";
import PoppinsBold from "../../assets/fonts/Poppins-Bold.ttf";
import ZeyadaRegular from "../../assets/fonts/Zeyada-Regular.ttf";
import PoppinsBoldItalic from "../../assets/fonts/Poppins-BoldItalic.ttf";

Font.register({
  family: "Poppins",
  format: "truetype",
  src: PoppinsRegular,
});

Font.register({
  family: "PoppinsBold",
  format: "truetype",
  src: PoppinsBold,
});

Font.register({
  family: "Zeyada",
  format: "truetype",
  src: ZeyadaRegular,
});

Font.register({
  family: "PoppinsBoldItalic",
  format: "truetype",
  src: PoppinsBoldItalic,
});

const styles = StyleSheet.create({
  page: {
    // flexDirection: "row",
    // backgroundColor: "#FFF",
    boxSizing: "border-box",
    fontSize: 10,
    // padding: 24,
    fontFamily: "Poppins",
  },
  header: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
  },
  headerText: {
    fontSize: 12,
    color: "#1f497d",
    margin: "0 10",
  },
  bodyText: {
    fontSize: 10,
    color: "#343a40",
    // margin: "0 10",
  },
  secondaryHeader: {
    fontSize: 10,
    margin: "8 0",
  },
  flex: {
    display: "flex",
  },
  bold: {
    fontFamily: "PoppinsBold",
  },
  boldItalic: {
    fontFamily: "PoppinsBoldItalic",
  },
  justifiedRight: {
    textAlign: "right",
  },
  orangeText: {
    color: "#E9682A",
  },
  grayText: {
    color: "#343a40",
  },
  whiteText: {
    color: "#FFFFFF",
  },
  ecoflowAccent: {
    color: "#26BAB1",
  },
  ecoflowAccentDark: {
    color: "#227D78",
  },
  ecoflowGrayBackground: {
    backgroundColor: "#3B3B3B",
  },
  ecoflowAccentBackground: {
    backgroundColor: "#26BAB1",
  },
  ecoflowAccentDarkBackground: {
    backgroundColor: "#227D78",
  },
  table: {
    width: "100%",
    borderRight: "1px solid #FFF",
  },
  tableRow: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-around",
  },
  cell: {
    paddingTop: "12",
    paddingBottom: "10",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    borderLeft: "1px solid #E6E6E6",
    borderBottom: "1px solid #E6E6E6",
    width: "33.333333%",
    height: "100%",
    fontSize: "10",
    backgroundColor: "#E6E6E6",
  },
  cell100: {
    paddingTop: "12",
    paddingBottom: "10",
    paddingLeft: "8",
    paddingRight: "8",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    borderLeft: "1px solid #E6E6E6",
    borderBottom: "1px solid #E6E6E6",
    width: "100%",
    height: "100%",
    fontSize: "10",
    backgroundColor: "#E6E6E6",
  },
  cell15: {
    paddingTop: "12",
    paddingBottom: "10",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    borderLeft: "1px solid #E6E6E6",
    borderBottom: "1px solid #E6E6E6",
    width: "15%",
    height: "100%",
    fontSize: "10",
    backgroundColor: "#E6E6E6",
  },
  headerCell: {
    backgroundColor: "#26BAB1",
    color: "#FFFFFF",
    border: "1px solid #26BAB1",
  },
  totalPrice: {
    marginTop: "8px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    width: "100%",
  },
  upperCase: {
    textTransform: "uppercase",
  },
  lineText: {
    display: "flex",
    flexDirection: "row",
  },
  boxedText: {
    padding: "4px 8px 2px 8px",
    borderRadius: "3px",
    border: "1px solid #dcdcdc",
    width: "100%",
  },
  marginSpacer: {
    marginTop: "6px",
    marginBottom: "4px",
  },
  backgroundImage: {
    zIndex: -1,
    position: "absolute",
    minWidth: "100%",
    minHeight: "100%",
    display: "block",
    height: "100%",
    width: "100%",
    objectFit: "cover",
  },
});

export default function CoverPage(props) {
  return (
    <Page size="A4" style={styles.page}>
      {/* Page Container */}
      <View
        style={{
          width: "100%",
          display: "flex",
          boxSizing: "border-box",
          justifyContent: "space-between",
          height: "100%",
          padding: "0px",
          color: "#343434",
          backgroundColor: "#010101",
        }}
      >
        <View
          style={{
            width: "100%",
            display: "flex",
            boxSizing: "border-box",
            justifyContent: "space-between",
            height: "100%",
            padding: "0px",
            color: "#343434",
            backgroundColor: "#010101",
          }}
        >
          <View
            style={{
              width: "100%",
              display: "flex",
              boxSizing: "border-box",
              justifyContent: "flex-end",
              height: "100%",
              padding: "0px",
              color: "#343434",
              marginLeft: "24px",
              // backgroundColor: "#000",
            }}
          >
            <Text
              style={[
                styles.ecoflowAccent,
                { fontSize: "32px", fontFamily: "Poppins", lineHeight: "0.75" },
              ]}
            >
              {" "}
              COTIZACIÓN
            </Text>
            <Image
              style={{ height: "81px", width: "388px", marginBottom: "72px" }}
              src="/assets/ecoflow/ecoflow-logo-glow.png"
            />
            <Image
              style={{ width: "340px", height: "156px", marginBottom: "64x" }}
              src="/assets/ecoflow/powered-ps-logo.png"
            />
          </View>
          <Image
            src="/assets/ecoflow/portada-ecoflow-bg-image.png"
            style={styles.backgroundImage}
          />
        </View>
        {/* Contact Info Footer */}
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            width: "100%",
            backgroundColor: "#010101",
            color: "white",
            boxSizing: "content-box",
            height: "auto",
          }}
        >
          {/* Mas informacion */}
          <View
            style={{
              display: "flex",
              flexDirection: "column",
              padding: "16px 24px",
            }}
          >
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                marginBottom: "8px",
              }}
            >
              <Text
                style={[
                  styles.boldItalic,
                  styles.whiteText,
                  {
                    fontSize: "12px",
                  },
                ]}
              >
                Más{" "}
              </Text>
              <Text
                style={[
                  styles.boldItalic,
                  styles.ecoflowAccent,
                  {
                    fontSize: "12px",
                  },
                ]}
              >
                Información
              </Text>
            </View>

            {/* Contact icons */}
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
              }}
            >
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <Image
                  style={{
                    height: "12px",
                    display: "flex",
                    flexDirection: "row",
                    marginRight: "4px",
                  }}
                  src="/assets/commercial quotes images/phone-alt-white.png"
                />
                <Text style={[styles.whiteSmallText]}>787.331.1000</Text>
              </View>

              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  marginLeft: "12px",
                }}
              >
                <Image
                  style={{
                    height: "12px",
                    display: "flex",
                    flexDirection: "row",
                    marginRight: "4px",
                  }}
                  src="/assets/commercial quotes images/link-white.png"
                />
                <Text style={[styles.whiteSmallText]}>ecoflowpr.com</Text>
              </View>

              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  marginLeft: "12px",
                }}
              >
                <Image
                  style={{
                    height: "12px",
                    width: "auto",
                    display: "flex",
                    flexDirection: "row",
                    marginRight: "4px",
                  }}
                  src="/assets/commercial quotes images/facebook-white.png"
                />
                <Image
                  style={{
                    height: "12px",
                    width: "auto",
                    display: "flex",
                    flexDirection: "row",
                    marginRight: "4px",
                  }}
                  src="/assets/commercial quotes images/instagram-white.png"
                />
                <Text style={[styles.whiteSmallText]}>EcoflowPR</Text>
              </View>
            </View>
          </View>
        </View>
      </View>
    </Page>
  );
}
