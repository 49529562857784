import React from "react";
import { BasicFormWrapper } from "../../../../containers/styled";
import Heading from "../../../heading/heading";
import { Row, Col, Form, Radio } from "antd";

function FinanceMethodForm(props) {
  const { form } = props;

  return (
    <BasicFormWrapper className="basic-form-inner">
      <div className="atbd-form-checkout">
        <Row justify="center">
          <Col sm={22} xs={24}>
            <div className="shipping-form">
              <Heading as="h4">2. Please Select A Finance Method</Heading>
              <Form form={form} name="address">
                <Form.Item
                  name="finance_method"
                  initialValue=""
                  label="Method"
                  rules={[{ required: true, message: "Method is required" }]}
                >
                  <Radio.Group style={{ width: "100%" }}>
                    <Radio value="Cash - Cheque" style={{ width: "100%" }}>
                      <p>Cash - Cheque</p>
                    </Radio>
                    <Radio value="Cooperativa" style={{ width: "100%" }}>
                      <p>Cooperativa</p>
                    </Radio>
                    <Radio value="Tarjeta de Crédito" style={{ width: "100%" }}>
                      <p>Tarjeta de credito</p>
                    </Radio>
                    <Radio value="Banco Popular" style={{ width: "100%" }}>
                      <p>Banco popular</p>
                    </Radio>
                    <Radio value="Synchrony" style={{ width: "100%" }}>
                      <p>Synchrony (+$0.20 por W)</p>
                    </Radio>
                    <Radio value="United" style={{ width: "100%" }}>
                      <p>United (+10%)</p>
                    </Radio>
                  </Radio.Group>
                </Form.Item>
              </Form>
            </div>
          </Col>
        </Row>
      </div>
    </BasicFormWrapper>
  );
}

export default FinanceMethodForm;
