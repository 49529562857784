import React, { useEffect } from "react";
import { Modal } from "./antd-modals";
import EditUserForm from "../forms/UserForm/EditUserForm";
import { connect } from "react-redux";
import { Form } from "antd";
import { updateUserAction } from "../../redux/actions/userActions";

function UserFormModal(props) {
  const { user, open, setOpen, updateUser, authUser } = props;

  const [form] = Form.useForm();

  const handleSubmit = () => {
    let data = form.getFieldsValue(true);
    if (user && user.id) {
      updateUser(user.id, data).then((result) => {
        if (result && result.id) {
          setOpen(false);
        }
      });
    }
  };

  useEffect(() => {
    form.resetFields();
  }, [user, form]);

  return (
    <Modal
      title={user && user.id ? "Edit User" : "Create User"}
      visible={open}
      onOk={() => handleSubmit()}
      onCancel={() => setOpen(false)}
      type="primary"
      footer={false}
    >
      <EditUserForm {...user} form={form} authUser={authUser} />
    </Modal>
  );
}

let mapDispatchToProps = (dispatch) => ({
  updateUser: (id, formData) => dispatch(updateUserAction(id, formData)),
});

export default connect(null, mapDispatchToProps)(UserFormModal);
