import React from "react";
import { Page, Text, View, StyleSheet, Image, Font } from "@react-pdf/renderer";
import { newLanguages as text } from "./lang";
import PoppinsRegular from "../../assets/fonts/Poppins-Regular.ttf";
import PoppinsBold from "../../assets/fonts/Poppins-Bold.ttf";
import PoppinsBoldItalic from "../../assets/fonts/Poppins-BoldItalic.ttf";
import {
  getProductPrice,
  hasTeslaInverter,
  hasPanel455,
} from "../helpers/helper";
import { formatter } from "../../helpers/helper";
Font.register({
  family: "Poppins",
  format: "truetype",
  src: PoppinsRegular,
});

Font.register({
  family: "PoppinsBold",
  format: "truetype",
  src: PoppinsBold,
});

Font.register({
  family: "PoppinsBoldItalic",
  format: "truetype",
  src: PoppinsBoldItalic,
});

const styles = StyleSheet.create({
  page: {
    backgroundColor: "#FFF",
    boxSizing: "border-box",
    fontSize: 11,
    fontFamily: "Poppins",
  },
  header: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
  },
  headerText: {
    fontSize: 28,
    color: "#1f497d",
    margin: "0 10",
    fontFamily: "PoppinsBold",
  },
  secondaryHeader: {
    fontSize: 15,
    margin: "8 0",
    fontFamily: "PoppinsBold",
  },
  bodyText: {
    fontSize: 11,
    color: "#343a40",
    margin: "0 10",
  },
  flex: {
    display: "flex",
  },
  bold: {
    fontFamily: "PoppinsBold",
  },
  boldItalic: {
    fontFamily: "PoppinsBoldItalic",
  },
  justifiedRight: {
    textAlign: "right",
  },
  upperCase: {
    textTransform: "uppercase",
  },
  orangeText: {
    color: "#E9682A",
  },
  grayText: {
    color: "#343a40",
  },
  whiteText: {
    color: "#FFFFFF",
  },
  blueText: {
    color: "#2C4596",
  },
});

export default function PaymentPlanAndWarrantiesPage(props) {
  const { lang, quote } = props;

  const generateDiscount = () => {
    if (quote.discount && parseFloat(quote.discount) > 0) {
      return parseFloat(quote.discount / 100);
    }

    return 0;
  };

  const generateFinalPrice = () => {
    if (quote.quote_products) {
      let result = quote.quote_products.reduce((sumPrice, qp) => {
        return (sumPrice +=
          getProductPrice(
            qp.product,
            qp.quantity,
            quote.quote_products,
            quote.finance_method
          ) * parseInt(qp.quantity, 10));
      }, 0);

      // If financed through United, add 10% cost
      if (quote.finance_method === "United") {
        result += result * 0.1;
      }

      return result / 100 - generateDiscount();
    }

    return 0;
  };

  return (
    <Page size="A4" style={styles.page}>
      {/* Page Container */}
      <View
        style={{
          width: "100%",
          display: "flex",
          border: "1px solid #ccc",
          boxSizing: "border-box",
          justifyContent: "space-between",
          height: "100%",
        }}
      >
        <View
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            padding: "48px",
          }}
        >
          {/* header  */}
          <View
            style={[
              styles.headerText,
              styles.upperCase,
              {
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              },
            ]}
          >
            <Text
              style={[
                styles.blueText,
                styles.bold,
                { fontSize: 28, textAlign: "center" },
              ]}
            >
              {text[lang].paymentPlanTitleBlue}{" "}
            </Text>
            <Text
              style={[
                styles.orangeText,
                styles.bold,
                {
                  fontSize: 28,
                  textAlign: "center",
                },
              ]}
            >
              {text[lang].paymentPlanTitleOrange}
            </Text>
          </View>
          {/* 30, 50 20 % */}
          <View
            style={[
              {
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                textAlign: "left",
                width: "100%",
              },
            ]}
          >
            {/* 20% */}
            <View
              style={[
                {
                  display: "flex",
                  flexDirection: "column",
                  width: "28%",
                  margin: "16 0",
                },
              ]}
            >
              <Text
                style={[
                  styles.secondaryHeader,
                  styles.upperCase,
                  { marginBottom: 8, color: "#43A7DF" },
                ]}
              >
                20%
              </Text>
              <Text
                style={[styles.orangeText, { fontSize: "16", marginBottom: 8 }]}
              >
                ({formatter.format(generateFinalPrice() * 0.2)})
              </Text>
              <Text>{text[lang].paymentPlanFirstParagraph}</Text>
            </View>

            {/* 50% */}
            <View
              style={[
                {
                  display: "flex",
                  flexDirection: "column",
                  width: "28%",
                  margin: "16 0",
                },
              ]}
            >
              <Text
                style={[
                  styles.secondaryHeader,
                  styles.upperCase,
                  { marginBottom: 8, color: "#43A7DF" },
                ]}
              >
                50%
              </Text>
              <Text
                style={[styles.orangeText, { fontSize: "16", marginBottom: 8 }]}
              >
                ({formatter.format(generateFinalPrice() * 0.5)})
              </Text>
              <Text>{text[lang].paymentPlanSecondParagraph}</Text>
            </View>

            {/* 30% */}
            <View
              style={[
                {
                  display: "flex",
                  flexDirection: "column",
                  margin: "16 0",
                  width: "28%",
                },
              ]}
            >
              <Text
                style={[
                  styles.secondaryHeader,
                  styles.upperCase,
                  { marginBottom: 8, color: "#43A7DF" },
                ]}
              >
                30%
              </Text>
              <Text
                style={[styles.orangeText, { fontSize: "16", marginBottom: 8 }]}
              >
                ({formatter.format(generateFinalPrice() * 0.3)})
              </Text>
              <Text>{text[lang].paymentPlanThirdParagraph}</Text>
            </View>
          </View>
          {/* Side note */}
          <View>
            <Text>*{text[lang].paymentPlanWarning}</Text>
          </View>
          {/* ROI */}

          {quote.luma_monthly_bill > 0 && (
            <>
              <View
                style={[
                  styles.headerText,
                  styles.blueText,
                  styles.upperCase,
                  {
                    width: "100%",
                    textAlign: "center",
                    marginTop: "24px",
                    marginBottom: "12px",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                  },
                ]}
              >
                <Text>{text[lang].roiTitleBlue} </Text>
                <Text style={[styles.orangeText]}>
                  {text[lang].roiTitleOrange}
                </Text>
              </View>
              {/* ROI Content */}

              <Text
                style={[
                  styles.secondaryHeader,
                  styles.grayText,
                  { width: "100%", textAlign: "center" },
                ]}
              >
                {text[lang].returnOfInvestment(
                  formatter.format(generateFinalPrice() - generateDiscount()),
                  formatter.format(quote.luma_monthly_bill / 100),
                  Math.round(
                    ((generateFinalPrice() - generateDiscount()) /
                      (quote.luma_monthly_bill / 100) /
                      12) *
                      10
                  ) / 10
                )}
              </Text>
            </>
          )}
          {/*Garantias*/}
          <View
            style={[
              styles.headerText,
              styles.blueText,
              styles.upperCase,
              {
                width: "100%",
                textAlign: "center",
                marginTop: "24px",
                marginBottom: "12px",
              },
            ]}
          >
            <Text>{text[lang].warrantiesTitleBlue}</Text>
          </View>
          {/* Warranties Content */}
          <View
            style={[
              {
                display: "flex",
                flexDirection: "row",
                width: "100%",
                justifyContent: "space-between",
              },
            ]}
          >
            {/* Column 1 */}
            <View
              style={{
                display: "flex",
                flexDirection: "column",
                margin: "16 0",
                width: "28%",
              }}
            >
              <Text
                style={[styles.secondaryHeader, styles.orangeText, styles.bold]}
              >
                {text[lang].warranty1Header}
              </Text>
              <Text style={[]}>
                {text[lang].warranty1Text(hasPanel455(quote))}
              </Text>
              <Text> </Text>
              <Text
                style={[styles.secondaryHeader, styles.orangeText, styles.bold]}
              >
                {text[lang].warranty2Header(hasTeslaInverter(quote))}
              </Text>
              <Text style={[]}>
                {text[lang].warranty2Text(hasTeslaInverter(quote))}
              </Text>
            </View>

            {/* Column 2 */}
            <View
              style={{
                display: "flex",
                flexDirection: "column",
                margin: "16 0",
                width: "28%",
              }}
            >
              <Text
                style={[styles.secondaryHeader, styles.orangeText, styles.bold]}
              >
                {text[lang].warranty3Header}
              </Text>
              <Text style={[]}>{text[lang].warranty3Text}</Text>
              <Text> </Text>
              <Text
                style={[styles.secondaryHeader, styles.orangeText, styles.bold]}
              >
                {text[lang].warranty4Header}
              </Text>
              <Text style={[]}>{text[lang].warranty4Text}</Text>
            </View>

            {/* Column 3 */}
            <View
              style={{
                display: "flex",
                flexDirection: "column",
                margin: "16 0",
                width: "28%",
              }}
            >
              <Text
                style={[styles.secondaryHeader, styles.orangeText, styles.bold]}
              >
                {text[lang].warranty5Header}
              </Text>
              <Text style={[]}>{text[lang].warranty5Text(10)}</Text>
              <Text> </Text>
              <Text
                style={[styles.secondaryHeader, styles.orangeText, styles.bold]}
              >
                {text[lang].warranty6Header}
              </Text>
              <Text style={[]}>{text[lang].warranty6Text}</Text>
            </View>
          </View>
        </View>

        {/* Footer */}
        <Image
          style={{
            width: "100%",
            display: "flex",
            alignSelf: "center",
          }}
          src={`/assets/bottom-triangles-border.png`}
        />
      </View>
    </Page>
  );
}
