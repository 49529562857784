const costosNuevaEnergia2 = {
  8: {
    costoPaneles: 10560,
    costoBatería: 13200,
  },
  9: {
    costoPaneles: 11700,
    costoBatería: 13200,
  },
  10: {
    costoPaneles: 13000,
    costoBatería: 13200,
  },
  11: {
    costoPaneles: 14080,
    costoBatería: 13200,
  },
  12: {
    costoPaneles: 14400,
    costoBatería: 13200,
  },
  13: {
    costoPaneles: 15080,
    costoBatería: 13200,
  },
  14: {
    costoPaneles: 15680,
    costoBatería: 13200,
  },
  15: {
    costoPaneles: 16200,
    costoBatería: 12900,
  },
  16: {
    costoPaneles: 16640,
    costoBatería: 12600,
  },
  17: {
    costoPaneles: 17000,
    costoBatería: 12500,
  },
  18: {
    costoPaneles: 17280,
    costoBatería: 12500,
  },
};

const costoBatería = {
  1: 13200,
};

export { costosNuevaEnergia2, costoBatería };
