import React, { useState, useEffect } from "react";
import { BasicFormWrapper } from "../../../../containers/styled";
import Heading from "../../../heading/heading";
import { Cards } from "../../../cards/frame/cards-frame";
import { Link } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import { connect } from "react-redux";
import { formatter } from "../../../../helpers/helper";
import {
  costosNuevaEnergia2,
  costoBatería,
} from "../../../../api/data/costosNuevaEnergia2";

function ViviendaEstimateReview(props) {
  const { form, setStep } = props;

  const [formValues, setFormValues] = useState({});

  useEffect(() => {
    setFormValues({ ...formValues, ...form.getFieldsValue(true) });
    // eslint-disable-next-line
  }, [form]);

  const generateProducts = () => {
    let products = [];
    if (formValues && formValues.quantity) {
      if (formValues.product.includes("Paneles solares")) {
        products.push(
          <div>
            <hr style={{ borderTop: "1px solid #eeeeee" }} />
            <h3 className="orange-text">Panel Solar</h3>
            <li>
              <strong>Cantidad: </strong>
              {formValues.quantity}
            </li>
            <li>
              <strong>Precio Total:</strong>{" "}
              {formatter.format(
                costosNuevaEnergia2[formValues.quantity].costoPaneles
              )}
            </li>
          </div>
        );
      }

      if (formValues.product === "Paneles solares y baterías") {
        products.push(
          <div>
            <hr style={{ borderTop: "1px solid #eeeeee" }} />
            <h3 className="orange-text">Microinversor IQ8H</h3>
            <li>
              <strong>Cantidad: </strong>
              {formValues.quantity}
            </li>
            <li>
              <strong>Precio Total:</strong> {formatter.format(0)}
            </li>
          </div>
        );
      }

      products.push(
        <div>
          <hr style={{ borderTop: "1px solid #eeeeee" }} />
          <h3 className="orange-text">
            Tesla Powerwall{" "}
            {formValues.product === "Paneles solares y baterías +" ? "+" : "2"}
          </h3>
          <li>
            <strong>Cantidad: </strong>
            {formValues.product.includes("Paneles solares")
              ? 1
              : formValues["quantity"]}
          </li>
          <li>
            <strong>Precio Total:</strong>{" "}
            {formatter.format(
              formValues.product.includes("Paneles solares")
                ? costosNuevaEnergia2[formValues.quantity].costoBatería
                : costoBatería[formValues.quantity]
            )}
          </li>
        </div>
      );
    }

    return products;
  };

  const generateFinalPrice = () => {
    if (formValues && formValues.quantity) {
      if (formValues.product.includes("Paneles solares")) {
        return (
          costosNuevaEnergia2[formValues.quantity].costoBatería +
          costosNuevaEnergia2[formValues.quantity].costoPaneles
        );
      } else if (formValues.product === "Baterías") {
        return costoBatería[formValues.quantity];
      }
    }
    return 0;
  };

  return (
    <BasicFormWrapper style={{ width: "100%" }}>
      <div className="atbd-review-order" style={{ width: "100%" }}>
        <Heading as="h4">4. Revisa y Confirma la Cotización</Heading>
        <Cards
          bodyStyle={{ backgroundColor: "#F8F9FB", borderRadius: 10 }}
          headless
        >
          <div className="atbd-review-order__single">
            <Cards headless>
              <div className="atbd-review-order__shippingTitle">
                <Heading as="h5">
                  Información de Cliente
                  <Link to="#" onClick={() => setStep(0)}>
                    <FeatherIcon icon="edit" />
                    Editar
                  </Link>
                </Heading>
              </div>
              <article className="atbd-review-order__shippingInfo">
                <p>Nombre de Cliente: {formValues["customer_name"]}</p>
                <p>Dirección: {formValues["address_line"]}</p>
                <p>Municipio: {formValues["municipality"]}</p>
                <p>Latitud: {formValues["latitude"]}</p>
                <p>Longitud: -{formValues["longitude"]}</p>
              </article>
            </Cards>
          </div>
          <div className="atbd-review-order__single">
            <Cards headless>
              <div className="atbd-review-order__shippingTitle">
                <Heading as="h5">
                  Información Energía 2
                  <Link to="#" onClick={() => setStep(1)}>
                    <FeatherIcon icon="edit" />
                    Editar
                  </Link>
                </Heading>
              </div>
              {formValues["ticket_number"] && (
                <p>Número de Boleto: {formValues["ticket_number"]}</p>
              )}
              <p>
                Suma de Factura kWh (12 meses):{" "}
                {formValues["annual_client_consumption"]}
              </p>
              {formValues["annual_client_consumption_capacity"] && (
                <p>
                  Producción de PVWatts anual kW:{" "}
                  {formValues["annual_client_consumption_capacity"]}
                </p>
              )}
            </Cards>
          </div>
          <div className="atbd-review-order__single">
            <Cards headless>
              <div className="atbd-review-order__shippingTitle">
                <Heading as="h5">
                  Productos
                  <Link to="#" onClick={() => setStep(2)}>
                    <FeatherIcon icon="edit" />
                    Editar
                  </Link>
                </Heading>
              </div>
              {generateProducts()}
            </Cards>
          </div>

          <div className="atbd-review-order__single">
            <Cards headless>
              <div>
                <Heading as="h5">Resumen de Cotización para Vivienda </Heading>
              </div>
              <h2>
                Total: <strong>{formatter.format(generateFinalPrice())}</strong>
              </h2>
            </Cards>
          </div>
        </Cards>
      </div>
    </BasicFormWrapper>
  );
}

let mapStateToProps = (state) => ({
  products: state.products.products,
});

export default connect(mapStateToProps)(ViviendaEstimateReview);
