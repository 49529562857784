import React, { useState, useCallback } from "react";
import { connect } from "react-redux";
// eslint-disable-next-line import/no-extraneous-dependencies
import { AuthWrapper } from "./style";
import { resetPasswordAction } from "../../redux/actions/authActions";
import AuthLayout from "../../components/layouts/AuthLayout";
import NewPasswordForm from "../../components/forms/NewPasswordForm/NewPasswordForm";
import { useLocation } from "react-router-dom";

const NewPassword = (props) => {
  const { resetPassword, loading } = props;
  const location = useLocation();

  const [submitResponse, setSubmitResponse] = useState({});

  const submitForm = useCallback(
    (values) => {
      resetPassword({
        email: values.email,
        password: values.password,
        token:
          location && location.search && location.search.split("=").length > 0
            ? location.search.split("=")[1]
            : "",
      }).then((result) => {
        setSubmitResponse(result);
      });
    },
    [resetPassword, setSubmitResponse, location]
  );

  return (
    <AuthLayout>
      <AuthWrapper>
        <div className="auth-contents">
          <NewPasswordForm
            onSubmit={(values) => submitForm(values)}
            loading={loading}
            submitResponse={submitResponse}
            token={{}}
          />
        </div>
      </AuthWrapper>
    </AuthLayout>
  );
};

const mapStateToProps = (state) => ({
  loading: state.auth.loading,
});

const mapDispatchToProps = (dispatch) => ({
  resetPassword: (formValues) => dispatch(resetPasswordAction(formValues)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewPassword);
