import React from "react";
import FeatherIcon from "feather-icons-react";
import { Row } from "antd";
import { UserCard } from "./style";
import Heading from "../heading/heading";
import { Cards } from "../cards/frame/cards-frame";
import { Button } from "../buttons/buttons";
import { formatter } from "../../helpers/helper";

const ProductCard = (props) => {
  const {
    id,
    name,
    category,
    subcategory,
    description,
    price,
    datasheet_name,
    is_enabled,
    // open,
    setOpenModal,
    setOpenEnableModal,
  } = props;

  return (
    <UserCard>
      <div className="card user-card">
        <Cards headless>
          <div className="card__content">
            <Heading className="card__name, body-text-large" as="h3">
              {name}
            </Heading>
            <p style={{ fontWeight: "bold" }}> {category}</p>
            <p>{description}</p>
          </div>

          <div className="card__actions">
            <Button
              size="default"
              type="white"
              onClick={() => setOpenModal({ ...props })}
            >
              <FeatherIcon icon="edit-3" size={14} />
              Edit
            </Button>
            <Button
              size="default"
              type="white"
              onClick={() => setOpenEnableModal({ ...props })}
            >
              <FeatherIcon icon="x" size={14} />
              {is_enabled ? "Disable" : "Enable"}
            </Button>
          </div>
          <div className="card__info border-bottom">
            <Row gutter={16} style={{ margin: "0px" }}>
              <ul className="quote-info " style={{ padding: 0 }}>
                <li className="quote-info-item" style={{ width: "100%" }}>
                  <FeatherIcon icon="hash" size={16} />
                  {id}
                </li>
                <li className="quote-info-item">
                  <FeatherIcon icon="dollar-sign" size={16} />
                  {formatter.format(price / 100)}
                </li>
                <li className="quote-info-item">
                  <FeatherIcon icon="paperclip" size={16} />
                  {datasheet_name ? datasheet_name + ".pdf" : 'None'}
                </li>
                <li className="quote-info-item">
                  <FeatherIcon icon="tag" size={16} />
                  {subcategory ? subcategory : 'None'}
                </li>
              </ul>
            </Row>
          </div>
          <div className="card-footer">
            <Row justify="center" style={{ flexDirection: "column" }}>
              {is_enabled && (
                <p className="text-success" style={{ fontWeight: "bold" }}>
                  Enabled
                </p>
              )}
              {!is_enabled && (
                <p className="text-danger" style={{ fontWeight: "bold" }}>
                  Disabled
                </p>
              )}
              <p>Status</p>
            </Row>
          </div>
        </Cards>
      </div>
    </UserCard>
  );
};

export default ProductCard;
