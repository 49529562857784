import React, { useState } from "react";
import FeatherIcon from "feather-icons-react";
import { Row } from "antd";
import { UserCard } from "./style";
import Heading from "../heading/heading";
import { Cards } from "../cards/frame/cards-frame";
import { Button } from "../buttons/buttons";
import moment from "moment";
import { generateQuotePDF } from "../helpers/helper";

const QuoteCard = (props) => {
  const {
    id,
    created_at,
    customer_name,
    address_line1,
    address_line2,
    finance_method,
    state,
    country,
    comments,
    user,
    // open,
    // setOpenModal,
  } = props;

  const [loading, setLoading] = useState(false);

  const handleGeneratePDF = async () => {
    setLoading(true);

    await generateQuotePDF({ ...props }, "es");

    setLoading(false);
  };

  return (
    <UserCard>
      <div className="card user-card">
        <Cards headless>
          <div className="card__content">
            <Heading className="card__name" as="h6">
              Cotización #{id}
            </Heading>
            <p>{moment(created_at).format("DD/MMMM/YYYY")}</p>
          </div>

          <div className="card__actions">
            <Button
              size="default"
              type="white"
              onClick={() => handleGeneratePDF()}
            >
              <FeatherIcon
                icon={loading ? "loader" : "download"}
                size={14}
                className={`pdf-loader-icon ${loading ? "loading" : ""}`}
              />
              {loading ? "Generating..." : "Export"}
            </Button>
            {/* <Button
              size="default"
              type="white"
              onClick={() => setOpenModal({ ...props })}
            >
              <FeatherIcon icon="check" size={14} />
              Edit
            </Button> */}
          </div>
          <div className="card__info border-bottom">
            <Row gutter={15}>
              <ul className="quote-info ">
                <li className="quote-info-item">
                  <FeatherIcon icon="user" size={16} />
                  {customer_name}
                </li>
                <li className="quote-info-item">
                  <FeatherIcon icon="map-pin" size={16} />
                  {address_line1}
                  {address_line2 && address_line2}
                  {", "}
                  {state}, {country}
                </li>
                <li className="quote-info-item">
                  <FeatherIcon icon="credit-card" size={16} />
                  {finance_method}
                </li>
                {comments && (
                  <li className="quote-info-item">
                    <FeatherIcon icon="message-square" size={16} />
                    {comments}
                  </li>
                )}
              </ul>
            </Row>
          </div>
          <div className="card-footer">
            <Row justify="center">
              <p>Consultor: {user && user.name ? user.name : ""}</p>
            </Row>
            {/* <Row>
              <p>Comentarios: {comments}</p>
            </Row> */}
          </div>
        </Cards>
      </div>
    </UserCard>
  );
};

export default QuoteCard;
