import React from "react";
import { Page, Text, View, StyleSheet, Image, Font } from "@react-pdf/renderer";
import { formatter } from "../../helpers/helper";
import { getProductPrice } from "../helpers/helper";
import moment from "moment";
import "moment/locale/es";
import PoppinsRegular from "../../assets/fonts/Poppins-Regular.ttf";
import PoppinsBold from "../../assets/fonts/Poppins-Bold.ttf";
import ZeyadaRegular from "../../assets/fonts/Zeyada-Regular.ttf";
import PoppinsBoldItalic from "../../assets/fonts/Poppins-BoldItalic.ttf";

Font.register({
  family: "Poppins",
  format: "truetype",
  src: PoppinsRegular,
});

Font.register({
  family: "PoppinsBold",
  format: "truetype",
  src: PoppinsBold,
});

Font.register({
  family: "Zeyada",
  format: "truetype",
  src: ZeyadaRegular,
});

Font.register({
  family: "PoppinsBoldItalic",
  format: "truetype",
  src: PoppinsBoldItalic,
});

const styles = StyleSheet.create({
  page: {
    // flexDirection: "row",
    // backgroundColor: "#FFF",
    boxSizing: "border-box",
    fontSize: 10,
    // padding: 24,
    fontFamily: "Poppins",
  },
  header: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
  },
  headerText: {
    fontSize: 12,
    color: "#1f497d",
    margin: "0 10",
  },
  bodyText: {
    fontSize: 10,
    color: "#343a40",
    // margin: "0 10",
  },
  secondaryHeader: {
    fontSize: 10,
    margin: "8 0",
  },
  flex: {
    display: "flex",
  },
  bold: {
    fontFamily: "PoppinsBold",
  },
  boldItalic: {
    fontFamily: "PoppinsBoldItalic",
  },
  justifiedRight: {
    textAlign: "right",
  },
  orangeText: {
    color: "#E9682A",
  },
  grayText: {
    color: "#343a40",
  },
  whiteText: {
    color: "#FFFFFF",
  },
  ecoflowAccent: {
    color: "#26BAB1",
  },
  ecoflowAccentDark: {
    color: "#227D78",
  },
  ecoflowGrayBackground: {
    backgroundColor: "#3B3B3B",
  },
  ecoflowAccentBackground: {
    backgroundColor: "#26BAB1",
  },
  ecoflowAccentDarkBackground: {
    backgroundColor: "#227D78",
  },
  table: {
    width: "100%",
    borderRight: "1px solid #FFF",
  },
  tableRow: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-around",
  },
  cell: {
    paddingTop: "12",
    paddingBottom: "10",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    borderLeft: "1px solid #E6E6E6",
    borderBottom: "1px solid #E6E6E6",
    width: "33.333333%",
    height: "100%",
    fontSize: "10",
    backgroundColor: "#E6E6E6",
  },
  cell100: {
    paddingTop: "12",
    paddingBottom: "10",
    paddingLeft: "8",
    paddingRight: "8",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    borderLeft: "1px solid #E6E6E6",
    borderBottom: "1px solid #E6E6E6",
    width: "100%",
    height: "100%",
    fontSize: "10",
    backgroundColor: "#E6E6E6",
  },
  cell15: {
    paddingTop: "12",
    paddingBottom: "10",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    borderLeft: "1px solid #E6E6E6",
    borderBottom: "1px solid #E6E6E6",
    width: "15%",
    height: "100%",
    fontSize: "10",
    backgroundColor: "#E6E6E6",
  },
  headerCell: {
    backgroundColor: "#26BAB1",
    color: "#FFFFFF",
    border: "1px solid #26BAB1",
  },
  totalPrice: {
    marginTop: "8px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    width: "100%",
  },
  upperCase: {
    textTransform: "uppercase",
  },
  lineText: {
    display: "flex",
    flexDirection: "row",
  },
  boxedText: {
    padding: "4px 8px 2px 8px",
    borderRadius: "3px",
    border: "1px solid #dcdcdc",
    width: "100%",
  },
  marginSpacer: {
    marginTop: "6px",
    marginBottom: "4px",
  },
});

export default function EstimatePage(props) {
  const { quote } = props;

  // const generateDate = () => {
  //   let localLocale = moment();
  //   localLocale.locale("es");

  //   return localLocale.format("DD [de] MMMM [de] YYYY");
  // };

  // const generateFinanceCharge = () => {
  //   return generateFinalPrice() - generateFinalPrice(true);
  // };

  const generateproductRows = () => {
    let productRows = [];

    productRows = [
      ...quote.quote_products.map((qp) => {
        return (
          <View style={[styles.tableRow]} key={qp.id}>
            <Text style={[styles.cell]}>{qp.product.subcategory}</Text>
            <Text style={[styles.cell100]}>{qp.product.name}</Text>
            <Text style={[styles.cell]}>{qp.quantity}</Text>
            <Text style={[styles.cell]}>
              {getProductPrice(
                qp.product,
                qp.quantity,
                undefined,
                quote.finance_method,
                quote.united_approved_percentage
              ) > 0
                ? formatter.format(
                    (getProductPrice(
                      qp.product,
                      qp.quantity,
                      undefined,
                      quote.finance_method,
                      quote.united_approved_percentage
                    ) *
                      parseInt(qp.quantity, 10)) /
                      100
                  )
                : 0}
            </Text>
          </View>
        );
      }),
    ];

    // If finance method is Synchrony
    // Add Synchrony row
    // if (quote.finance_method === "Synchrony") {
    //   productRows.push(
    //     <View style={[styles.tableRow]}>
    //       <Text style={[styles.cell]}>Synchrony</Text>
    //       <Text style={[styles.cell100]}></Text>
    //       <Text style={[styles.cell]}></Text>
    //       <Text style={[styles.cell]}>
    //         {formatter.format(generateFinanceCharge())}
    //       </Text>
    //     </View>
    //   );
    // }

    // If finance method is United
    // Add United row
    // if (quote.finance_method === "United") {
    //   productRows.push(
    //     <View style={[styles.tableRow]}>
    //       <Text style={[styles.cell]}>United</Text>
    //       <Text style={[styles.cell100]}>Cargo por financiamiento</Text>
    //       <Text style={[styles.cell]}></Text>
    //       <Text style={[styles.cell]}>
    //         {formatter.format(generateFinanceCharge())}
    //       </Text>
    //     </View>
    //   );
    // }

    return productRows;
  };

  const generateDiscount = () => {
    if (quote.discount && parseFloat(quote.discount) > 0) {
      return parseFloat(quote.discount / 100);
    }

    return 0;
  };

  const generateFinalPrice = (basePrice = false) => {
    if (quote.quote_products) {
      let result = quote.quote_products.reduce((sumPrice, qp) => {
        if (basePrice) {
          return (sumPrice +=
            getProductPrice(qp.product, qp.quantity) *
            parseInt(qp.quantity, 10));
        } else {
          return (sumPrice +=
            getProductPrice(
              qp.product,
              qp.quantity,
              undefined,
              quote.finance_method,
              quote.united_approved_percentage
            ) * parseInt(qp.quantity, 10));
        }
      }, 0);

      return result / 100;
    }

    return 0;
  };

  return (
    <Page size="A4" style={styles.page}>
      {/* Page Container */}
      <View
        style={{
          width: "100%",
          display: "flex",
          boxSizing: "border-box",
          justifyContent: "space-between",
          height: "100%",
          padding: "0px",
          color: "#343434",
          // backgroundColor: "#000",
        }}
      >
        {/* Header */}
        <View
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            backgroundColor: "#FFF",
          }}
        >
          {/* logo image  */}
          <View
            style={{
              boxSizing: "border-box",
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              width: "35%",
              padding: "0px 32px",
              height: "120px",
            }}
          >
            <Image
              style={{
                objectFit: "contain",
                height: "50px",
              }}
              src={`/assets/commercial quotes images/Ecoflow-logo.png`}
            />
          </View>

          {/* Page title  */}
          <View
            style={[
              {
                boxSizing: "border-box",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "40%",
                padding: "24px 0px",
                fontSize: "24px",
                borderBottomLeftRadius: "50px",
              },
              styles.ecoflowAccentBackground,
            ]}
          >
            <Text
              style={[
                {
                  boxSizing: "border-box",
                  paddingBottom: "32px",
                },
                styles.upperCase,
                styles.whiteText,
                styles.bold,
              ]}
            >
              Cotización
            </Text>
          </View>
        </View>
        {/* Client info */}
        <View
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            backgroundColor: "#FFF",
          }}
        >
          <View
            style={[
              {
                boxSizing: "border-box",
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
                width: "30%",
                padding: "16px",
                borderTopRightRadius: "50px",
              },
              styles.ecoflowAccentDarkBackground,
            ]}
          >
            <Text
              style={[
                {
                  boxSizing: "border-box",
                  // paddingBottom: "16px",
                },
                styles.bold,
                styles.upperCase,
                styles.whiteText,
              ]}
            >
              Cotización para
            </Text>
          </View>
          <View
            style={[
              {
                boxSizing: "border-box",
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
                width: "60%",
                //height: "70px",
                padding: "16px",
                borderBottomRightRadius: "50px",
                fontSize: "18px",
              },
              styles.ecoflowAccentBackground,
            ]}
          >
            <Text
              style={[
                {
                  boxSizing: "border-box",
                },
                styles.whiteText,
                styles.ecoflowAccentBackground,
              ]}
            >
              {quote.customer_name}
            </Text>
          </View>
        </View>
        {/* Page Content */}
        <View
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            height: "100%",
            padding: "0px",
          }}
        >
          {/* Metodo y invoice date  */}
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              padding: "16px 32px",
              backgroundColor: "#FFF",
            }}
          >
            {/* Metodo de Pago  */}
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
                marginLeft: "24px",
              }}
            >
              <Image
                style={{
                  height: "20px",
                  display: "flex",
                  flexDirection: "row",
                  marginRight: "8px",
                }}
                src="/assets/commercial quotes images/credit-card-icon.png"
              />
              <View
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Text>Método de Pago</Text>
                <Text style={[styles.bold]}>{quote.finance_method}</Text>
              </View>
            </View>

            {/* Invoice Date  */}
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
                marginLeft: "24px",
              }}
            >
              <Image
                style={{
                  height: "20px",
                  display: "flex",
                  flexDirection: "row",
                  marginRight: "8px",
                }}
                src="/assets/commercial quotes images/calendar-alt-icon.png"
              />
              <View
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Text>Fecha</Text>
                <Text style={[styles.bold]}>
                  {moment().format("MMMM DD, YYYY").charAt(0).toUpperCase() +
                    moment().format("MMMM DD, YYYY").slice(1)}
                </Text>
              </View>
            </View>
          </View>

          {/* Table  */}
          <View
            style={{
              padding: "0px 32px",
              display: "flex",
              flexDirection: "column",
              backgroundColor: "#FFF",
            }}
          >
            <View style={[{}, styles.table]}>
              <View style={[styles.tableRow]}>
                <Text
                  style={[
                    styles.cell,
                    styles.headerCell,
                    styles.upperCase,
                    styles.bold,
                  ]}
                >
                  Componente
                </Text>
                <Text
                  style={[
                    styles.cell100,
                    styles.headerCell,
                    styles.upperCase,
                    styles.bold,
                  ]}
                >
                  Descripción
                </Text>
                <Text
                  style={[
                    styles.cell,
                    styles.headerCell,
                    styles.upperCase,
                    styles.bold,
                  ]}
                >
                  Cant.
                </Text>
                <Text
                  style={[
                    styles.cell,
                    styles.headerCell,
                    styles.upperCase,
                    styles.bold,
                  ]}
                >
                  Costo
                </Text>
              </View>
              {generateproductRows()}
            </View>

            {/* Notas, Total y Subtotal */}
            <View
              style={[
                {
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  padding: "16px",
                },
                styles.ecoflowAccentDarkBackground,
              ]}
            >
              {/* Notas */}
              <View style={[{ width: "45%" }]}>
                <View>
                  <Text
                    style={[
                      styles.bold,
                      styles.ecoflowAccent,
                      { fontSize: "14px" },
                    ]}
                  >
                    ¡Gracias por su confianza!
                  </Text>
                </View>
                <View style={[styles.whiteText, { hyphens: "none" }]}>
                  <Text>
                    EcoFlow es la marca líder en la industria de soluciones
                    portátiles de energía, revolucionando la forma en que puedes
                    llevar la energía contigo.
                  </Text>
                </View>
              </View>
              {/* Total y Subtotal */}
              <View
                style={[
                  {
                    width: "30%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignContent: "flex-start",
                  },
                  styles.marginSpacer,
                  styles.upperCase,
                ]}
              >
                {generateDiscount() > 0 && (
                  <>
                    {/* Subtotal */}
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        width: "100%",
                        paddingBottom: "8px",
                      }}
                    >
                      <Text style={[{ textAlign: "right" }, styles.whiteText]}>
                        SUBTOTAL:
                      </Text>
                      <Text
                        style={[
                          {
                            fontSize: "12px",
                            textAlign: "right",
                          },
                          styles.whiteText,
                        ]}
                      >
                        {formatter.format(generateFinalPrice())}
                      </Text>
                    </View>

                    {/* Discount */}
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        width: "100%",
                        paddingBottom: "8px",
                      }}
                    >
                      <Text style={[{ textAlign: "right" }, styles.whiteText]}>
                        DESCUENTO:
                      </Text>
                      <Text
                        style={[
                          {
                            fontSize: "12px",
                            textAlign: "right",
                          },
                          styles.whiteText,
                        ]}
                      >
                        -{formatter.format(generateDiscount())}
                      </Text>
                    </View>
                  </>
                )}
                {/* Total */}
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    width: "100%",
                    paddingBottom: "8px",
                  }}
                >
                  <Text
                    style={[
                      { textAlign: "right" },
                      styles.bold,
                      styles.whiteText,
                    ]}
                  >
                    TOTAL:
                  </Text>
                  <Text
                    style={[
                      styles.ecoflowAccent,
                      styles.bold,
                      {
                        fontSize: "20px",
                        textAlign: "right",
                      },
                    ]}
                  >
                    {formatter.format(
                      generateFinalPrice() - generateDiscount()
                    )}
                  </Text>
                </View>
              </View>
            </View>
            {/* Additional Comments */}
            {quote && quote.comments && quote.comments.length > 0 && (
              <View
                style={[
                  {
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-start",
                    minHeight: "40px",
                  },
                  styles.marginSpacer,
                ]}
              >
                <Text style={[styles.bold]}>Comentarios Adicionales:</Text>
                <Text>{quote.comments}</Text>
              </View>
            )}
          </View>
        </View>
        {/* Footer  */}
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            backgroundColor: "#010101",
            padding: "24px 32px",
          }}
        >
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              height: "100%",
            }}
          >
            {/* Signature Section */}
            <View
              style={[
                styles.whiteText,
                {
                  display: "flex",
                  flexDirection: "column",
                },
              ]}
            >
              <Text style={{ marginBottom: "8px" }}>Cotización por:</Text>
              <Text
                style={{
                  fontSize: "26px",
                  fontFamily: "Zeyada",
                }}
              >
                {quote.user.name}
              </Text>
            </View>
          </View>
          {/* Logo */}
          <Image
            style={{
              width: "210px",
              height: "100px",
              objectFit: "contain",
              objectPosition: "right center",
            }}
            src="/assets/ecoflow/powered-ps-logo.png"
          />
        </View>
      </View>
    </Page>
  );
}
