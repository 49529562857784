import {
    LOGIN,
    REGISTER,
    LOGOUT,
    LOADING_AUTH,
    CLEAR_LOADING_AUTH,
    // RESET_EXPIRED_PASSWORD,
    // AUTHENTICATION_FAILED,
} from '../constants/authConstants';


const initialState = {
    userLogin: {
        email: '',
        password: '',
    },
    currentUser: JSON.parse(localStorage.getItem('currentUser')),
    loading: false,
};

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case CLEAR_LOADING_AUTH:
            return { ...state, loading: false }
        case LOADING_AUTH:
            return { ...state, loading: true }
        case LOGIN:
            return {
                ...state,
                loading: false,
                currentUser: action.payload,
                // authenticationFailed: false,
            }
        case REGISTER:
            return {
                ...state,
                loading: false,
                currentUser: action.payload,
            }
        case LOGOUT:
            return {
                ...state, 
                loading: false,
                currentUser: {},
            }
        default:
            return state;
    }
}