import React from "react";
import { Page, Text, View, StyleSheet, Image } from "@react-pdf/renderer";
// import text from "./lang";

const styles = StyleSheet.create({
  page: {
    // flexDirection: "row",
    backgroundColor: "#FFF",
    boxSizing: "border-box",
    fontSize: 10,
    padding: 0,
    fontFamily: "Poppins",
  },
  header: {
    width: "100%",
    display: "flex",
    alignSelf: "center",
    backgroundColor: "#1D4091",
    color: "#fff",
    padding: "16 16 8 16",
    marginBottom: 16,
    borderBottom: "solid 3px #0E9CD5",
  },
  headerText: {
    fontSize: 14,
    color: "#1f497d",
    margin: "0 10",
  },
  bodyText: {
    fontSize: "10px",
    color: "#343a40",
    margin: "0",
    lineHeight: "1.5px",
  },
  secondaryHeader: {
    fontSize: 16,
    margin: "8 0",
  },
  flex: {
    display: "flex",
  },
  bold: {
    fontFamily: "PoppinsBold",
  },
  italic: {
    fontFamily: "PoppinsItalic",
  },
  justifiedRight: {
    textAlign: "right",
  },
  orangeText: {
    color: "#E9682A",
  },
  grayText: {
    color: "#343a40",
  },

  blueText: {
    color: "#2C4596",
  },

  table: {
    width: "100%",
  },
  tableRow: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-around",
  },
  cell: {
    paddingTop: "5",
    paddingBottom: "3",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    border: "1 solid #ccc",
    width: "33.333333%",
    height: "100%",
    fontSize: "9",
  },
  headerCell: {
    backgroundColor: "#E9682A",
    color: "#FFFFFF",
  },
  totalPrice: {
    marginTop: "8px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    width: "100%",
  },
  upperCase: {
    textTransform: "uppercase",
  },
  lineText: {
    display: "flex",
    flexDirection: "row",
  },
});

function AgreementPageFour() {
  return (
    <Page size="A4" style={styles.page}>
      {/* Page Container */}
      <View
        style={{
          width: "100%",
          display: "flex",
          // border: "1px solid #ccc",
          boxSizing: "border-box",
          justifyContent: "space-between",
          height: "100%",
        }}
      >
        {/* Header */}
        <View
          style={[
            {
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
              flexDirection: "row",
              alignItems: "center",
              backgroundColor: "#1D4091",
              color: "#fff",
              padding: "20 16 20 16",
              borderBottom: "4px solid #0E9CD5",
              marginBottom: 0,
              fontSize: 14,
              height: "72px",
            },
            styles.upperCase,
          ]}
        >
          {/* Page title */}
          {/* <Text>Cotización Comercial</Text> */}
          <Image
            style={{
              height: "28px",
            }}
            src={`/assets/commercial quotes images/powersolar-logo-white.png`}
          />
        </View>

        {/* Page Content */}
        <View
          style={[
            {
              padding: 48,
              display: "flex",
              flexDirection: "column",
              height: "100%",
            },
            styles.bodyText,
          ]}
        >
          <View
            style={[
              {
                fontSize: "16px",
                marginBottom: 16,
                textAlign: "center",
              },
              styles.bold,
              styles.upperCase,
              styles.grayText,
            ]}
          >
            <Text>CÓMO RECLAMAR SU GARANTÍA</Text>
          </View>

          {/* 1. */}
          <View
            style={[
              {
                display: "flex",
                flexDirection: "row",
                marginBottom: 16,
              },
            ]}
          >
            <Text style={{ paddingLeft: 12, width: "30px" }}>1. {"  "}</Text>
            <Text
              style={{
                width: "calc(100% - 30px)",
                textAlign: "justify",
                textJustify: "inter-word",
              }}
            >
              Cuando observe alguna filtración en el techo de su casa se
              recomienda sacar fotos, identificar el área de la casa especifica
              donde está ocurriendo la situación
            </Text>
          </View>

          {/* 2. */}
          <View
            style={[
              {
                display: "flex",
                flexDirection: "row",
                marginBottom: 16,
              },
            ]}
          >
            <Text style={{ paddingLeft: 12, width: "30px" }}>2. {"  "}</Text>
            <Text
              style={{
                width: "calc(100% - 30px)",
                textAlign: "justify",
                textJustify: "inter-word",
              }}
            >
              Llame a nuestras oficinas en horas laborables, 8:30 am a 4:30 pm
              al 787.331.1000.
            </Text>
          </View>

          {/* 3. */}
          <View
            style={[
              {
                display: "flex",
                flexDirection: "row",
                marginBottom: 16,
              },
            ]}
          >
            <Text style={{ paddingLeft: 12, width: "30px" }}>3. {"  "}</Text>
            <Text
              style={{
                width: "calc(100% - 30px)",
                textAlign: "justify",
                textJustify: "inter-word",
              }}
            >
              Tenga a la mano el nombre de la persona en el contrato, dirección
              exacta y un número de teléfono del contacto para coordinar la
              visita.
            </Text>
          </View>

          {/* 4. */}
          <View
            style={[
              {
                display: "flex",
                flexDirection: "row",
                marginBottom: 16,
              },
            ]}
          >
            <Text style={{ paddingLeft: 12, width: "30px" }}>4. {"  "}</Text>
            <Text
              style={{
                width: "calc(100% - 30px)",
                textAlign: "justify",
                textJustify: "inter-word",
              }}
            >
              El personal del área de garantías, luego de tomar toda su
              información, le estará anotando para su turno.
            </Text>
          </View>

          {/* 5. */}
          <View
            style={[
              {
                display: "flex",
                flexDirection: "row",
                marginBottom: 16,
              },
            ]}
          >
            <Text style={{ paddingLeft: 12, width: "30px" }}>5. {"  "}</Text>
            <Text
              style={{
                width: "calc(100% - 30px)",
                textAlign: "justify",
                textJustify: "inter-word",
              }}
            >
              El período de tiempo aproximado para atender su reclamación se
              estima en 2 semanas.
            </Text>
          </View>

          {/* 6. */}
          <View
            style={[
              {
                display: "flex",
                flexDirection: "row",
                marginBottom: 16,
              },
            ]}
          >
            <Text style={{ paddingLeft: 12, width: "30px" }}>6. {"  "}</Text>
            <Text
              style={{
                width: "calc(100% - 30px)",
                textAlign: "justify",
                textJustify: "inter-word",
              }}
            >
              Un supervisor le estará llamando para coordinar la visita.
            </Text>
          </View>
        </View>
        {/* Power Solar Contact Info */}
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: "100%",
            backgroundColor: "#F2F2F2",
            padding: "16px",
            fontSize: 10,
            color: "#2C4596",
          }}
        >
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <Image
              style={{
                height: "14px",
                display: "flex",
                flexDirection: "row",
                marginRight: 4,
                padding: 2,
              }}
              src="/assets/icons8-phone-64-blue.png"
            />
            <Text style={[styles.whiteSmallText]}>787.331.100</Text>
          </View>

          <View
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <Image
              style={{
                height: "14px",
                display: "flex",
                flexDirection: "row",
                marginRight: 4,
                padding: 2,
              }}
              src="/assets/icons8-website-64-blue.png"
            />
            <Text style={[styles.whiteSmallText]}>powersolarpr.com</Text>
          </View>

          <View
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <Image
              style={{
                height: "14px",
                width: "auto",
                display: "flex",
                flexDirection: "row",
                marginRight: 4,
                padding: 2,
              }}
              src="/assets/icons8-facebook-64-blue.png"
            />
            <Text style={[styles.whiteSmallText]}>PowerSolarPR</Text>
          </View>

          <View
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <Image
              style={{
                height: "14px",
                display: "flex",
                flexDirection: "row",
                marginRight: 4,
                padding: 2,
              }}
              src="/assets/icons8-location-64-blue.png"
            />
            <Text style={[styles.whiteSmallText]}>
              279 calle César L. Gónzalez • San Juan, P.R. 00918
            </Text>
          </View>
        </View>
      </View>
    </Page>
  );
}

export default AgreementPageFour;
