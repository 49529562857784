import React, { useState } from "react";
import Navbar from "./Navbar";
import "./navbar.css";
import { Outlet } from "react-router-dom";
import Sidebar from "./Sidebar";

function DashboardLayout() {
  const [collapsed, setCollapsed] = useState(true)

  return (
    <div>
      <Navbar collapsed={collapsed} setCollapsed={setCollapsed} />

      <div className="content">
        <Sidebar collapsed={collapsed} setCollapsed={setCollapsed} />
        <Outlet />
      </div>
    </div>
  );
}

export default DashboardLayout;
