import React from "react";

const languages = {
  en: {
    coverPageTitle: "Quote",
    name: "Name",
    address: "Address",
    quote: "Quote",
    consultant: "Consultant",
    financeMethod: "Finance Method",
    pageHeader: "Residential Quote",
    aboutUsTitle: "About Us",
    aboutUsFirstParagraph: (
      <>
        <strong>Power Solar</strong> is a company dedicated to the design,
        production and marketing of photovoltaic systems, aimed at the needs of
        consumers in Puerto Rico.
      </>
    ),
    aboutUsSecondParagraph: (
      <>
        We have trained technical personnel, modern machinery and
        state-of-the-art technology, making us one of the leading renewable
        energy companies on the island. With honesty and responsibility in the
        production and delivery of our products, we guarantee an optimal service
        that will achieve the highest degree of satisfaction in our customers.
      </>
    ),
    aboutUsThirdParagraph: (
      <>
        <strong>Our Mission</strong> is to provide the necessary options for our
        clients to obtain an economy and certain energy independence. In
        addition to providing energy solutions with the appropriate technology
        to guarantee the effectiveness of our systems.
      </>
    ),
    aboutUsSecondaryHeader: "LET'S MAKE PUERTO RICO 100% RENEWABLE.",
    descriptionHeader: "DESCRIPTION EQUIPMENT & PERMITS",
    descriptionFirstParagraph: (
      <>
        Power Solar, LLC. will be in charge of processing all the pertinent
        permits to complete the process of net metering and construction of
        equipment
      </>
    ),
    descriptionSecondParagraph: (
      inverterCount,
      inverterName,
      solarPanelCount,
      solarPanelName
    ) => (
      <>
        An equipment with Unirac anchor will be installed, {inverterCount}{" "}
        {inverterName} and {solarPanelCount} {solarPanelName}.
      </>
    ),
    descriptionThirdParagraph: (
      <>
        The price and installation will be subject to an initial technical visit
        to validate what is recommended in this proposal.
      </>
    ),
    additionalCommentsHeader: "Additional Comments",
    paymentPlanHeader: "Payment Plan",
    paymentPlanFirstParagraph: "When the agreement is signed.",
    paymentPlanSecondParagraph: "Before installation.",
    paymentPlanThirdParagraph:
      "When the system is installed and fully working.",
    paymentPlanWarning:
      "The price and installation will be subject to an initial technical visit to validate what is recommended in this proposal.",
    paymentPlanNotice:
      "The last payment is not subject to LUMA finalizing the net metering process.",
    returnOfInvestment: (totalCost, lumaBill, years) =>
      `Return of Investment (${totalCost} / ${lumaBill}) = ` + years + " years",
    warrantHeader: "Warranties",
    warranty1Header: "Solar Panels",
    warranty1Text: "25 years",
    warranty2Header: (hasTeslaInverter) =>
      hasTeslaInverter ? "Warranty Inverter Tesla PW+" : "Enphase IQ7 A",
    warranty2Text: (hasTeslaInverter) =>
      hasTeslaInverter ? "10 years" : "25 years",
    warranty3Header: "Tesla Powerwall",
    warranty3Text: "10 years",
    warranty4Header: "Installation",
    warranty4Text: "10 years",
    warranty5Header: "Service",
    warranty5Text: (years) => `${years} years`,
    warranty6Header: "Leaks and Anchoring",
    warranty6Text: "10 years",
    unitedDescription: "Financing charge",
    uniracDescription: "Mounting and anchoring",
    includedPrice: "Included",
    productQuantityHeader: "Quantity",
    productProductHeader: "Product",
    productDescriptionHeader: "Description",
    productPriceHeader: "Price",
    energeticIndependenceHeader: "On The Road To Energy Independence",
    energeticIndependenceParagraph: (
      <>
        Power Solar, LLC will work hard to get your equipment up and running as
        soon as possible. Installation time is subject to PREPA approval and
        technical studies. Your installation may be delayed by those mentioned
        above.
      </>
    ),
    agreementBenefitsHeader: "Agreements Benefits & Installation Process",
    agreementFirstParagraph: (kw) =>
      `Power Solar, LLC will install a ${kw}WDC solar system.`,
    agreementSecondParagraph:
      "Power Solar, LLC will design the plans and engineering of your solar system. After design Power Solar, LLC will check again to ensure our high quality standards.",
    agreementThirdParagraph:
      "When install the photovoltaic system is installed and all the LUMA-PREPA permits are completed, you will still receive an invoice from LUMA with the net metering interconnection program.",
    agreementFourthParagraph:
      "The solar system and storage system are designed to export excess energy to the net metering meter. However, in the event that LUMA is not in operation (blackout) the system will not export the excess to the meter, but it will provide energy limited to what it has stored at the time of the blackout.",
    agreementFifthParagraph:
      "When LUMA is not in operation, the Storage System will provide energy limited to what it has stored at the time of the blackout.",
    agreementSixthParagraph:
      "Power Solar LLC, manages and submits all the certifications to obtain the net metering permits, but does not guarantee or stipulate the time that the change with LUMA may take.",
    agreementSeventhParagraph:
      "15 days after the installation, the PREPA filing number will be delivered.",
    // agreementValid: (date) => `This quote is valid until: ${date}.`,
    agreementReview:
      "I have reviewed, understand and agree to the terms and processes of the above agreement.",
    agreementSignOwner: "Owner's Signature",
    agreementSignConsultant: "Consultant's Signature",
    promoPageHeader: "¿WHY POWER SOLAR?",
    promoProfessional: "PROFESSIONAL AND CERTIFIED INSTALLERS",
    promoInventory: "LARGE INVENTORY OF THE LATEST TECHNOLOGY",
    promoTeam: "THE LARGEST INSTALLATION TEAM IN PUERTO RICO",
    promoDesigns: "CUSTOM SYSTEMS DESIGN",
    promoPersonnel: "TRAINED AND CERTIFIED PERSONNEL",
    ProductionPageTitle: "Energy Production - 25 years (Estimates)",
    ProductionPageYearHeader: "Year",
    ProductionPageProductionHeader: "Production",
  },
  es: {
    coverPageTitle: "Cotización",
    name: "Nombre",
    address: "Dirección",
    quote: "Cotización",
    consultant: "Consultor",
    financeMethod: "Método de Financiamiento",
    pageHeader: "Cotización Residencial",
    aboutUsTitle: "Sobre Nosotros",
    aboutUsFirstParagraph: (
      <>
        <strong>Power Solar</strong> es una empresa dedicada al diseño,
        producción y comercialización de sistemas fotovoltaicos, dirigidos a las
        necesidades de los consumidores en Puerto Rico.
      </>
    ),
    aboutUsSecondParagraph: (
      <>
        Contamos con personal técnico capacitado, maquinaria moderna y
        tecnología de punta convirtiéndonos en una de las compañías líderes de
        energía renovable en la isla. Con honestidad y responsabilidad en la
        producción y entrega de nuestros productos garantizamos un óptimo
        servicio que logrará el más alto grado de satisfacción en nuestros
        clientes.
      </>
    ),
    aboutUsThirdParagraph: (
      <>
        <strong>Nuestra Misión</strong> es brindar las opciones necesarias para
        que nuestros clientes obtengan una economía y cierta independencia
        energética. Además de brindarles soluciones energéticas con la
        tecnología adecuada para nuestros sistemas.
      </>
    ),
    aboutUsSecondaryHeader: "HAGAMOS A PUERTO RICO 100% RENOVABLE.",
    descriptionHeader: "DESCRIPCIÓN EQUIPO Y PERMISOS",
    descriptionFirstParagraph: (
      <>
        Power Solar, LLC. se encargará de procesar todos los permisos
        pertinentes para completar el proceso de Medición Neta y construcción
        del equipo
      </>
    ),
    descriptionSecondParagraph: (
      inverterCount,
      inverterName,
      solarPanelCount,
      solarPanelName
    ) => (
      <>
        Se instalará un equipo con anclaje Unirac, {inverterCount}{" "}
        {inverterName} y {solarPanelCount} placas {solarPanelName}.
      </>
    ),
    descriptionThirdParagraph: (
      <>
        El precio y la instalación estarán sujetos a una visita técnica para
        validar lo recomensado en esta propuesta.
      </>
    ),
    additionalCommentsHeader: "Comentarios Adicionales",
    paymentPlanHeader: "Plan de Pago",
    paymentPlanFirstParagraph: (
      <>
        Al firmar el acuerdo de servicio, se comienza el proceso de diseño y se
        asigna “site visit”.
      </>
    ),
    paymentPlanSecondParagraph: "Previamente a la instalación.",
    paymentPlanThirdParagraph:
      "Al finalizar la instalación y el sistema esté en funcionamiento.",
    paymentPlanWarning:
      "Este último pago no esta sujeto a que PREPA (LUMA) culmine su responsabilidad del proceso de Medición Neta.",
    paymentPlanNotice:
      "Importante: La compra de baterías solas no incluye Medición Neta.",
    returnOfInvestment: (totalCost, lumaBill, years) =>
      `Retorno de inversión (${totalCost} / ${lumaBill}) = ` + years + " años",
    warrantHeader: "Garantías",
    warranty1Header: "Paneles Solares",
    warranty1Text: "25 años Garantia del fabricante",
    warranty2Header: (hasTeslaInverter) =>
      hasTeslaInverter
        ? "Garantia Inversor Tesla PW+"
        : "Enphase Microinversores",
    warranty2Text: (hasTeslaInverter) =>
      hasTeslaInverter
        ? "10 años Garantia del fabricante"
        : "25 años Garantia del fabricante",
    warranty3Header: "Tesla Powerwall",
    warranty3Text: "10 años (garantía del fabricante)",
    warranty4Header: "Instalación",
    warranty4Text: "10 años (con Power Solar)",
    warranty5Header: "Servicio",
    warranty5Text: (years) => `${years} años (con Power Solar)`,
    warranty6Header: "Filtraciones y anclaje",
    warranty6Text: "10 años",
    unitedDescription: "Cargo por financiamiento",
    uniracDescription: "Montura y anclaje",
    includedPrice: "Incluido",
    productQuantityHeader: "Cantidad",
    productProductHeader: "Producto",
    productDescriptionHeader: "Descripción",
    productPriceHeader: "Precio",
    energeticIndependenceHeader: "Camino a la Independencia Energética",
    energeticIndependenceParagraph: (
      <>
        Power Solar, LLC va a trabajar arduamente para que su equipo esté
        instalado y produciendo lo más pronto possible. El tiempo de instalación
        está sujeto a aprobación de PREPA y estudios técnicos. Su instalación se
        puede ver retrasada por los mencionados anteriormente.
      </>
    ),
    agreementBenefitsHeader: "BENEFICIOS DEL ACUERDO Y PROCESO DE INSTALACIÓN",
    agreementFirstParagraph: (kw) =>
      `Power Solar, LLC instalará un sistema solar de ${kw}WDC.`,
    agreementSecondParagraph:
      "Power Solar, LLC diseñará los planos e ingeniería de su sistema solar. Luego del diseño Power Solar, LLC revisará nuevamente para asegurar nuestros estándares altos de calidad.",
    agreementThirdParagraph:
      "Sistema Solar ser instalado y todos los permisos de LUMA-PREPA estén completados, usted aún va a recibir factura de LUMA con el programa de interconexión de medición neta.",
    agreementFourthParagraph:
      "El sistema solar y sistema de almacenamiento están diseñados para exportar el exceso de energía al contador de medición neta. Sin embargo, en el evento que LUMA no esté en funcionamiento apagón el sistema no exportará el exceso al contador, pero si proveerá la energía necesaria.",
    agreementFifthParagraph:
      "Cuando LUMA no esté en funcionamiento el Sistema de almacenamiento proveerá energía limitada a lo que tenga almacenado en el momento del apagón.",
    agreementSixthParagraph:
      "Power Solar LLC, gestiona y somete todas las certificaciones para obtener los permisos de Medición Neta, pero no garantiza ni estipula el tiempo que pueda tomar el cambio con LUMA.",
    agreementSeventhParagraph:
      "15 días luego de la instalación se entregara el número de radicación de PREPA",
    // agreementValid: (date) => `Esta cotización es válida hasta el ${date}.`,
    agreementReview:
      "He revisado, entiendo y estoy de acuerdo con los términos y procesos del acuerdo anterior",
    agreementSignOwner: "Firma del Propietario",
    agreementSignConsultant: "Firma del consultor",
    promoPageHeader: "¿POR QUÉ POWER SOLAR?",
    promoProfessional: "INSTALADORES PROFESIONALES Y CERTIFICADOS",
    promoInventory: "GRANDE INVENTARIO DE LO ÚLTIMO EN TECNOLOGÍA",
    promoTeam: "EL EQUIPO DE INSTALACIÓN MÁS GRANDE EN PUERTO RICO",
    promoDesigns: "DISEÑO DE SISTEMAS CUSTOMIZADOS",
    promoPersonnel: "PERSONAL ADIESTRADO Y CERTIFICADO",
    ProductionPageTitle: "Producción Energética - 25 años (Estimados)",
    ProductionPageYearHeader: "Año",
    ProductionPageProductionHeader: "Producción",
  },
};

export const newLanguages = {
  en: {
    coverPageTitle: "Residential Quote",
    name: "Client",
    consultant: "Consultant",
    // About Page
    aboutPageTitleBlue: "About",
    aboutPageTitleOrange: "Us",
    aboutUsFirstParagraphLineOne: "A purely Puerto Rican company dedicated",
    aboutUsFirstParagraphLineTwo: "to the energy transformation of the island.",
    aboutUsSecondParagraphTitle: "Mission",
    aboutUsSecondParagraph: (
      <>
        To lead the most important transformation of Puerto Rico: the country's
        energy independence based 100% on renewable energy, with a clean, safe,
        and reliable system.
      </>
    ),
    aboutUsThirdParagraphTitle: "Vision",
    aboutUsThirdParagraph: (
      <>
        To provide efficient and reliable solutions based on renewable energy,
        resulting in progress and a better quality of life for Puerto Ricans.
      </>
    ),
    aboutUsPartnersTitle: "Our local and global partners",
    // Quote Page
    quotePageTitleBlue: "Residential",
    quotePageTitleOrange: "Quote",
    quotePageForHeader: "Quote for",
    quotePageFinanceMethod: "Finance Method",
    quotePageDate: "Date",
    quotePageProductColumn: "Product",
    quotePageDescriptionColumn: "Description",
    quotePageAmountColumn: "Amount",
    quotePagePriceColumn: "Price",
    quotePageThankYou: "Thank you for choosing us!",
    quotePageDiscount: "DISCOUNT",
    quotePageCommentsTitle: "Additional Comments",
    descriptionHeader: "DESCRIPTION EQUIPMENT & PERMITS",
    quoteDescription: (
      hasSolarPanels,
      inverterCount,
      inverterName,
      solarPanelCount,
      solarPanelName
    ) => (
      <>
        Power Solar, LLC. will be in charge of processing all the pertinent
        permits to complete the process of net metering and construction of
        equipment.{" "}
        {hasSolarPanels &&
          `An equipment with Unirac anchor will be installed, ${inverterCount} ${inverterName} and ${solarPanelCount} ${solarPanelName}. `}
        The price and installation will be subject to an initial technical visit
        to validate what is recommended in this proposal.
      </>
    ),
    // PaymentPlanAndWarrantiesPage
    paymentPlanTitleBlue: "Payment",
    paymentPlanTitleOrange: "Plan",
    paymentPlanFirstParagraph: "When the agreement is signed.",
    paymentPlanSecondParagraph: "Before installation.",
    paymentPlanThirdParagraph:
      "When the system is installed and fully working.",
    paymentPlanWarning:
      "The price and installation will be subject to an initial technical visit to validate what is recommended in this proposal.",
    paymentPlanNotice:
      "The last payment is not subject to LUMA finalizing the net metering process.",
    roiTitleBlue: "Return",
    roiTitleOrange: "Of Investment",
    returnOfInvestment: (totalCost, lumaBill, years) =>
      `Return of Investment (${totalCost} / ${lumaBill}) = ` + years + " years",
    warrantiesTitleBlue: "Warranties",
    warranty1Header: "Solar Panels",
    warranty1Text: (hasPanel455) => (hasPanel455 ? "30 years" : "25 years"),
    warranty2Header: (hasTeslaInverter) =>
      hasTeslaInverter ? "Warranty Inverter Tesla PW+" : "Enphase IQ7 A",
    warranty2Text: (hasTeslaInverter) =>
      hasTeslaInverter ? "10 years" : "25 years",
    warranty3Header: "Tesla Powerwall",
    warranty3Text: "10 years",
    warranty4Header: "Installation",
    warranty4Text: "10 years",
    warranty5Header: "Service",
    warranty5Text: (years) => `${years} years`,
    warranty6Header: "Leaks and Anchoring",
    warranty6Text: "10 years",
    // CertificationsAndProceduresPage
    certificationsTitle: "Certifications",
    certificationsOne: "Certified Blue Ion Installer",
    certificationsTwo: "Certified Enphase Installer",
    certificationsThree: "Certified Tesla Installer",
    certificationsFour: "Electricians Certification",
    certificationsFive:
      "Certification for Solar System Installation in Puerto Rico",
    proceduresTitle: "Procedures",
    proceduresTitleOne: "Machinery",
    proceduresParagraphOne:
      "Machinery for mobilization and/or cranes for lifting the solar system will be included in the proposal.",
    proceduresTitleTwo: "PREPA Costs",
    proceduresParagraphTwo:
      "Any additional costs required by PREPA for interconnection will be considered.",
    proceduresTitleThree: "Roof Sealing",
    proceduresParagraphThree:
      "Roof sealing will be carried out in coordination with sealing providers if applicable, and inspections will be arranged to ensure warranties are not violated at the location.",
    proceduresTitleFour: "Drillings",
    proceduresParagraphFour:
      "All drillings will be sealed with SYKAPLEX and/or DANOSA boots or similar to comply with warranties.",
    certificationsNoteHeader: "Additional Note:",
    certificationsNoteParagraph:
      "Power Solar LLC Roofing Services handles roof sealing with DANOSA products. If interested in this service, please contact us to include it in the quote.",
    // Itinerary Page
    itineraryTitleBlue: "Project",
    itineraryTitleOrange: "Itinerary",
    itineraryParagraph: (
      <>
        Power Solar, LLC will work diligently to ensure your system is installed
        and producing as soon as possible. The installation time is subject to
        PREPA approval and technical studies. Your installation may be delayed
        due to the factors mentioned above.
      </>
    ),
    // Productions Page
    productionTitleBlue: "Energy Production",
    productionTitleOrange: "25 Years (Estimated)",
    productionTableColumnOne: "Year",
    productionTableColumnTwo: "Production",
    // AgreementsPage
    agreementTitleBlue: "Agreement Benefits",
    agreementTitleOrange: "& Installation Process",
    agreementFirstParagraph: (kw) =>
      `Power Solar, LLC will install a ${kw}WDC solar system.`,
    agreementSecondParagraph:
      "Power Solar, LLC will design the plans and engineering of your solar system. After design Power Solar, LLC will check again to ensure our high quality standards.",
    agreementThirdParagraph:
      "When install the photovoltaic system is installed and all the LUMA-PREPA permits are completed, you will still receive an invoice from LUMA with the net metering interconnection program.",
    agreementFourthParagraph:
      "The solar system and storage system are designed to export excess energy to the net metering meter. However, in the event that LUMA is not in operation (blackout) the system will not export the excess to the meter, but it will provide energy limited to what it has stored at the time of the blackout.",
    agreementFifthParagraph:
      "When LUMA is not in operation, the Storage System will provide energy limited to what it has stored at the time of the blackout.",
    agreementSixthParagraph:
      "Power Solar LLC, manages and submits all the certifications to obtain the net metering permits, but does not guarantee or stipulate the time that the change with LUMA may take.",
    agreementSeventhParagraph:
      "15 days after the installation, the PREPA filing number will be delivered.",
    // agreementValid: (date) => `This quote is valid until: ${date}.`,
    agreementReview:
      "I have reviewed, understand and agree to the terms and processes of the above agreement.",
    agreementConsultant: "Consultant",
    agreementDate: "Date",
    agreementSignOwner: "Owner's Signature",
    agreementSignConsultant: "Consultant's Signature",
    // PromoPageOne
    promoPageOneTitleBlue: "Why",
    promoPageOneTitleOrange: "POWER SOLAR?",
    // PromoPageTwo
    promoPageTwoTitleBlue: "Some of Our",
    promoPageTwoTitleOrange: "Residential Projects",
    // PromoPageThree
    promoPageThreeTitleBlue: "Some of Our",
    promoPageThreeTitleOrange: "Commercial Projects",
    // PromoPageFour
    promoPageFourTitleBlue: "We Are the Solution to",
    promoPageFourTitleOrange: "Puerto Rico's Energy Problem",
    // PromoPageFive
    promoPageFiveTitleBlue: "Take Control of your Power",
    promoPageFiveTitleOrange: "Today!",
  },
  es: {
    // Cover Page
    coverPageTitle: "Cotización Residencial",
    name: "Cliente",
    consultant: "Consultor",
    // About Page
    aboutPageTitleBlue: "Sobre",
    aboutPageTitleOrange: "Nosotros",
    aboutUsFirstParagraphLineOne:
      "Una compañía netamente puertorriqueña dedicada",
    aboutUsFirstParagraphLineTwo: "a la transformación energética de la isla.",
    aboutUsSecondParagraphTitle: "Misión",
    aboutUsSecondParagraph: (
      <>
        Liderar la transformación más importante de Puerto Rico; la
        independencia energética del país basada 100% en energía renovable, con
        un sistema limpio, seguro y confiable.
      </>
    ),
    aboutUsThirdParagraphTitle: "Visión",
    aboutUsThirdParagraph: (
      <>
        Proveer soluciones eficientes y confiables basadas en energía renovable,
        que se traduzcan en progreso y en una mejor calidad de vida para los
        puertorriqueños
      </>
    ),
    aboutUsPartnersTitle: "Nuestros Asociados Locales y Globales",
    // Quote Page
    quotePageTitleBlue: "Cotización",
    quotePageTitleOrange: "Residencial",
    quotePageForHeader: "Cotización para",
    quotePageFinanceMethod: "Método de Financiamiento",
    quotePageDate: "Fecha",
    quotePageProductColumn: "Producto",
    quotePageDescriptionColumn: "Descripción",
    quotePageAmountColumn: "Cant.",
    quotePagePriceColumn: "Precio",
    quotePageThankYou: "¡Gracias por su confianza!",
    quotePageDiscount: "DESCUENTO",
    quotePageCommentsTitle: "Comentarios Adicionales",
    descriptionHeader: "DESCRIPCIÓN EQUIPO Y PERMISOS",
    quoteDescription: (
      hasSolarPanels,
      inverterCount,
      inverterName,
      solarPanelCount,
      solarPanelName
    ) => (
      <>
        Power Solar, LLC. se encargará de procesar todos los permisos
        pertinentes para completar el proceso de Medición Neta y construcción
        del equipo.{" "}
        {hasSolarPanels &&
          `Se instalará un equipo con anclaje Unirac, ${inverterCount} ${inverterName} y ${solarPanelCount} placas ${solarPanelName}. `}
        El precio y la instalación estarán sujetos a una visita técnica para
        validar lo recomensado en esta propuesta.
      </>
    ),
    // PaymentPlanAndWarrantiesPage
    paymentPlanTitleBlue: "Plan",
    paymentPlanTitleOrange: "de pago",
    paymentPlanFirstParagraph: (
      <>
        Al firmar el acuerdo de servicio, se comienza el proceso de diseño y se
        asigna “site visit”.
      </>
    ),
    paymentPlanSecondParagraph: "Previamente a la instalación.",
    paymentPlanThirdParagraph:
      "Al finalizar la instalación y el sistema esté en funcionamiento.",
    paymentPlanWarning:
      "Este último pago no esta sujeto a que PREPA (LUMA) culmine su responsabilidad del proceso de Medición Neta.",
    paymentPlanNotice:
      "Importante: La compra de baterías solas no incluye Medición Neta.",
    roiTitleBlue: "Retorno",
    roiTitleOrange: "De Inversión",
    returnOfInvestment: (totalCost, lumaBill, years) =>
      `Retorno de Inversión (${totalCost} / ${lumaBill}) = ` + years + " años",
    warrantiesTitleBlue: "Garantías",
    warranty1Header: "Paneles Solares",
    warranty1Text:  (hasPanel455) => (hasPanel455 ? "30 años Garantía del fabricante" : "25 años Garantía del fabricante"),
    warranty2Header: (hasTeslaInverter) =>
      hasTeslaInverter
        ? "Garantia Inversor Tesla PW+"
        : "Enphase Microinversores",
    warranty2Text: (hasTeslaInverter) =>
      hasTeslaInverter
        ? "10 años Garantia del fabricante"
        : "25 años Garantia del fabricante",
    warranty3Header: "Tesla Powerwall",
    warranty3Text: "10 años (garantía del fabricante)",
    warranty4Header: "Instalación",
    warranty4Text: "10 años (con Power Solar)",
    warranty5Header: "Servicio",
    warranty5Text: (years) => `${years} años (con Power Solar)`,
    warranty6Header: "Filtraciones y anclaje",
    warranty6Text: "10 años",
    // CertificationsAndProceduresPage
    certificationsTitle: "Certificaciones",
    certificationsOne: "Instalador Certificado de Blue Ion",
    certificationsTwo: "Instalador certificado de Enphase",
    certificationsThree: "Instalador certificado de Tesla",
    certificationsFour: "Certificación Electricistas",
    certificationsFive:
      "Certificación para Instalación de Sistemas Solares en Puerto Rico",
    proceduresTitle: "Gestiones",
    proceduresTitleOne: "Maquinaria",
    proceduresParagraphOne:
      "Maquinaria para movilización y/o grúas para levantamiento de Sistema solar estará incluido en propuesta.",
    proceduresTitleTwo: "Costos PREPA",
    proceduresParagraphTwo:
      "Cualquier costo Adicional que requiera PREPA para la interconexión estará considerado.",
    proceduresTitleThree: "Sellado de Techo",
    proceduresParagraphThree:
      "Sellados de techos son realizados en conjunto con los proveedores de sellado si alguno aplica, se coordina inspección para no violentar garantías de cualquier sellado en la localidad. ",
    proceduresTitleFour: "Perforaciones",
    proceduresParagraphFour:
      "Todas las perforaciones serán selladas con SYKAPLEX y/o botas DANOSA o similares para cumplimiento de garantías.",
    certificationsNoteHeader: "Nota Adicional:",
    certificationsNoteParagraph:
      " Power Solar LLC Roofing Services se encarga de sellarlos techos con product DANOSA, de estar interesado en este Servicio debe comunicarse para incluirlo en la cotización.",
    // Itinerary Page
    itineraryTitleBlue: "Itinerario",
    itineraryTitleOrange: "De Trabajo",
    itineraryParagraph: (
      <>
        Power Solar, LLC va a trabajar arduamente para que su equipo esté
        instalado y produciendo lo más pronto ossible. El tiempo de instalación
        está sujeto a aprobación de PREPA y estudios técnicos. Su instalación se
        puede ver retrasada por los mencionados anteriormente.
      </>
    ),
    // Productions Page
    productionTitleBlue: "Producción Energética",
    productionTitleOrange: "25 años (estimados)",
    productionTableColumnOne: "Año",
    productionTableColumnTwo: "Producción",
    // AgreementsPage
    agreementTitleBlue: "Beneficios del acuerdo",
    agreementTitleOrange: "Y Proceso de instalación",
    agreementFirstParagraph: (kw) =>
      `Power Solar, LLC instalará un sistema solar de ${kw}WDC.`,
    agreementSecondParagraph:
      "Power Solar, LLC diseñará los planos e ingeniería de su sistema solar. Luego del diseño Power Solar, LLC revisará nuevamente para asegurar nuestros estándares altos de calidad.",
    agreementThirdParagraph:
      "Sistema Solar ser instalado y todos los permisos de LUMA-PREPA estén completados, usted aún va a recibir factura de LUMA con el programa de interconexión de medición neta.",
    agreementFourthParagraph:
      "El sistema solar y sistema de almacenamiento están diseñados para exportar el exceso de energía al contador de medición neta. Sin embargo, en el evento que LUMA no esté en funcionamiento apagón el sistema no exportará el exceso al contador, pero si proveerá la energía necesaria.",
    agreementFifthParagraph:
      "Cuando LUMA no esté en funcionamiento el Sistema de almacenamiento proveerá energía limitada a lo que tenga almacenado en el momento del apagón.",
    agreementSixthParagraph:
      "Power Solar LLC, gestiona y somete todas las certificaciones para obtener los permisos de Medición Neta, pero no garantiza ni estipula el tiempo que pueda tomar el cambio con LUMA.",
    agreementSeventhParagraph:
      "15 días luego de la instalación se entregara el número de radicación de PREPA",
    // agreementValid: (date) => `Esta cotización es válida hasta el ${date}.`,
    agreementReview:
      "He revisado, entiendo y estoy de acuerdo con los términos y procesos del acuerdo anterior",
    agreementConsultant: "Consultor",
    agreementDate: "Fecha",
    agreementSignOwner: "Firma del Propietario",
    agreementSignConsultant: "Firma del consultor",
    // PromoPageOne
    promoPageOneTitleBlue: "¿Por Qué",
    promoPageOneTitleOrange: "POWER SOLAR?",
    // PromoPageTwo
    promoPageTwoTitleBlue: "Algunos de Nuestros",
    promoPageTwoTitleOrange: "Proyectos Residenciales",
    // PromoPageThree
    promoPageThreeTitleBlue: "Algunos de Nuestros",
    promoPageThreeTitleOrange: "Proyectos Comerciales",
    // PromoPageFour
    promoPageFourTitleBlue: "Somos la solución para el",
    promoPageFourTitleOrange: "Problema energético de PR",
    // PromoPageFive
    promoPageFiveTitleBlue: "¡El momento de tomar control",
    promoPageFiveTitleOrange: "es ahora!",
  },
};

export default languages;
