import React from 'react';
import { Row, Col } from 'antd';
import { Aside, Content } from './style';

const AuthLayout = (props) => {
  const {
    children,
  } = props
  
    return (
      <Row>
        <Col xxl={8} xl={9} lg={12} md={8} xs={24}>
          <Aside>
            <div className="auth-side-content">
              <Content>
                {/* <img style={{ width: '150px'}} src={require('../../assets/images/Logo_Dark.svg')} alt="" /> */}
                <br />
                <br />
                <img
                  className="auth-content-figure"
                  src={require('../../assets/images/Logo_white.png')}
                  alt=""
                  style={{width: "100%"}}
                  
                />
              </Content>
            </div>
          </Aside>
        </Col>

        <Col xxl={16} xl={15} lg={12} md={16} xs={24}>
          { children }
        </Col>
      </Row>
    );
};

export default AuthLayout;
