import {
  LOADING_NEW_ENERGY_2_QUOTES,
  CLEAR_LOADING_NEW_ENERGY_2_QUOTES,
  CREATE_NEW_ENERGY_2_QUOTE,
  FETCH_NEW_ENERGY_2_QUOTES,
  UPDATE_NEW_ENERGY_2_QUOTE,
} from "../constants/newEnergy2QuoteConstants";

const initialState = {
  newEnergy2Quotes: [],
  loading: false,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case CLEAR_LOADING_NEW_ENERGY_2_QUOTES:
      return {
        ...state,
        loading: false,
      };
    case LOADING_NEW_ENERGY_2_QUOTES:
      return {
        ...state,
        loading: true,
      };
    case FETCH_NEW_ENERGY_2_QUOTES:
      return {
        ...state,
        loading: false,
        newEnergy2Quotes: [...state.newEnergy2Quotes, ...action.payload],
      };
    case CREATE_NEW_ENERGY_2_QUOTE:
      return {
        ...state,
        loading: false,
        newEnergy2Quotes:
          state.newEnergy2Quotes.length === 0
            ? []
            : [...state.newEnergy2Quotes, action.payload],
      };
    case UPDATE_NEW_ENERGY_2_QUOTE:
      return {
        ...state,
        loading: false,
        newEnergy2Quotes: [
          ...state.newEnergy2Quotes.filter(
            (q) => q && q.id && q.id !== action.payload.id
          ),
          action.payload,
        ],
      };
    default:
      return state;
  }
}
