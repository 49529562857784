import React, { useState, useEffect } from "react";
import { Row, Col, Skeleton } from "antd";
import { PageHeader } from "../../../components/page-headers/page-headers";
import { Main } from "../../styled";
import { Cards } from "../../../components/cards/frame/cards-frame";
import { Button } from "../../../components/buttons/buttons";
import FeatherIcon from "feather-icons-react";
import FilterRow from "./FilterRow";
import QuotationsList from "./QuotationsList";
import { connect } from "react-redux";
import { fetchQuotesAction } from "../../../redux/actions/quoteActions";
import { fetchCommercialCommercialQuotesAction } from "../../../redux/actions/commercialQuoteActions";
import { setCurrentKeyAction } from "../../../redux/actions/uiActions";
import { adminRoles } from "../../../api/data/roles";

const QuotationsContainer = (props) => {
  const { quotes, fetchQuotes, fetchCommercialCommercialQuotes, loading, setCurrentKey, currentUser } = props;

  const handleClick = (e) => {
    e.preventDefault();

    setCurrentKey("/app/quotations/new");
  };

  useEffect(() => {
    if (!quotes || (quotes && quotes.length === 0)) {
      fetchQuotes();
      if (adminRoles.includes(currentUser.role)) {
        fetchCommercialCommercialQuotes();
      }
    }
    // eslint-disable-next-line
  }, []);

  const [filteredQuotes, setFilteredQuotes] = useState([]);

  const getQuotes = () => {
    if (!!(currentUser && currentUser.role === "admin")) {
      return [...quotes];
    } else {
      return [...quotes.filter((q) => q.user_id === currentUser.id)];
    }
  };

  useEffect(() => {
    setFilteredQuotes(getQuotes());
    // eslint-disable-next-line
  }, [quotes]);

  return (
    <>
      <PageHeader
        title="Quotes List"
        buttons={[
          <div key="6" className="page-header-actions">
            <Button size="small" type="primary" onClick={handleClick}>
              <FeatherIcon icon="plus" size={14} />
              Add New
            </Button>
          </div>,
        ]}
      />
      <Main>
        <Row gutter={25}>
          {loading && (
            <Col sm={24} xs={24}>
              <Cards headless>
                <Skeleton paragraph={{ rows: 20 }} active />
              </Cards>
            </Col>
          )}
          {!loading && (
            <>
              <Col sm={24} xs={24}>
                <FilterRow
                  setFilteredQuotes={setFilteredQuotes}
                  quotes={getQuotes()}
                />
              </Col>
              <Col sm={24} xs={24}>
                <QuotationsList quotes={filteredQuotes ? filteredQuotes : []} />
              </Col>
            </>
          )}
        </Row>
      </Main>
    </>
  );
};

let mapStateToProps = (state) => ({
  quotes: state.quotes.quotes,
  loading: state.quotes.loading,
  currentUser: state.auth.currentUser,
});

let mapDispatchToProps = (dispatch) => ({
  fetchQuotes: () => dispatch(fetchQuotesAction()),
  setCurrentKey: (key) => dispatch(setCurrentKeyAction(key)),
  fetchCommercialCommercialQuotes: () =>
    dispatch(fetchCommercialCommercialQuotesAction()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QuotationsContainer);
