import React from "react";
import { Page, View, StyleSheet, Image, Font } from "@react-pdf/renderer";
import PoppinsRegular from "../../assets/fonts/Poppins-Regular.ttf";
import PoppinsBold from "../../assets/fonts/Poppins-Bold.ttf";
import PoppinsBoldItalic from "../../assets/fonts/Poppins-BoldItalic.ttf";

Font.register({
  family: "Poppins",
  format: "truetype",
  src: PoppinsRegular,
});

Font.register({
  family: "PoppinsBold",
  format: "truetype",
  src: PoppinsBold,
});

Font.register({
  family: "PoppinsBoldItalic",
  format: "truetype",
  src: PoppinsBoldItalic,
});

const styles = StyleSheet.create({
  page: {
    backgroundColor: "#FFF",
    boxSizing: "border-box",
    fontSize: 11,
    fontFamily: "Poppins",
  },
  header: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
  },
  headerText: {
    fontSize: 24,
    color: "#1f497d",
    margin: "0 10",
    fontFamily: "PoppinsBold",
  },
  secondaryHeader: {
    fontSize: 16,
    margin: "8 0",
    fontFamily: "PoppinsBold",
  },
  bodyText: {
    fontSize: 11,
    color: "#343a40",
    margin: "0 10",
  },
  bold: {
    fontFamily: "PoppinsBold",
  },
  boldItalic: {
    fontFamily: "PoppinsBoldItalic",
  },
  justifiedRight: {
    textAlign: "right",
  },
  upperCase: {
    textTransform: "uppercase",
  },
  orangeText: {
    color: "#E9682A",
  },
  grayText: {
    color: "#343a40",
  },
  whiteText: {
    color: "#FFFFFF",
  },
  blueText: {
    color: "#2C4596",
  },
  flex: {
    display: "flex",
  },
  smallText: {
    fontSize: 10,
  },
  whiteSmallText: {
    color: "#ffffff",
    fontSize: "9px",
  },
});

export default function BackCoverPage(props) {
  const { lang } = props;
  return (
    <Page size="A4" style={styles.page}>
      <View
        style={[
          styles.header,
          { display: "flex", justifyContent: "space-between", height: "100%" },
        ]}
      >
        {/* Upper Half */}
        <View
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            width: "100%",
            height: "60%",
            padding: 48,
          }}
        >
          {/* Toma Control image */}
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "100%",
            }}
          >
            <Image
              style={{
                marginBottom: 10,
                width: "70%",
                objectFit: "contain",
                display: "flex",
                justifyContent: "center",
              }}
              src={`/assets/residential quotes images/pdf-residencial_toma-control-de-tu-energia-${lang}.png`}
            />
          </View>
        </View>

        {/* Bottom Half  */}
        <View
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          {/* Triangles Footer */}
          <Image
            style={{
              width: "100%",
              display: "flex",
              alignSelf: "center",
            }}
            src={`/assets/bottom-triangles-border.png`}
          />

          {/* Stats Image*/}
          <View
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              backgroundColor: "#2C4596",
              paddingTop: "24px",
             
            }}
          >
            <Image
              style={{
                width: "100%",
                objectFit: "contain",
              }}
              src={`/assets/residential quotes images/power-solar-stats-${lang}.png`}
            />
          </View>

          {/* PS contact info footer */}
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%",
              backgroundColor: "#2C4596",
              padding: "24px",
            }}
          ></View>
        </View>
      </View>
    </Page>
  );
}
