import React, { useState, useEffect } from "react";
import { Menu } from "antd";
import menuItems from "../../api/menuItems";
import { useNavigate, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { setCurrentKeyAction } from "../../redux/actions/uiActions";

function Sidebar(props) {
  const { collapsed, setCollapsed, currentKey, setCurrentKey, currentUser } =
    props;
  const location = useLocation();

  const [keys, setKeys] = useState([location.pathname]);

  const navigate = useNavigate();

  const handleClick = (event) => {
    setCollapsed(!collapsed);
  };

  const handleSelect = (event) => {
    setCurrentKey(event.key);
  };

  useEffect(() => {
    setCollapsed(true);
    if (currentKey) {
      setKeys([currentKey]);
      navigate(currentKey);
    }
    // eslint-disable-next-line
  }, [currentKey]);

  return (
    <div className={`sidebar ${collapsed ? " collapsed" : ""}`}>
      <Menu
        // defaultSelectedKeys={["1"]}
        defaultOpenKeys={[]}
        mode="inline"
        inlineCollapsed={collapsed}
        items={menuItems
          .filter(
            (menu) =>
              currentUser &&
              (currentUser.role === "admin" ||
                menu.roles.includes(currentUser.role))
          )
          .map((menu) => {
            let filteredMenu = { ...menu };
            filteredMenu.children = menu.children.filter(
              (m) =>
                currentUser &&
                (currentUser.role === "admin" ||
                  !(m && m.roles && !m.roles.includes(currentUser.role)))
            );
            return filteredMenu
          })}
        onClick={handleClick}
        onSelect={handleSelect}
        selectedKeys={keys}
      />
    </div>
  );
}

const mapStateToProps = (state) => ({
  currentKey: state.ui.currentKey,
  currentUser: state.auth.currentUser,
});

let mapDispatchToProps = (dispatch) => ({
  setCurrentKey: (key) => dispatch(setCurrentKeyAction(key)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
