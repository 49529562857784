import React from "react";
import { Button } from "antd";
import FeatherIcon from "feather-icons-react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { logoutAction } from "../../redux/actions/authActions";
import { setCurrentKeyAction } from "../../redux/actions/uiActions";

function Navbar(props) {
  const { collapsed, setCollapsed, logout, setCurrentKey } = props;

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  return (
    <nav className="navbar">
      <div>
        <Button type="link" onClick={toggleCollapsed}>
          <img
            src={require(`../../assets/images/icon/${
              collapsed ? "right.svg" : "left.svg"
            }`)}
            alt="menu"
          />
        </Button>
        <Link to="/" onClick={() => setCurrentKey('/app')}>
          <img
            className="logo"
            src={require(`../../assets/images/Logo_white.png`)}
            alt="logo"
          />
        </Link>
      </div>
      <FeatherIcon
        icon="log-out"
        color="white"
        className="pointer"
        onClick={() => logout()}
      />
    </nav>
  );
}

let mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(logoutAction()),
  setCurrentKey: (key) => dispatch(setCurrentKeyAction(key)),
});

export default connect(null, mapDispatchToProps)(Navbar);
