import React from "react";
import { Page, Text, View, StyleSheet, Image } from "@react-pdf/renderer";
// import text from "./lang";

const styles = StyleSheet.create({
  page: {
    // flexDirection: "row",
    backgroundColor: "#FFF",
    boxSizing: "border-box",
    fontSize: 14,
    padding: 0,
  },
  // section: {
  //   margin: 10,
  //   padding: 10,
  //   flexGrow: 1,
  // },
  header: {
    width: "100%",
    display: "flex",
    alignSelf: "center",
    backgroundColor: "#1D4091",
    color: "#fff",
    padding: "16 16 8 16",
    marginBottom: 16,
    borderBottom: "solid 3px #0E9CD5",
  },
  headerText: {
    fontSize: 14,
    color: "#1f497d",
    margin: "0 10",
  },
  bodyText: {
    fontSize: "12px",
    color: "#343a40",
    margin: "0",
    lineHeight: "2px",
  },
  secondaryHeader: {
    fontSize: 16,
    margin: "8 0",
  },
  flex: {
    display: "flex",
  },
  bold: {
    fontFamily: "Helvetica-Bold",
  },
  justifiedRight: {
    textAlign: "right",
  },
  orangeText: {
    color: "#E9682A",
  },
  grayText: {
    color: "#343a40",
  },
  table: {
    width: "100%",
  },
  tableRow: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-around",
  },
  cell: {
    paddingTop: "5",
    paddingBottom: "3",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    border: "1 solid #ccc",
    width: "33.333333%",
    height: "100%",
    fontSize: "9",
  },
  headerCell: {
    backgroundColor: "#E9682A",
    color: "#FFFFFF",
  },
  totalPrice: {
    marginTop: "8px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    width: "100%",
  },
  upperCase: {
    textTransform: "uppercase",
  },
  lineText: {
    display: "flex",
    flexDirection: "row",
  },
});

function AboutPage(props) {
  // const { lang } = props;

  const generateStatsCards = () => {
    return [
      { number: 14, text1: "Años de", text2: "Experiencia" },
      { number: 20000, text1: "Puertorriqueños", text2: "Satisfechos" },
      { number: 800, text1: "Nuestro", text2: "Equipo" },
    ].map((stat) => (
      <View
        style={{
          display: "flex",
          flexDirection: "column",
          width: "30%",
        }}
      >
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <View
            style={{
              borderTop: "4px solid #E9682A",
              borderLeft: "4px solid #E9682A",
              width: 23,
              height: 23,
            }}
          ></View>
          <View
            style={{
              borderTop: "4px solid #E9682A",
              borderRight: "4px solid #E9682A",
              width: 23,
              height: 23,
            }}
          >
            {" "}
          </View>
        </View>
        <View
          style={[
            {
              textAlign: "center",
              fontWeight: "700",
              fontSize: "24px",
            },
            styles.orangeText,
          ]}
        >
          {" "}
          <Text>{stat.number}+</Text>
        </View>
        <View
          style={[
            {
              textAlign: "center",
              fontSize: "12px",
              width: "60%",
              margin: "auto",
            },
            styles.orangeText,
          ]}
        >
          <Text>{stat.text1}</Text>
        </View>
        <View
          style={[
            {
              textAlign: "center",
              fontSize: "12px",
              width: "60%",
              margin: "auto",
            },
            styles.orangeText,
          ]}
        >
          <Text>{stat.text2}</Text>
        </View>

        <View
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <View
            style={{
              borderBottom: "4px solid #E9682A",
              borderLeft: "4px solid #E9682A",
              width: 23,
              height: 23,
            }}
          ></View>
          <View
            style={{
              borderBottom: "4px solid #E9682A",
              borderRight: "4px solid #E9682A",
              width: 23,
              height: 23,
            }}
          >
            {" "}
          </View>
        </View>
      </View>
    ));
  };

  return (
    <Page size="A4" style={styles.page}>
      {/* Page Container */}
      <View
        style={{
          width: "100%",
          display: "flex",
          boxSizing: "border-box",
          justifyContent: "space-between",
          height: "100%",
        }}
      >
        {/* Header */}
        {/* <View
          style={[
            {
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "row",
              alignItems: "center",
              backgroundColor: "#1D4091",
              color: "#fff",
              padding: "20 16 20 16",
              borderBottom: "4px solid #0E9CD5",
              marginBottom: 0,
              fontSize: 14,
              height: "72px",
            },
            styles.upperCase,
          ]}
        > */}
        {/* Page title */}
        {/* <Text>Cotización Comercial</Text>
          <Image
            style={{
              height: "28px",
            }}
            src={`/assets/commercial quotes images/powersolar-logo-white.png`}
          />
        </View> */}

        {/* Page Content */}
        <View>
          {/* Header Image */}

          <Image
            style={{
              width: "100%",
              // height: "250px",
              objectFit: "cover",
            }}
            src={`/assets/commercial quotes images/sobre-nosotros-header.png`}
          />

          <View
            style={{
              padding: 48,
              display: "flex",
              flexDirection: "column",
              height: "100%",
            }}
          >
            {/* Page Title */}
            <View
              style={[
                {
                  fontSize: "24px",
                  marginBottom: 24,
                  textAlign: "left",
                },
                styles.bold,
                styles.upperCase,
                styles.grayText,
              ]}
            >
              <Text>Sobre Nosotros</Text>
            </View>
            {/* Page Content */}
            <View style={[{}, styles.bodyText]}>
              <Text
                style={{
                  marginBottom: 16,
                }}
              >
                Power Solar es una empresa con mas de 14 años de experiencia,
                dedicada al diseño, producción y comercialización de sistemas
                fotovoltaicos, dirigidos a las necesidades de los consumidores
                en Puerto Rico.
              </Text>
              <Text
                style={{
                  marginBottom: 16,
                }}
              >
                Contamos con más de 600 empleados capacitados, maquinaria
                moderna y tecnología de punta convirtiéndonos en una de las
                compañías líderes de energía renovable en la isla. Ya energizado
                sobre 12,000 hogares en Puerto Rico e instalado sobre 90MW en
                sistemas fotovoltáicos. Con honestidad y responsabilidad en la
                producción y entrega de nuestros productos garantizamos un
                óptimo servicio que logrará el más alto grado de satisfacción en
                nuestros clientes.
              </Text>
              <Text
                style={{
                  marginBottom: 16,
                }}
              >
                Nuestra Misión es brindar las opciones necesarias para que
                nuestros clientes obtengan una economía y cierta independencia
                energética. Además de brindarles soluciones energéticas con la
                tecnología adecuada para nuestros sistemas.
              </Text>
            </View>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              {generateStatsCards()}
            </View>
          </View>
        </View>
      </View>
    </Page>
  );
}

export default AboutPage;
